"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CalendarLocaleProvider", {
  enumerable: true,
  get: function () {
    return _CalendarLocaleProvider.default;
  }
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function () {
    return _Calendar.default;
  }
});
var _Calendar = _interopRequireDefault(require("./Calendar"));
var _CalendarLocaleProvider = _interopRequireDefault(require("./CalendarLocaleProvider"));
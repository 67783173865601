import { useMutation } from 'react-query';
import { ServiceError } from '@dateam/ark';
// import { usePick } from '@dateam/ark-react';
import logger from 'utils/logger';
import { useUserState } from 'utils/userStore';
import {
    defaultActionOptions,
    // mutateResultKeys,
    MutationResult
} from './constants';
import { getLocalActivity, saveActivity } from './sync';

type InspectionValidationRequest = {
    inspectionId: App.Inspection['id'];
};

type InspectionPlotValidationRequest = {
    inspectionId: App.Inspection['id'];
    plotId: App.Plot['id'];
};

type InspectionPlotUnObservableRequest = {
    inspectionId: App.Inspection['id'];
    plotId: App.Plot['id'];
    reason: App.Plot['ignoredReason'];
};

export const useValidateInspection = (): MutationResult<void, InspectionValidationRequest> => {
    const [user] = useUserState();

    const result = useMutation<void, ServiceError, InspectionValidationRequest>(
        async ({ inspectionId }: InspectionValidationRequest) => {
            logger.debug('Inspection: validating...');

            if (user == null) throw new Error('Inspection: no user available, aborted.');

            try {
                logger.debug('Inspection: retrieving local data...');
                const activity = await getLocalActivity(user.id);

                if (activity == null) throw new Error('Inspection: no activity available, aborted.');

                const inspectionFound = activity.inspections.find(inspection => inspection.id === inspectionId);
                if (inspectionFound == null || inspectionFound.validationDate != null) {
                    logger.warn(`Inspection: unable to find inspection (${inspectionId})`);
                    return;
                }

                const hasUnvalidatedPlots = inspectionFound.plots.some(plot => plot.validationDate == null);

                if (hasUnvalidatedPlots) {
                    logger.warn('Inspection: inspection has unvalidated plots.');
                    return;
                }

                logger.debug('Inspection: updating activity observation...');
                inspectionFound.validationDate = new Date();
                inspectionFound.hasChanged = true;
                inspectionFound.validationChanged = true;

                logger.debug('Inspection: saving local data...');
                await saveActivity(user.id, activity);
                logger.debug('Inspection: data saved');
            }
            catch (err) {
                logger.error(err);
                throw new Error('Inspection: failed to save data.');
            }
        },
        {
            ...defaultActionOptions
        }
    );

    return result;
};

export const useValidateInspectionPlot = (): MutationResult<void, InspectionPlotValidationRequest> => {
    const [user] = useUserState();

    const result = useMutation<void, ServiceError, InspectionPlotValidationRequest>(
        async ({ inspectionId, plotId }: InspectionPlotValidationRequest) => {
            logger.debug('Inspection plot: validating...');

            if (user == null) throw new Error('Inspection plot: no user available, aborted.');

            try {
                logger.debug('Inspection plot: retrieving local data...');
                const activity = await getLocalActivity(user.id);

                if (activity == null) throw new Error('Inspection plot: no activity available, aborted.');

                logger.debug('Inspection plot: updating activity observation...');
                activity.inspections.forEach(inspection => {
                    if (inspection.id !== inspectionId) return;

                    inspection.plots.forEach(plot => {
                        if (plot.id !== plotId) return;

                        if (plot.isValid) {
                            plot.validationDate = new Date();
                            plot.hasChanged = true;
                            plot.validationChanged = true;
                        }
                    });
                });

                logger.debug('Inspection plot: saving local data...');
                await saveActivity(user.id, activity);
                logger.debug('Inspection plot: data saved');
            }
            catch (err) {
                logger.error(err);
                throw new Error('Inspection plot: failed to save data.');
            }
        },
        {
            ...defaultActionOptions
        }
    );

    return result;
};

export const useUnObservableInspectionPlot = (): MutationResult<void, InspectionPlotUnObservableRequest> => {
    const [user] = useUserState();

    const result = useMutation<void, ServiceError, InspectionPlotUnObservableRequest>(
        async ({ inspectionId, plotId, reason }: InspectionPlotUnObservableRequest) => {
            logger.debug('Inspection plot: validating...');

            if (user == null) throw new Error('Inspection plot: no user available, aborted.');

            try {
                logger.debug('Inspection plot: retrieving local data...');
                const activity = await getLocalActivity(user.id);

                if (activity == null) throw new Error('Inspection plot: no activity available, aborted.');

                logger.debug('Inspection plot: updating activity observation...');
                activity.inspections.forEach(inspection => {
                    if (inspection.id !== inspectionId) return;

                    inspection.plots.forEach(plot => {
                        if (plot.id !== plotId) return;

                        plot.observations.forEach(item => item.ignored = true);

                        plot.ignored = true;
                        plot.ignoredReason = reason;
                        plot.validationDate = new Date();
                        plot.hasChanged = true;
                        plot.validationChanged = true;
                    });
                });

                logger.debug('Inspection plot: saving local data...');
                await saveActivity(user.id, activity);
                logger.debug('Inspection plot: data saved');
            }
            catch (err) {
                logger.error(err);
                throw new Error('Inspection plot: failed to save data.');
            }
        },
        {
            ...defaultActionOptions
        }
    );

    return result;
};
import React from 'react';
import propTypes from 'prop-types';
import { isDefined, isString } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import styles from './Sidebar.module.scss';

type Props = {
    icon?: React.ReactNode;
    className?: string;
    children?: React.ReactNode;
    info?: React.ReactNode;
    color?: 'primary' | 'warning' | 'error';
    onClick?: () => unknown;
};

const SidebarMenuItem = React.forwardRef<HTMLLIElement, Props>(({
    children,
    icon,
    className,
    info,
    color,
    onClick
}: Props, ref) => {
    const rootRef = React.useRef<HTMLLIElement>(null);

    React.useEffect(() => {
        if (!isDefined(ref)) return;
        (ref as any).current = rootRef.current;
    }, [ref]);

    const showInfo = React.useMemo(() => isDefined(info), [info]);

    return (
        <li
            ref={rootRef}
            className={concatClassName(
                styles['menuItem'],
                isString(color) ? styles[`menuItem-${color}`] : null,
                className
            )}
        >
            <button type="button" className={styles['menuItemRoot']} onClick={onClick}>
                <span className={styles['menuItemIcon']}>{icon}</span>
                <span className={styles['menuItemText']}>{children}</span>
                {showInfo && (
                    <span className={styles['itemInfo']}>{info}</span>
                )}
            </button>
        </li>
    );
});

SidebarMenuItem.propTypes = {
    children: propTypes.node,
    icon: propTypes.node,
    className: propTypes.string,
    info: propTypes.node,
    color: propTypes.oneOf(['primary', 'warning', 'error']),
    onClick: propTypes.func
};

SidebarMenuItem.defaultProps = {
    children: undefined,
    icon: undefined,
    className: undefined,
    info: undefined,
    color: undefined,
    onClick: undefined
};

export default SidebarMenuItem;

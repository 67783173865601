import * as AppInsight from '@microsoft/applicationinsights-web';
import config from 'config';
import { getUserState } from './userStore';

export type SeverityLevelType = AppInsight.SeverityLevel;
export const { SeverityLevel } = AppInsight;
export let appInsights: AppInsight.ApplicationInsights;
export const initAppInsights = (): void => {
    try {
        appInsights = new AppInsight.ApplicationInsights({
            config: {
                instrumentationKey: config.appInsightsKey
            }
        });

        appInsights.loadAppInsights();
        const telemetryInitializer = (item: AppInsight.ITelemetryItem) => {
            if (item == null) return;
            if (item.data != null) {
                const user = getUserState();
                item.data.authUser = user?.username ?? null;
                item.data.appName = config.appName;
                item.data.prod = config.prod;
            }
        };
        appInsights.addTelemetryInitializer(telemetryInitializer);
    }
    catch {
        // Ignore
    }
};

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FormAutocomplete = _interopRequireDefault(require("./FormAutocomplete"));
var _FormCheckbox = _interopRequireDefault(require("./FormCheckbox"));
var _FormDate = _interopRequireDefault(require("./FormDate"));
var _FormEditableDate = _interopRequireDefault(require("./FormEditableDate"));
var _FormEditableInput = _interopRequireDefault(require("./FormEditableInput"));
var _FormEditableNumber = _interopRequireDefault(require("./FormEditableNumber"));
var _FormEditableSelect = _interopRequireDefault(require("./FormEditableSelect"));
var _FormEditableAutocomplete = _interopRequireDefault(require("./FormEditableAutocomplete"));
var _FormGroup = _interopRequireDefault(require("./FormGroup"));
var _FormInput = _interopRequireDefault(require("./FormInput"));
var _FormFile = _interopRequireDefault(require("./FormFile"));
var _FormLabel = _interopRequireDefault(require("./FormLabel"));
var _FormMultiselect = _interopRequireDefault(require("./FormMultiselect"));
var _FormNumber = _interopRequireDefault(require("./FormNumber"));
var _FormSelect = _interopRequireDefault(require("./FormSelect"));
var _FormSwitch = _interopRequireDefault(require("./FormSwitch"));
var _default = exports.default = Object.assign({}, {
  Autocomplete: _FormAutocomplete.default,
  Checkbox: _FormCheckbox.default,
  Date: _FormDate.default,
  Group: _FormGroup.default,
  Input: _FormInput.default,
  File: _FormFile.default,
  Label: _FormLabel.default,
  Multiselect: _FormMultiselect.default,
  Number: _FormNumber.default,
  Select: _FormSelect.default,
  Switch: _FormSwitch.default,
  Editable: {
    Date: _FormEditableDate.default,
    Input: _FormEditableInput.default,
    Number: _FormEditableNumber.default,
    Select: _FormEditableSelect.default,
    Autocomplete: _FormEditableAutocomplete.default
  }
});
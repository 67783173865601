import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useNavigation, withRouter } from 'shared-utils';
import config from 'config';
import Page from 'components/Page';
import InspectionInstructionDialog from 'components/InspectionInstructionDialog';
import { useInspection, useInstructionDisplayed } from 'data/effects';
import { DashboardNavigation } from 'screens/routes';
import PlotDetails from './components/PlotDetails';
import InspectionDetails from './components/InspectionDetails';
import styles from './InspectionScreen.module.scss';

const InspectionScreen: React.FC = () => {
    const { t } = useTranslation();
    const { push: navigate, query: { inspectionId: inspectionIdParam, plotId: plotIdParam } } = useNavigation();
    const [inspectionId] = React.useState(parseInt(inspectionIdParam, 10));
    const { data: inspection, isFetched } = useInspection(inspectionId);
    const [instructionDisplayed, setInstructionDisplayed] = useInstructionDisplayed(inspectionId);
    const [showInstruction, setShowInstruction] = React.useState(false);

    React.useEffect(() => {
        if (isFetched === true && inspection === null) {
            navigate(DashboardNavigation());
        }
    }, [isFetched, inspection, navigate]);

    React.useEffect(() => {
        if (instructionDisplayed === false) {
            setShowInstruction(true);
        }
    }, [instructionDisplayed]);

    const plotId = React.useMemo(() => (plotIdParam != null ? parseInt(plotIdParam, 10) : undefined), [plotIdParam]);

    const handleBackClick = React.useCallback(() => {
        navigate(DashboardNavigation());
    }, [navigate]);

    const handleShowInstructionDialog = React.useCallback(() => {
        setShowInstruction(true);
    }, [setShowInstruction]);

    const handleInstructionDialogClose = React.useCallback(() => {
        setShowInstruction(false);
        if (instructionDisplayed === false) {
            setInstructionDisplayed();
        }
    }, [setShowInstruction, instructionDisplayed, setInstructionDisplayed]);

    return (
        <>
            <Helmet>
                <title>{t('inspection.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page className="page-full">
                <Page.Content className={`${styles['root']} row`}>
                    {inspection != null && (
                        <>
                            <InspectionDetails
                                value={inspection}
                                plotId={plotId}
                                onBackClick={handleBackClick}
                            />
                            <PlotDetails
                                inspectionId={inspection.id}
                                plotId={plotId}
                                onShowInstruction={handleShowInstructionDialog}
                            />
                        </>
                    )}
                </Page.Content>
            </Page>
            {showInstruction && (
                <InspectionInstructionDialog
                    instruction={inspection?.instruction}
                    campaignInstruction={inspection?.campaignInstruction}
                    onClose={handleInstructionDialogClose}
                />
            )}
        </>
    );
};
export default withRouter(InspectionScreen, 'INSPECTION');

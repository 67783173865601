import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateToFormat, isArray, isValidDate, orderBy, sortByProperty } from '@dateam/ark';
import { useNavigation } from 'shared-utils';
import List from 'components/ui/List';
import { ApiRef } from 'components/ui/List/List';
import { InspectionNavigation } from 'screens/routes';
import InspectionListItem from './InspectionListItem';
import styles from '../DashboardScreen.module.scss';

type Props = {
    data: App.InspectionSync[];
};

const InspectionList: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation();
    const { push: navigate } = useNavigation();
    const listApiRef = React.useRef<ApiRef>();

    const inspections = React.useMemo(() => {
        if (!isArray(data) || data.length === 0) return [];

        const computedList = [...data];

        orderBy(computedList, 'label');
        orderBy(computedList, 'startDate');
        // This particular implementation is used to sort such as :
        // if the dates are defined, it returns 0.
        // if they are not, it sorts them at the end
        // Allowing to keep the previous sort by startDate and
        // simply making 2 groups (validated and non-validated)
        sortByProperty(computedList, 'validationDate', () => 0);

        return computedList;
    }, [data]);

    const handleItemClick = React.useCallback((inspectionId: number) => {
        navigate(InspectionNavigation(inspectionId));
    }, [navigate]);

    React.useEffect(() => {
        if (!isArray(inspections) || inspections.length === 0) return;
        const inspectionsToComplete = inspections.filter(inspection => inspection.validationDate == null);
        if (inspectionsToComplete.length > 0) {
            listApiRef.current?.scrollTo(`inspection-${inspectionsToComplete[0].id}`, 'top');
        }
    }, [listApiRef, inspections]);

    if (inspections.length === 0) return (<div className={styles['noData']}>{t('dashboard.noInspection')}</div>);

    return (
        <List
            className={`list-scroll ${styles['list']}`}
            apiRef={listApiRef}
        >
            {inspections.map(item => (
                <InspectionListItem
                    id={`inspection-${item.id}-${dateToFormat(item.startDate, 'dd-MM')}`}
                    key={item.id}
                    data={item}
                    onClick={handleItemClick}
                />
            ))}
        </List>
    );
};

InspectionList.propTypes = {

};

InspectionList.defaultProps = {
};

export default React.memo(InspectionList);

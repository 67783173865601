export const parseObservationField = <T>(
    field: App.Api.ObservationField<T> | null
): App.ObservationField<T> | undefined => (field != null ? field.value : undefined);

export const mapObservationField = <T>(
    field: App.ObservationField<T> | undefined
): App.Api.ObservationField<T> | null => {
    if (field === undefined) return null;

    return { value: field };
};
import React from 'react';
import propTypes from 'prop-types';
import { isString } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { useNavigation } from 'shared-utils';
import { ConfirmDialog, Form, Menu } from 'shared-ui';
import { refLabelNumberPropTypes } from 'utils/propTypes';
import { useUnObservableInspectionPlot, useValidateInspectionPlot } from 'data/inspection';
import { useAddObservation } from 'data/observation';
import { InspectionNavigation } from 'screens/routes';
import styles from './PlotDetails.module.scss';

type Props = {
    inspectionId: App.Inspection['id'];
    plotId: App.Plot['id'];
    observations: RefLabel[];
    addDisabled?: boolean;
    isValidated?: boolean;
    isUnObservabled?: boolean;
    hasError?: boolean;
};

const PlotDetailsActions: React.FC<Props> = ({
    inspectionId,
    plotId,
    observations,
    addDisabled,
    isValidated,
    isUnObservabled,
    hasError
}) => {
    const { push: navigate } = useNavigation();
    const { mutateAsync: addObservation } = useAddObservation();
    const { mutateAsync: validate } = useValidateInspectionPlot();
    const { mutateAsync: unObservable } = useUnObservableInspectionPlot();

    const [showNewObservations, setShowNewObservations] = React.useState(false);
    const [showUnobservablePlotConfirm, setShowUnobservablePlotConfirm] = React.useState(false);
    const [unobservableReason, setUnobservableReason] = React.useState('');
    const obsMenuRef = React.useRef<HTMLButtonElement>(null);

    const showObservationMenu = React.useCallback(() => {
        if (showNewObservations === true) return;
        setShowNewObservations(true);
    }, [showNewObservations, setShowNewObservations]);

    const closeObservationMenu = React.useCallback(() => {
        setShowNewObservations(false);
    }, [setShowNewObservations]);

    const onAddObservation = React.useCallback((observationId: number) => {
        addObservation({
            inspectionId,
            plotId,
            observationId: observationId as App.Observation['id']
        });
        setShowNewObservations(false);
    }, [
        inspectionId,
        plotId,
        addObservation,
        setShowNewObservations
    ]);

    const onPlotValidation = React.useCallback(async () => {
        await validate({ inspectionId, plotId });

        navigate(InspectionNavigation(inspectionId));
    }, [
        inspectionId,
        plotId,
        validate,
        navigate
    ]);

    const handleUnobservablePlot = React.useCallback(() => {
        setShowUnobservablePlotConfirm(true);
    }, []);

    const handleConfirmUnobservablePlot = React.useCallback((isOk: boolean) => {
        setShowUnobservablePlotConfirm(false);

        if (isOk === true) {
            unObservable({
                inspectionId,
                plotId,
                reason: unobservableReason
            });
        }
    }, [unObservable, inspectionId, plotId, unobservableReason]);

    const validationDisabled = React.useMemo(() => isValidated === true || hasError === true, [isValidated, hasError]);
    const unObservableDisabled = React.useMemo(
        () => isUnObservabled === true,
        [isUnObservabled]
    );

    const haveUnobservableReason = isString(unobservableReason) && unobservableReason.length > 0;

    return (
        <>
            <div className={styles['actions']}>
                <button
                    type="button"
                    ref={obsMenuRef}
                    className={concatClassName(
                        styles['actionBtn'],
                        styles['actionBtn-unobservable'],
                        unObservableDisabled === true ? styles['actionBtn-disabled'] : null
                    )}
                    onClick={handleUnobservablePlot}
                    disabled={unObservableDisabled}
                >
                    Parcelle non observable
                </button>
                <button
                    type="button"
                    ref={obsMenuRef}
                    className={concatClassName(
                        styles['actionBtn'],
                        styles['actionBtn-secondary'],
                        addDisabled === true ? styles['actionBtn-disabled'] : null
                    )}
                    onClick={showObservationMenu}
                    disabled={addDisabled}
                >
                    Ajouter une observation
                </button>
                <button
                    type="button"
                    className={concatClassName(
                        styles['actionBtn'],
                        styles['actionBtn-primary'],
                        validationDisabled ? styles['actionBtn-disabled'] : null
                    )}
                    onClick={onPlotValidation}
                    disabled={validationDisabled}
                >
                    {isValidated === true ? 'Parcelle validée' : 'Valider la parcelle'}
                </button>
            </div>
            {showNewObservations && (
                <Menu
                    anchorEl={obsMenuRef}
                    position="top-right"
                    onClose={closeObservationMenu}
                    className={styles['addObservationMenu']}
                >
                    {observations.map(item => (
                        <Menu.Item key={item.id} onClick={() => onAddObservation(item.id)}>
                            {item.label}
                        </Menu.Item>
                    ))}
                </Menu>
            )}
            {showUnobservablePlotConfirm && (
                <ConfirmDialog
                    title="Parcelle non observable"
                    disabled={haveUnobservableReason === false}
                    message={(
                        <div>
                            <div>
                                Merci de confirmer que vous ne pouvez pas
                                réaliser d'observation sur la parcelle ?
                            </div>
                            <div>Attention, cette action n'est pas réversible.</div>
                            <br />
                            <Form.Group controlId="unobservableReason" className={styles['reason-form']}>
                                <Form.Input
                                    value={unobservableReason}
                                    onChange={value => setUnobservableReason(value)}
                                    placeholder="Raison"
                                    required
                                    maxlength={250}
                                />
                                {haveUnobservableReason === false && (
                                    <span className="error">La raison est obligatoire</span>
                                )}
                            </Form.Group>
                        </div>
                    )}
                    ok="Je ne peux pas observer la parcelle"
                    onClose={handleConfirmUnobservablePlot}
                />
            )}
        </>
    );
};

PlotDetailsActions.propTypes = {
    inspectionId: propTypes.number.isRequired,
    plotId: propTypes.number.isRequired,
    observations: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
    addDisabled: propTypes.bool,
    isValidated: propTypes.bool,
    isUnObservabled: propTypes.bool,
    hasError: propTypes.bool
};

PlotDetailsActions.defaultProps = {
    addDisabled: false,
    isValidated: false,
    isUnObservabled: false,
    hasError: false
};

export default PlotDetailsActions;

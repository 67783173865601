import React from 'react';
import { createHistoryHook } from '@dateam/ark-react';
var useNavigationHistory = createHistoryHook(function (item, compareWith) {
  return item.compareRoute(compareWith);
});
export var useRoute = function useRoute(route) {
  var deps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var _useNavigationHistory = useNavigationHistory(),
    push = _useNavigationHistory.push;
  React.useEffect(function () {
    push(route);
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};
export default useNavigationHistory;
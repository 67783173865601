import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
};

const InspectionIcon: React.FC<Props> = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" fill="currentColor" stroke="none" className={concatClassName('icon', className)}>
        <path d="M16.4,16.9h-2.6c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6h3.6c-0.3-0.3-0.6-0.7-0.9-1.1h-2.7c-1.5,0-2.7,1.2-2.7,2.7 s1.2,2.7,2.7,2.7h2.6c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6H7.6c-0.3,0.5-0.7,0.8-0.9,1.1h9.8c1.5,0,2.7-1.2,2.7-2.7 C19.1,18.1,17.9,16.9,16.4,16.9z" />
        <path d="M4.8,10.2c-2.4,0-4.4,2-4.4,4.4c0,3.4,3.4,7,3.8,7.4c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2c0.4-0.4,3.8-4,3.8-7.4 C9.2,12.2,7.2,10.2,4.8,10.2z M4.8,21c-0.8-0.9-3.3-3.8-3.3-6.4c0-1.8,1.5-3.3,3.3-3.3S8,12.9,8,14.6C8,17.2,5.6,20.1,4.8,21z" />
        <path d="M4.8,12.6c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2s2-0.9,2-2S5.9,12.6,4.8,12.6z M4.8,15.5c-0.5,0-0.9-0.4-0.9-0.9 c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C5.7,15.1,5.3,15.5,4.8,15.5z" />
        <path d="M19.3,1.7c-2.4,0-4.4,2-4.4,4.4c0,3.5,3.7,7.3,3.8,7.4c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2 c0.1-0.1,3.8-3.9,3.8-7.4C23.6,3.7,21.7,1.7,19.3,1.7z M19.3,12.5C18.5,11.6,16,8.7,16,6.1c0-1.8,1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3 C22.5,8.7,20.1,11.6,19.3,12.5z" />
        <path d="M19.3,4.1c-1.1,0-2,0.9-2,2s0.9,2,2,2c1.1,0,2-0.9,2-2C21.3,5,20.4,4.1,19.3,4.1z M19.3,7c-0.5,0-0.9-0.4-0.9-0.9 c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C20.2,6.6,19.8,7,19.3,7z" />
    </svg>
);

InspectionIcon.propTypes = {
    className: propTypes.string
};

InspectionIcon.defaultProps = {
    className: undefined
};

export default InspectionIcon;

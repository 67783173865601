import React from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import List from 'components/ui/List';
import styles from '../DashboardScreen.module.scss';

type Props = {
    count: number;
}

const DayCountColumn: React.FC<Props> = ({
    count
}) => {
    const { t } = useTranslation();

    return (
        <List.Item.Col width="auto" className={styles['colDays']}>
            {count > 1 && t('dashboard.overlapDays', { count })}
        </List.Item.Col>
    );
};

DayCountColumn.propTypes = {
    count: propTypes.number.isRequired
};

DayCountColumn.defaultProps = {

};

export default DayCountColumn;

import React from 'react';
import propTypes from 'prop-types';
import { isDefined, isString } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { useSyncError } from 'utils/sync';
import { DangerIcon, NoWifiIcon, SyncIcon } from 'components/icons';
import { useSync } from 'data/sync';
import { useActivity } from 'data/activity';
import styles from './Sidebar.module.scss';

type Props = {
    isOnline?: boolean;
    className?: string;
};

const SidebarMenuSync = React.forwardRef<HTMLLIElement, Props>(({
    isOnline,
    className
}: Props, ref) => {
    const { data: activity } = useActivity();
    const { mutateAsync: sync, isLoading: isSyncing } = useSync();
    const [hasSyncError] = useSyncError();
    const rootRef = React.useRef<HTMLLIElement>(null);

    React.useEffect(() => {
        if (!isDefined(ref)) return;
        (ref as any).current = rootRef.current;
    }, [ref]);

    const connectionStatusIcon = React.useMemo(
        () => (
            isOnline ?
                (<SyncIcon className={concatClassName(isSyncing === true ? styles['cw-anim'] : null, styles['sidebarIcon'])} />) :
                (<NoWifiIcon className={styles['sidebarIcon']} />)
        ),
        [
            isOnline,
            isSyncing
        ]
    );
    const syncStatusIcon = React.useMemo(
        () => (
            isString(activity?.syncState) && activity?.syncState !== 'none' ?
                (<DangerIcon className={styles['sidebarInfoIcon']} />) :
                undefined
        ),
        [activity]
    );
    const errorStatusIcon = React.useMemo(
        () => (
            hasSyncError === true ?
                (<DangerIcon className={styles['sidebarErrorIcon']} />) :
                undefined
        ),
        [hasSyncError]
    );

    const colorStatus = React.useMemo(() => (activity?.syncState === 'full' ? 'primary' : 'warning'), [activity]);

    const handleButtonClick = React.useCallback(() => {
        if (isSyncing === true) return;

        sync();
    }, [sync, isSyncing]);

    return (
        <li
            ref={rootRef}
            className={concatClassName(
                styles['menuItem'],
                isString(colorStatus) ? styles[`menuItem-${colorStatus}`] : null,
                className
            )}
        >
            <button type="button" className={styles['menuItemRoot']} onClick={handleButtonClick}>
                <span className={styles['menuItemIcon']}>{connectionStatusIcon}</span>
                <span className={styles['menuItemText']}>Synchro</span>
                {syncStatusIcon != null && (
                    <span className={styles['itemInfo']}>{syncStatusIcon}</span>
                )}
                {errorStatusIcon != null && (
                    <span className={styles['itemError']}>{errorStatusIcon}</span>
                )}
            </button>
        </li>
    );
});

SidebarMenuSync.propTypes = {
    isOnline: propTypes.bool,
    className: propTypes.string
};

SidebarMenuSync.defaultProps = {
    isOnline: false,
    className: undefined
};

export default SidebarMenuSync;

import React from 'react';
import propTypes from 'prop-types';
import { isFunction } from '@dateam/ark';
import { Button } from 'shared-ui';
import { ChevronLeftIcon } from 'components/icons';
import styles from './InspectionDetails.module.scss';

type Props = {
    data: App.Inspection;
    onBackClick?: () => void;
}

const InspectionDetails: React.FC<Props> = ({
    data,
    onBackClick
}) => {
    const handleBackBtnClick = React.useCallback(() => {
        if (isFunction(onBackClick)) {
            onBackClick();
        }
    }, [onBackClick]);

    return (
        <header className={styles['header']}>
            <div className={styles['headerTitle']}>
                <span className={styles['headerTitleLabel']}>Ma visite</span>
                <h1 className={styles['headerTitleName']}>
                    {data.label}
                </h1>
            </div>
            <div className={styles['headerAction']}>
                <Button
                    size="lg"
                    className={styles['backBtn']}
                    startIcon={(<ChevronLeftIcon />)}
                    onClick={handleBackBtnClick}
                >
                    Retour
                </Button>
            </div>
        </header>
    );
};

InspectionDetails.propTypes = {
    data: propTypes.any.isRequired,
    onBackClick: propTypes.func
};

InspectionDetails.defaultProps = {
    onBackClick: undefined
};

export default InspectionDetails;

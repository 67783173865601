import React from 'react';
import propTypes from 'prop-types';
import styles from '../PlanningScreen.module.scss';

type Props = {
    position: number;
    label?: string;
    customer?: string;
}

const InspectionPlotItem: React.FC<Props> = ({
    position,
    label,
    customer
}) => {
    return (
        <li className={styles['plotItem']}>
            <div className={styles['plotItemHeader']}>
                <span className={styles['plotItemNum']}>{position}</span>
            </div>
            <div className={styles['plotItemInfo']}>
                <span className={styles['plotItemName']}>{label}</span>
                <span className={styles['plotItemCustomer']}>{customer?.toUpperCase()}</span>
            </div>
        </li>
    );
};

InspectionPlotItem.propTypes = {
    position: propTypes.number.isRequired,
    label: propTypes.string,
    customer: propTypes.string
};

InspectionPlotItem.defaultProps = {
    label: undefined,
    customer: undefined
};

export default InspectionPlotItem;

export const ADVENTICE_VALUES = [
    'Achillée millefeuille',
    'Agrostide',
    'Ail',
    'Amarante',
    'Ammi élevé',
    'Armoise',
    'Arroche etalée',
    'Blé',
    'Brome',
    'Bryone',
    'Cabaret des oiseaux',
    'Camomille',
    'Capselle',
    'Cardamine',
    'Carotte',
    'Chardon',
    'Chelidoine',
    'Chénopode blanc',
    'Chiendent',
    'Cirse',
    'Clématite',
    'Colza',
    'Compagnon blanc',
    'Consoude',
    'Coquelicot',
    'Dactyle',
    'Datura',
    'Digitaire',
    'Diplotaxis',
    'Epilobe',
    'Erigéron',
    'Erodium',
    'Euphorbe',
    'Fétuque élevée',
    'Fétuque rouge',
    'Folle avoine',
    'Fraisier des bois',
    'Fumeterre',
    'Gaillet gratteron',
    'Géranium feuilles découpées',
    'Géranium feuilles rondes',
    'Géranium herbe à Robert',
    'Géranium mou',
    'Grande berce',
    'Herbe aux chantres',
    'Laiteron des champs',
    'Laiteron rude',
    'Laitue sauvage',
    'Lamier blanc',
    'Lamier pourpre',
    'Linaire',
    'Liseron des champs',
    'Liseron des haies',
    'Lotier',
    'Luzerne tachetée',
    'Lychnis dioïque',
    'Mâche',
    'Matricaire',
    'Mauve',
    'Mercuriale',
    'Millepertuis',
    'Morelle noire',
    'Mouron bleu',
    'Mouron rouge',
    'Moutarde',
    'Muscari',
    'Muscari à toupet',
    'Myosotis',
    'Orge des rats',
    'Ortie brulante',
    'Ortie dioïque',
    'Pâquerette',
    'Pâturin',
    'Pensée',
    'Picride',
    'Picris',
    'Pissenlit',
    'Plantain lancéolé',
    'Plantain major',
    'Potentille',
    'Prêle',
    'Primevère',
    'Ray-grass',
    'Renoncule',
    'Renouée des oiseaux',
    'Renouée liseron',
    'Renouée persicaire',
    'Reseda',
    'Ronce',
    'Rumex',
    'Sedum',
    'Séneçon',
    'Sétaire',
    'Stellaire',
    'Torilis',
    'Trèfle blanc',
    'Trèfle incarnat',
    'Trèfle violet',
    'Vergerette',
    'Véronique',
    'Vesce',
    'Vipérine'
];
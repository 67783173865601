import React from 'react';
import propTypes from 'prop-types';
import { isFunction, isValidDate } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import CustomerContactModal from 'components/CustomerContactModal';
import { useObservation, sortObservations } from 'data/observation';
import { useInspectionPlots } from 'data/effects';
import PlotDetailsHeader from './PlotDetailsHeader';
import PlotDetailsInfo from './PlotDetailsInfo';
import PlotDetailsActions from './PlotDetailsActions';
import PlotObservations from './PlotObservations';
import styles from './PlotDetails.module.scss';

type Props = {
    inspectionId: App.Inspection['id'];
    plotId: App.Plot['id'] | undefined;
    onShowInstruction?: () => void;
};

const PlotDetails: React.FC<Props> = ({
    inspectionId,
    plotId,
    onShowInstruction
}) => {
    const { data: plots } = useInspectionPlots(inspectionId);
    const [showContact, setShowContact] = React.useState(false);

    const selectedPlot = React.useMemo(() => plots?.find(plot => plot.id === plotId) ?? null, [plots, plotId]);

    const { data: observation } = useObservation();
    const ploObservationIds = selectedPlot?.observations.map<number>(obs => obs.id) ?? [];
    const unusedObservation = sortObservations(observation
        ?.filter(value => !(ploObservationIds.includes(value.id))) ?? []);

    const handleShowInstruction = React.useCallback(() => {
        if (isFunction(onShowInstruction)) {
            onShowInstruction();
        }
    }, [onShowInstruction]);

    const handleShowContactModal = React.useCallback(() => {
        setShowContact(true);
    }, [setShowContact]);

    const handleContactModalClose = React.useCallback(() => {
        setShowContact(false);
    }, [setShowContact]);

    if (selectedPlot == null) return null;

    const addObservationDisabled = unusedObservation.length === 0;
    const isValidated = isValidDate(selectedPlot.validationDate);
    const isUnObservabled = selectedPlot.ignored === true || isValidated;

    return (
        <section
            className={
                concatClassName(
                    styles['root'],
                    selectedPlot.ignored === true ? styles['plot-disabled'] : null
                )
            }
        >
            <PlotDetailsHeader
                id={selectedPlot.publicId}
                label={selectedPlot.label}
                onShowInstruction={handleShowInstruction}
                onShowContact={handleShowContactModal}
            />
            {selectedPlot.ignored === true && (
                <div className={styles['plot-unobserable']}>
                    <div className={styles['plot-unobserable-text']}>
                        La parcelle n'est pas observable.
                    </div>
                </div>
            )}
            <div className={styles['content']}>
                <PlotDetailsInfo data={selectedPlot} />
                <PlotObservations
                    data={selectedPlot.observations}
                    inspectionId={inspectionId}
                    plot={selectedPlot}
                />
                <PlotDetailsActions
                    inspectionId={inspectionId}
                    plotId={selectedPlot.id}
                    observations={unusedObservation}
                    addDisabled={addObservationDisabled}
                    isValidated={isValidated}
                    isUnObservabled={isUnObservabled}
                    hasError={selectedPlot.isValid === false}
                />
            </div>
            {showContact && (
                <CustomerContactModal
                    customer={selectedPlot.customer}
                    onClose={handleContactModalClose}
                />
            )}
        </section>
    );
};

PlotDetails.propTypes = {
    inspectionId: propTypes.number.isRequired,
    plotId: propTypes.number,
    onShowInstruction: propTypes.func
};

PlotDetails.defaultProps = {
    plotId: undefined,
    onShowInstruction: undefined
};

export default PlotDetails;

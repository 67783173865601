import React from 'react';
import { objectPick } from '@dateam/ark';
import { QueryResult, queryResultKeys } from 'data/constants';
import { useActivity } from 'data/activity';

type EffectResult<T> = AssertPropDefined<QueryResult<T>, 'data'>;

export const useInspections = (options?: DataOptions): EffectResult<App.InspectionSync[]> => {
    const { data: activity, ...queryData } = useActivity(options);

    return React.useMemo(() => {
        const result = activity?.inspections ?? [];

        return objectPick({
            ...queryData,
            data: result
        } as EffectResult<App.InspectionSync[]>, queryResultKeys);
    }, [activity, queryData]);
};
import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'shared-ui';
import { isDefined, isValidDate, ObjectAccessor, formatDate } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { validate as validator, ValidationError } from 'data/observation';
import { ObservationProps, plotObservationPropTypes } from './types';
import { ObservationErrors } from './components';
import ObservationField from './ObservationField';
import ObservationItem from './ObservationItem';
import styles from './Observation.module.scss';

const observationKey = 'observation7';

type Props = ObservationProps<App.Observation7>;

const Observation7: React.FC<Props> = ({
    data,
    plot,
    ignorable,
    className,
    onChange
}: Props) => {
    const [validationErrors, setValidationErrors] = React.useState<Record<string, ValidationError>>({});
    const internalData = React.useRef(data);

    React.useEffect(() => {
        internalData.current = data;
    }, [data]);

    React.useEffect(() => {
        if (data.count === undefined &&
            data.comment === undefined) return;

        setValidationErrors(validator(data, plot));
    }, [plot, data]);

    const count = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.count ?? undefined;
    }, [data]);

    const comment = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.comment ?? undefined;
    }, [data]);

    const previousValue = React.useMemo(() => {
        if (isDefined(internalData.current.previous)) {
            return (
                <>
                    {isValidDate(internalData.current.previous.updatedOn) && (
                        <div className={styles['previousDate']}>{formatDate(internalData.current.previous.updatedOn)}</div>
                    )}
                    <div className={styles['previous']}>Comptage: {internalData.current.previous.count?.value ?? '-'}</div>
                </>
            );
        }

        return (<div>Aucune valeur disponible</div>);
    }, [internalData.current]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleIgnoreObservation = React.useCallback(() => {
        const newData: App.Observation7 = {
            ...internalData.current,
            count: null,
            comment: null,
            ignored: !internalData.current.ignored
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleCountChange = React.useCallback((newValue: number | undefined) => {
        const newData: App.Observation7 = {
            ...internalData.current,
            count: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleCommentChange = React.useCallback((newValue: string | undefined) => {
        const newData: App.Observation7 = {
            ...internalData.current,
            comment: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleFocus = React.useCallback((field: ObjectAccessor<Omit<App.Observation7, 'previous'>>) => {
        if (internalData.current[field] !== undefined) return;

        const newData: App.Observation7 = {
            ...internalData.current,
            [field]: null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    return (
        <ObservationItem
            data={data}
            ignorable={ignorable}
            onIgnore={handleIgnoreObservation}
            previous={previousValue}
            label="Ceps manquants"
        >
            <div className="row">
                <ObservationField
                    id={`${observationKey}-count`}
                    label="Comptage"
                    offLabel="/200 ceps"
                    className={concatClassName(styles[`${observationKey}-count`], 'col-4')}
                >
                    <Form.Number
                        min={0}
                        max={200}
                        value={count}
                        onChange={handleCountChange}
                        state={validationErrors['count']?.type}
                        onFocus={() => handleFocus('count')}
                        required={!data.ignored}
                        readOnly={data.ignored}
                    />
                </ObservationField>
            </div>
            <div className="row">
                <ObservationField
                    id={`${observationKey}-comment`}
                    label="Commentaire"
                    className={concatClassName(styles[`${observationKey}-comment`], 'col-12')}
                >
                    <Form.Input
                        multiline
                        rows={2}
                        value={comment}
                        onChange={handleCommentChange}
                        state={validationErrors['comment']?.type}
                        readOnly={data.ignored}
                    />
                </ObservationField>
            </div>
            <ObservationErrors value={validationErrors} observationKey={observationKey} />
        </ObservationItem>
    );
};

Observation7.propTypes = {
    data: propTypes.any.isRequired,
    plot: plotObservationPropTypes.isRequired,
    className: propTypes.string,
    onChange: propTypes.func
};

Observation7.defaultProps = {
    className: undefined,
    onChange: undefined
};

export default Observation7;

import React from 'react';
import errorProvider from './errorProvider';
import ErrorDialog from './ErrorDialog';
import styles from './ErrorHandler.module.scss';

type Props = Record<string, never>;

const ErrorHandler: React.FC<Props> = (props: Props) => {
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        const unsubscribe = errorProvider.subscribe((error: Error) => {
            errorProvider.forget();

            setError(error.message ?? '');
        });

        return () => {
            unsubscribe();
        };
    }, [setError]);

    const close = React.useCallback(() => {
        setError(null);
    }, [setError]);

    if (error === null) return null;

    return (
        <ErrorDialog
            title="Une erreur est survenue"
            message={error}
            onClose={close}
            className={styles['errorDialog']}
        />
    );
};

export default ErrorHandler;
export type ValidationError = {
    field: string;
    type: 'error' | 'warning';
    code: string;
};

export class RecordValue<T> {
    value: T;

    constructor(value: T) {
        this.value = value;
    }
}
import React from 'react';
import { useSyncParam } from 'utils/sync';
import { useActivity } from 'data/activity';
import { useInspections } from 'data/effects';
import SyncParamOverwriteModal from 'components/SyncParamOverwriteModal';
import SyncParamSelector from './SyncParamSelector';
import InspectionList from './InspectionList';

type Props = Record<string, never>;

const Activity: React.FC<Props> = () => {
    const [syncParam, setSyncParam] = useSyncParam();
    const { data: activity } = useActivity();
    const { data: inspections } = useInspections();
    const [paramConfirmationRequired, setParamConfirmationRequired] = React.useState(false);
    const [pendingSyncParam, setPendingSyncParam] = React.useState<Nullable<App.SyncParam>>(null);

    const handleFilterChange = React.useCallback((param: App.SyncParam) => {
        if (activity?.syncState === 'none') {
            setSyncParam({ ...param });
        }
        else {
            setPendingSyncParam({ ...param });
            setParamConfirmationRequired(true);
        }
    }, [activity, setSyncParam, setPendingSyncParam, setParamConfirmationRequired]);

    const resetParamConfirmState = React.useCallback(() => {
        setPendingSyncParam(null);
        setParamConfirmationRequired(false);
    }, [setPendingSyncParam, setParamConfirmationRequired]);

    const confirmParam = React.useCallback(() => {
        if (pendingSyncParam != null) setSyncParam({ ...pendingSyncParam });

        resetParamConfirmState();
    }, [pendingSyncParam, setSyncParam, resetParamConfirmState]);

    return (
        <>
            <SyncParamSelector
                value={syncParam ?? {}}
                onChange={handleFilterChange}
            />
            <InspectionList data={inspections} />
            {paramConfirmationRequired === true && (
                <SyncParamOverwriteModal
                    onConfirm={confirmParam}
                    onCancel={resetParamConfirmState}
                />
            )}
        </>
    );
};

export default Activity;

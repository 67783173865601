import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
};

const SyncIcon: React.FC<Props> = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={concatClassName('icon', className)}>
        <polyline points="23,2.8 23,7.6 18.2,7.6 " />
        <path d="M1,12C1,5.9,5.9,1,12,1c4.4,0,8.2,2.6,9.9,6.3" />
        <polyline points="1,21.2 1,16.4 5.8,16.4 " />
        <path d="M23,12c0,6.1-4.9,11-11,11c-4.4,0-8.2-2.6-9.9-6.3" />
    </svg>
);

SyncIcon.propTypes = {
    className: propTypes.string
};

SyncIcon.defaultProps = {
    className: undefined
};

export default SyncIcon;

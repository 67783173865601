import { isArray, isString, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Entretien du sol
 ** - row1: Général ou 1er rang
 **     Max 2 chiffres et peut se terminer par 'P'
 **     Required
 ** - row2: 2ème rang
 **     Max 2 chiffres et peut se terminer par 'P'
 ** - processedRow: Rang de traitement
 **     Max 2 chiffres et peut se terminer par 'P'
 ** - comment: Commentaire
 */

type CurrentObservation = App.Observation3;
type CurrentApiObservation = App.Api.Observation3;

export const FORMAT_REGEXP = /^[0-9]{2}P?$/;

export const validate = (
    observation: CurrentObservation,
    _: App.Plot
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, row1, row2, processedRow, comment } = observation;

    if (ignored === true) return validation;

    // The value must be defined
    if (row1 == null || !isString(row1) || row1.trim().length === 0) {
        validation.row1 = {
            field: 'row1',
            type: 'error',
            code: 'required'
        };
    }
    else {
        const matchGroups = row1.match(FORMAT_REGEXP);

        // If the value doesn't match the RegExp, then the value doesn't respect the expected format
        if (!isArray(matchGroups)) {
            validation.row1 = {
                field: 'row1',
                type: 'error',
                code: 'format'
            };
        }
    }

    if (isString(row2) && row2.length > 0) {
        const matchGroups = row2.match(FORMAT_REGEXP);

        // If the value doesn't match the RegExp, then the value doesn't respect the expected format
        if (!isArray(matchGroups)) {
            validation.row2 = {
                field: 'row2',
                type: 'error',
                code: 'format'
            };
        }
    }

    if (isString(processedRow) && processedRow.length > 0) {
        const matchGroups = processedRow.match(FORMAT_REGEXP);

        // If the value doesn't match the RegExp, then the value doesn't respect the expected format
        if (!isArray(matchGroups)) {
            validation.processedRow = {
                field: 'processedRow',
                type: 'error',
                code: 'format'
            };
        }
    }

    if (comment != null && !isString(comment)) {
        validation.comment = {
            field: 'comment',
            type: 'error',
            code: 'format'
        };
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        row1,
        row2,
        processedRow,
        comment
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.row1 = parseObservationField(row1);
    parsedObs.row2 = parseObservationField(row2);
    parsedObs.processedRow = parseObservationField(processedRow);
    parsedObs.comment = parseObservationField(comment);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        row1,
        row2,
        processedRow,
        comment
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.row1 = row1 !== undefined ? new RecordValue(row1) : null;
    mappedObs.row2 = row2 !== undefined ? new RecordValue(row2) : null;
    mappedObs.processedRow = processedRow !== undefined ? new RecordValue(processedRow) : null;
    mappedObs.comment = comment !== undefined ? new RecordValue(comment) : null;

    return mappedObs;
};

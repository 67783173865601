import React from 'react';
import propTypes from 'prop-types';
import List from 'components/ui/List';
import { useLocale } from 'src/i18n/i18nLoader';
import styles from '../DashboardScreen.module.scss';

type Props = {
    name: string;
}

const PlotColumn: React.FC<Props> = ({ name }) => (
    <List.Item.Col width="auto" className={styles['colPlot']}>
        {name}
    </List.Item.Col>
);

PlotColumn.propTypes = {
    name: propTypes.string.isRequired
};

PlotColumn.defaultProps = {
};

export default PlotColumn;

import { isValidNumber } from '@dateam/ark';
import { Router, RouteData, NavigationRoute } from 'shared-utils';
import InspectionScreen from './InspectionScreen';

export const InspectionNavigation = (inspectionId: App.Inspection['id'], plotId?: App.PlotIdentity['id']): NavigationRoute => {
    if (isValidNumber(plotId)) {
        return new NavigationRoute(
            InspectionScreen.key,
            [inspectionId, plotId]
        );
    }

    return new NavigationRoute(
        InspectionScreen.key,
        [inspectionId]
    );
};

const router = new Router();

router
    .add(new RouteData({
        key: InspectionScreen.key,
        path: '/<:inspectionId>', // Should have matched the pattern <:inspectionId(\\d+)>/<:plot(plot)?>/<:plotId(\\d+)?> if react-router V6 supported it
        component: InspectionScreen,
        exact: true,
        metadata: {
            authRequired: true
        }
    }))
    .add(new RouteData({
        key: InspectionScreen.key,
        path: '/<:inspectionId>/plot/<:plotId?>', // Should have matched the pattern <:inspectionId(\\d+)>/<:plot(plot)?>/<:plotId(\\d+)?> if react-router V6 supported it
        component: InspectionScreen,
        exact: true,
        metadata: {
            authRequired: true
        }
    }));

export default router;

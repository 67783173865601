import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { ChevronRightIcon } from 'components/icons';

type Props = {
    className?: string;
};

const ListItemColNav: React.FC<Props> = ({
    className
}: Props) => (
    <div className={concatClassName('listItemCol listItemColNav', className)}>
        <ChevronRightIcon className="listItemNavIcon" />
    </div>
);

ListItemColNav.propTypes = {
    className: propTypes.string
};

ListItemColNav.defaultProps = {
    className: undefined
};

export default ListItemColNav;

import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import ObservationHeader from './ObservationHeader';
import styles from './Observation.module.scss';

type Props = {
    label?: string;
    className?: string;
    data?: any;
    ignorable?: boolean;
    previous?: React.ReactNode;
    children?: React.ReactNode;
    onIgnore?: () => void;
};

const ObservationItem: React.FC<Props> = ({
    label,
    className,
    data,
    ignorable,
    previous,
    children,
    onIgnore
}: Props) => (
    <div className={concatClassName(styles['root'], className)}>
        <ObservationHeader label={label} data={data} ignorable={ignorable} previous={previous} onIgnore={onIgnore} />
        {children}
    </div>
);

ObservationItem.propTypes = {
    label: propTypes.string,
    className: propTypes.string,
    data: propTypes.any,
    ignorable: propTypes.bool,
    previous: propTypes.node,
    children: propTypes.node,
    onIgnore: propTypes.func
};

ObservationItem.defaultProps = {
    label: undefined,
    className: undefined,
    data: undefined,
    ignorable: false,
    previous: undefined,
    children: undefined,
    onIgnore: undefined
};

export default ObservationItem;

import React from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import List from 'components/ui/List';
import styles from '../DashboardScreen.module.scss';

type Props = {
    value: number;
    count: number;
}

const ProgressColumn: React.FC<Props> = ({
    value,
    count
}) => {
    const { t } = useTranslation();

    return (
        <List.Item.Col width="auto" className={styles['colProgress']}>
            <div className={styles['rowtitle']}>Avancement</div>
            <div>
                <span>{value}</span>/<span>{t('dashboard.plotCount', { count })}</span>
            </div>
        </List.Item.Col>
    );
};

ProgressColumn.propTypes = {
    value: propTypes.number.isRequired,
    count: propTypes.number.isRequired
};

ProgressColumn.defaultProps = {
};

export default ProgressColumn;

import React from 'react';
import propTypes from 'prop-types';
import { isFunction, isString } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import styles from './InspectionDetails.module.scss';

type Props = {
    id?: string;
    position: number;
    label?: string;
    customer?: string;
    active?: boolean;
    tag?: string | null;
    state?: 'none' | 'ok' | 'error' | 'unObservable';
    onClick?: () => void;
}

const InspectionPlotItem: React.FC<Props> = ({
    id,
    position,
    label,
    customer,
    active,
    tag,
    state,
    onClick
}) => {
    const handleOnItemClick = React.useCallback(() => {
        if (isFunction(onClick)) {
            onClick();
        }
    }, [onClick]);

    return (
        <li
            id={id}
            className={concatClassName(
                styles['plotItem'],
                active === true ? styles['plotItem-active'] : null,
                isString(state) ? styles[`plotItem-state-${state}`] : null
            )}
            onClick={handleOnItemClick}
        >
            <div className={styles['plotItemHeader']}>
                <span className={styles['plotItemNum']}>{position}</span>
            </div>
            <div className={styles['plotItemInfo']}>
                <span className={styles['plotItemName']}>{label}</span>
                <span className={styles['plotItemCustomer']}>{customer?.toUpperCase()}</span>
            </div>
            {tag != null && (
                <span
                    className={concatClassName(
                        styles['tag'],
                        tag === 'N' ? styles['tag-ntester'] : null,
                        tag === 'B' ? styles['tag-bsv'] : null
                    )}
                >
                    <span className={styles['tagLabel']}>{tag}</span>
                </span>
            )}
        </li>
    );
};

InspectionPlotItem.propTypes = {
    id: propTypes.string,
    position: propTypes.number.isRequired,
    label: propTypes.string,
    customer: propTypes.string,
    active: propTypes.bool,
    state: propTypes.oneOf(['none', 'ok', 'error']),
    tag: propTypes.string,
    onClick: propTypes.func
};

InspectionPlotItem.defaultProps = {
    id: undefined,
    label: undefined,
    customer: undefined,
    active: undefined,
    state: undefined,
    tag: undefined,
    onClick: undefined
};

export default InspectionPlotItem;

import { QueryOptions, UseBaseMutationResult, UseQueryResult } from 'react-query';
import { ServiceError, isNumber } from '@dateam/ark';
import logger from 'utils/logger';
import config from 'config';
import { errorProvider } from 'components/errorHandler';
import { unauthorizedProvider } from 'components/unauthorizedHandler';
import { setSyncError } from 'utils/sync';

export const USER_KEY = 'user';
export const ACTIVITY_KEY = 'activity';
export const SYNC_KEY = 'sync';
export const PLANNING_KEY = 'planning';
export const REF_KEY = 'ref';
export const REF_OBSERVATOR_KEY = 'observator';
export const REF_ADVENTICE_KEY = 'adventice';
export const OBS_KEY = 'observation';

const _queryResultKeys = [
    'data',
    'error',
    'isError',
    'isFetched',
    'isFetching',
    'isIdle',
    'isLoading',
    'isPlaceholderData',
    'isStale',
    'isSuccess',
    'refetch',
    'remove'
] as const;

const _mutateResultKeys = [
    'data',
    'error',
    'isError',
    'isIdle',
    'isLoading',
    'isSuccess',
    'mutate',
    'mutateAsync'
] as const;

export const queryResultKeys: readonly ValuesOf<typeof _queryResultKeys>[] = _queryResultKeys;
export const mutateResultKeys: readonly ValuesOf<typeof _mutateResultKeys>[] = _mutateResultKeys;
export type QueryResult<TData = unknown> =
    Pick<UseQueryResult<TData, ServiceError>, ValuesOf<typeof queryResultKeys>>;

export type MutationResult<TData = unknown, TVariables = void, TContext = unknown> =
    Pick<UseBaseMutationResult<TData, ServiceError, TVariables, TContext>, ValuesOf<typeof mutateResultKeys>>;

export const queryRetry: QueryOptions<unknown, ServiceError>['retry'] = (failureCount, error) => {
    if (error instanceof ServiceError && error.code.indexOf('_UNAUTHORIZED')) {
        return false;
    }

    if (isNumber(config.query.retry)) {
        return failureCount < (config.query.retry ?? 0);
    }

    return config.query.retry as boolean;
};

export const onServiceError = (error: ServiceError): void => {
    setSyncError(true);
    logger.warn(error);
    if (!(error instanceof ServiceError)) return;

    if (error.code.indexOf('_UNAUTHORIZED') >= 0) return unauthorizedProvider.notify();

    errorProvider.notify(error);
};

export const defaultDataOptions = {
    enabled: true,
    staleTime: Infinity,
    refetchInterval: Infinity,
    onError: onServiceError,
    retry: queryRetry
};

export const defaultActionOptions = {
    onError: onServiceError
};
import { assert, assertIsArray, assertIsDefined, HttpStatusCode, isDefined, isString, ServiceError, stringFormat } from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';
import logger from 'utils/logger';

export const LOGIN_UNAUTHORIZED = 'LOGIN_UNAUTHORIZED';

export const login = async (data: App.AuthCredentials): Promise<App.UserInfo> => {
    let response;
    try {
        const requestUrl = stringFormat('{0}/auth', config.apiUrl);
        const { username, password } = data;

        response = await httpService.post<App.UserInfo>(requestUrl, {
            username,
            password
        }, {
            passToken: false
        });
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling login API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send login request');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('LOGIN_UNAUTHORIZED');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'LOGIN_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsDefined(response.body, 'unable to access request data');
        assert(isString(response.body.token) && response.body.token.length > 0, 'token is missing from the response');
    }
    catch (err) {
        throw new ServiceError('LOGIN_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const renewAuth = async (): Promise<App.UserInfo> => {
    let response;
    try {
        const requestUrl = stringFormat('{0}/auth/renew', config.apiUrl);

        response = await httpService.post<App.UserInfo>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling login API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send login request');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('RENEW_UNAUTHORIZED');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'RENEW_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsDefined(response.body, 'unable to access request data');
        assert(isString(response.body.token) && response.body.token.length > 0, 'token is missing from the response');
    }
    catch (err) {
        throw new ServiceError('RENEW_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export const getObservators = async (): Promise<App.User[]> => {
    let response;

    try {
        const requestUrl = stringFormat('{0}/user/observator', config.apiUrl);

        response = await httpService.get<App.User[]>(requestUrl, {});
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling user observator API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send bio request (getTypes)');
    if (response.status === HttpStatusCode.Unauthorized) {
        throw new ServiceError('USER_GET_OBSERVATOR_UNAUTHORIZED');
    }

    if (isDefined(response.error)) {
        throw new ServiceError(
            response.error.code ?? 'USER_GET_OBSERVATOR_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        assert(response.jsonContent === true, 'excepted JSON data');
        assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new ServiceError('USER_GET_OBSERVATOR_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
};

const CalendarIcon: React.FC<Props> = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={concatClassName('icon', className)}>
        <path d="M4,4h16c1.1,0,2,0.9,2,2v14c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6C2,4.9,2.9,4,4,4z" />
        <line x1="17" y1="2" x2="17" y2="6" />
        <line x1="7" y1="2" x2="7" y2="6" />
        <line x1="2" y1="10" x2="22" y2="10" />
        <line x1="6.8" y1="14" x2="6.8" y2="14" />
        <line x1="12" y1="14" x2="12" y2="14" />
        <line x1="17.2" y1="14" x2="17.2" y2="14" />
        <line x1="6.8" y1="18" x2="6.8" y2="18" />
        <line x1="12" y1="18" x2="12" y2="18" />
        <line x1="17.2" y1="18" x2="17.2" y2="18" />
    </svg>
);

CalendarIcon.propTypes = {
    className: propTypes.string
};

CalendarIcon.defaultProps = {
    className: undefined
};

export default CalendarIcon;

import { createGlobalStateHook } from '@dateam/ark-react';

const syncParamGlobal = createGlobalStateHook<Nullable<App.SyncParam>>(null);

export const useSyncParam = syncParamGlobal.hook;
export const setSyncParam = syncParamGlobal.setState;
export const getSyncParam = syncParamGlobal.getState;

const lastSyncDateGlobal = createGlobalStateHook<Nullable<Date>>(null);

export const useLastSync = lastSyncDateGlobal.hook;
export const setLastSync = lastSyncDateGlobal.setState;
export const getLastSync = lastSyncDateGlobal.getState;

const syncErrorDateGlobal = createGlobalStateHook<boolean>(false);

export const useSyncError = syncErrorDateGlobal.hook;
export const setSyncError = syncErrorDateGlobal.setState;
export const getSyncError = syncErrorDateGlobal.getState;

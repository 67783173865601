import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
};

const DangerIcon: React.FC<Props> = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.26 13.63" preserveAspectRatio="xMidYMid meet" fill="currentColor" className={concatClassName('icon', className)}>
        <path transform="translate(-5.96 -0.27)" d="M7.09,9.3A1.13,1.13,0,0,1,6,8.17V1.4a1.13,1.13,0,1,1,2.25,0V8.17A1.13,1.13,0,0,1,7.09,9.3Z" />
        <circle cx="1.13" cy="12.5" r="1.13" />
    </svg>
);

DangerIcon.propTypes = {
    className: propTypes.string
};

DangerIcon.defaultProps = {
    className: undefined
};

export default DangerIcon;

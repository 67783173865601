import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HelmetProvider } from 'react-helmet-async';
import { ConnectivityProvider } from '@dateam/ark-react';
import { RouterProvider } from 'shared-utils';
import { initClient, queryClient } from 'utils/queryClient';
import tokenStore from 'utils/tokenStore';
import connectivity from 'utils/connectivity';
import { getUserState, useUserState } from 'utils/userStore';
import { ServiceWorkerUpdateNotifier } from 'components/ServiceWorkerUpdateNotifier';
import { ErrorHandler } from 'components/errorHandler';
import { UnauthorizedHandler, unauthorizedProvider } from 'components/unauthorizedHandler';
import { useSyncManager } from 'data/sync';
import { router } from 'screens';
import { LogOutNavigation } from 'screens/routes';
import './i18n';
import './App.scss';
import useAppInitialize from './useAppInitialize';

initClient();

const App = () => {
    const isReady = useAppInitialize();
    const [unauthorizedNavigation] = React.useState(LogOutNavigation());
    const [user] = useUserState();
    const syncedUser = React.useRef<typeof user>(null);
    const { start: startSync, stop: stopSync } = useSyncManager();

    React.useEffect(() => {
        if (!isReady) return;

        const user = getUserState();
        if (user == null) {
            unauthorizedProvider.notify(true);
        }
    }, [isReady]);

    React.useEffect(() => {
        if (user !== syncedUser.current) {
            if (user != null) {
                syncedUser.current = user;
                startSync();
            }

            if (user == null) {
                syncedUser.current = null;
                stopSync();
            }
        }
    }, [user, syncedUser, startSync, stopSync]);

    if (!isReady) return null;

    return (
        <RouterProvider router={router}>
            <ErrorHandler />
            <UnauthorizedHandler route={unauthorizedNavigation} tokenStore={tokenStore} />
        </RouterProvider>
    );
};

const AppWrapper = () => (
    <QueryClientProvider client={queryClient}>
        <ConnectivityProvider value={connectivity}>
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </ConnectivityProvider>
        <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
        <ServiceWorkerUpdateNotifier />
    </QueryClientProvider>
);

export default AppWrapper;
import React from 'react';
import propTypes from 'prop-types';
import { isArray, isValidNumber, orderBy } from '@dateam/ark';
import { propTypeAllowUndefined } from '@dateam/ark-react';
import { Form } from 'shared-ui';
import { useObservators } from 'data/user';
import { UserIcon } from 'components/icons';

type Props = {
    value: Partial<App.SyncParam>;
    onChange?: (param: App.SyncParam) => void;
};

const SyncParamSelector: React.FC<Props> = ({
    value,
    onChange
}) => {
    const { data: observators } = useObservators();

    const observatorData = React.useMemo(() => {
        if (!isArray(observators)) return [];

        const data = observators.map(observator => ({
            id: observator.id,
            fullname: `${observator.firstName} ${observator.lastName}`
        }));

        orderBy(data, 'fullname');

        return data;
    }, [observators]);

    const handleObsSelectorChange = React.useCallback((newValue: number) => {
        onChange?.({ ...value, userId: newValue });
    }, [value, onChange]);

    return (
        <div className="row">
            <Form.Group
                controlId="filter-obs"
                className="col col-5 form-material"
            >
                <Form.Label>Observateur</Form.Label>
                <Form.Select
                    selected={value.userId}
                    onChange={handleObsSelectorChange}
                >
                    {observatorData.map(observator => (
                        <Form.Select.Option
                            key={observator.id}
                            value={observator.id}
                            selectedText={observator.fullname}
                        >
                            {observator.fullname}
                        </Form.Select.Option>
                    ))}
                </Form.Select>
                <UserIcon className="formIcon" />
            </Form.Group>
        </div>
    );
};

SyncParamSelector.propTypes = {
    value: propTypes.shape({
        userId: propTypeAllowUndefined(isValidNumber)
        // date: propTypes.instanceOf(Date)
    }).isRequired,
    onChange: propTypes.func
};

SyncParamSelector.defaultProps = {
    onChange: undefined
};

export default SyncParamSelector;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { formatDate, isDefined } from '@dateam/ark';
import { withRouter } from 'shared-utils';
import config from 'config';
import { useLocale } from 'i18n/.';
import Page from 'components/Page';
import { usePlanning } from 'data/planning';
import { Planning } from './components';
import styles from './PlanningScreen.module.scss';

const PlanningScreen: React.FC = () => {
    const { t } = useTranslation();
    const ressources = useLocale('fr');
    const { data: planning } = usePlanning();

    const dateLabel = React.useMemo(() => {
        if (!isDefined(planning)) return '';

        const date = planning.requestedDate;
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDay();
        const dayNum = date.getDate();

        if (isDefined(ressources)) {
            const { locale } = ressources;

            const monthLabel = locale.monthLabel[month] ?? month;
            const dayLabel = locale.dayLabel[day] ?? null;

            return `${dayLabel} ${dayNum} ${monthLabel} ${year}`;
        }

        return formatDate(date);
    }, [ressources, planning]);

    return (
        <>
            <Helmet>
                <title>{t('dashboard.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page className="page-full">
                <div className={styles['header']}>
                    <Page.Title className={styles['headerTitle']}>
                        Planning au
                    </Page.Title>
                    <div className={styles['headerSubtitle']}>
                        {dateLabel}
                    </div>
                </div>
                <Page.Content className={`pageContent-auto ${styles['content']}`}>
                    <Planning />
                </Page.Content>
            </Page>
        </>
    );
};
export default withRouter(PlanningScreen, 'PLANNING');

import { Router, RouteData, NavigationRoute } from 'shared-utils';
import LoginScreen from './LoginScreen';

export const LoginNavigation = (): NavigationRoute => new NavigationRoute(LoginScreen.key);

const router = new Router();

router
    .setLayout(null)
    .add(new RouteData({
        key: LoginScreen.key,
        path: '',
        component: LoginScreen,
        exact: true
    }));

export default router;
import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { isFunction, isValidDate } from '@dateam/ark';
import List from 'components/ui/List';
import DateColumn from './DateColumn';
import PlotColumn from './PlotColumn';
import ProgressColumn from './ProgressColumn';
import DayCountColumn from './DayCountColumn';
import styles from '../DashboardScreen.module.scss';

type Props = {
    id: string;
    data: App.InspectionSync;
    onClick?: (inspectionId: number) => void;
};

const InspectionListItem: React.FC<Props> = ({
    id,
    data,
    onClick
}) => {
    const advancement = React.useMemo(
        () => data.plots.filter((plot: any) => (plot).validationDate != null).length,
        [data]
    );

    const handleOnClick = React.useCallback(() => {
        if (!isFunction(onClick)) return;
        onClick(data.id);
    }, [onClick, data]);

    return (
        <List.Item
            key={id}
            id={id}
            onClick={handleOnClick}
            className={concatClassName(
                isValidDate(data.validationDate) ? styles['validatedItem'] : undefined,
                isValidDate(data.validationDate) && !data.hasChanged ? styles['syncItem'] : undefined
            )}
        >
            <DateColumn date={data.startDate} />
            <List.Item.Col>
                -
            </List.Item.Col>
            <PlotColumn name={data.label} />
            <DayCountColumn count={data.overlapDays} />
            <ProgressColumn value={advancement} count={data.plots.length} />
            <List.Item.ColNav />
        </List.Item>
    );
};

InspectionListItem.propTypes = {
    id: propTypes.string.isRequired,
    data: propTypes.any.isRequired,
    onClick: propTypes.func
};

InspectionListItem.defaultProps = {
    onClick: undefined
};

export default InspectionListItem;

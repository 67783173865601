import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { computeOptions, isArray, ServiceError } from '@dateam/ark';
import { useConnectivity, usePick } from '@dateam/ark-react';
import logger from 'utils/logger';
import { queryClient } from 'utils/queryClient';
import tokenStore from 'utils/tokenStore';
import { useUserState } from 'utils/userStore';
import { db } from 'utils/localDb';
import { resolveFirstAuth } from 'utils/auth';
import { clearInstructionDisplayed } from './sync/helpers';
import {
    defaultDataOptions,
    // mutateResultKeys,
    MutationResult,
    QueryResult,
    queryResultKeys,
    REF_OBSERVATOR_KEY,
    REF_KEY,
    defaultActionOptions
} from './constants';
import { userRequests } from './requests';

export const useObservators = (options?: DataOptions): QueryResult<App.User[]> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.User[], ServiceError>({
        ...config,
        queryKey: [REF_KEY, REF_OBSERVATOR_KEY],
        queryFn: async () => {
            // Checking current data
            let queryState = queryClient.getQueryState([REF_KEY, REF_OBSERVATOR_KEY]);
            if (queryState != null) {
                const { data: cachedData, isInvalidated } = queryState;

                // if there are already some valid data, simply return it
                if (isInvalidated === false && cachedData != null) return cachedData as App.User[];
            }

            const localObservators = await getLocalObservators();

            // Checking again for current data
            // in case query updated while accessing local data
            queryState = queryClient.getQueryState([REF_KEY, REF_OBSERVATOR_KEY]);
            if (queryState != null) {
                const { data: cachedData, isInvalidated } = queryState;

                // if there are already some valid data, simply return it
                if (isInvalidated === false && cachedData != null) return cachedData as App.User[];
            }
            logger.debug('Observators: No data in cache, updating with local data...');

            // Returning local data or default value if none exists
            return localObservators ?? [];
        }
    });

    return usePick(query as any, queryResultKeys);
};

const getLocalObservators = async (): Promise<App.User[] | undefined> => {
    let observators: App.User[] | undefined;

    try {
        observators = await db.getAllValue('observator');

        if (observators != null) {
            return observators;
        }
    }
    catch {
        logger.error('getLocalObservators: failed to access local data.');
    }

    return undefined;
};

export const useLogIn = (): MutationResult<App.UserInfo, App.AuthCredentials> => {
    const [, setUser] = useUserState();

    const result = useMutation<App.UserInfo, ServiceError, App.AuthCredentials>(
        ({ username, password }: App.AuthCredentials) => {
            tokenStore.clear();

            return userRequests.login({ username, password });
        },
        {
            ...defaultActionOptions,
            onSuccess: async user => {
                logger.debug('useLogIn: saving to local storage...');

                try {
                    setUser(user);
                    tokenStore.set(user.token);
                    await db.clear('user');
                    await db.putValue('user', user);
                }
                catch (err) {
                    logger.debug('useLogIn: failed to save local data.');
                    return;
                }

                logger.debug('useLogIn: data stored to local');
                resolveFirstAuth(true);
            },
            onError: () => {
                resolveFirstAuth(false);
            }
        }
    );

    return result;
};

export const useRenewAuth = (): MutationResult<App.UserInfo, void> => {
    const isOnline = useConnectivity();
    const [, setUser] = useUserState();

    const result = useMutation<App.UserInfo, ServiceError>(
        async () => {
            if (isOnline) {
                return userRequests.renewAuth();
            }

            const userList = await db.getAllValue('user');
            logger.debug('useRenewAuth user', userList);

            if (!isArray(userList) || userList.length === 0) throw new Error('useRenewAuth: no local user data');

            const [user] = userList;

            return user;
        },
        {
            ...defaultActionOptions,
            onSuccess: async user => {
                logger.debug('useRenewAuth: saving to local storage...');

                try {
                    setUser(user);
                    tokenStore.set(user.token);
                    await db.clear('user');
                    await db.putValue('user', user);
                }
                catch (err) {
                    logger.debug('useRenewAuth: failed to save local data.');
                    return;
                }

                logger.debug('useRenewAuth: data stored to local');
                resolveFirstAuth(true);
            },
            onError: err => {
                defaultActionOptions.onError(err);
                resolveFirstAuth(false);
            }
        }
    );
    
    return result;
};

export const useLogOut = (): MutationResult<void> => {
    const [, setUser] = useUserState();

    const result = useMutation<void, ServiceError, void>(
        async () => {
            setUser(null);
            tokenStore.clear();
            await clearInstructionDisplayed();
        },
        {
            onSuccess: () => {
                queryClient.clear();
            }
        }
    );

    return result;
};

import React from 'react';
import propTypes from 'prop-types';
import { isDefined, isString, noop } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { ChevronDownIcon, ChevronUpIcon } from 'components/icons';
import ListItem from './ListItem';

export type ScrollOptions = {
    delay: number;
    vertical: 'top' | 'center' | 'bottom';
};

const scrollTo = (
    node: HTMLElement | string,
    options: ScrollOptions = {
        delay: 10,
        vertical: 'top'
    }
) => {
    setTimeout(() => {
        let nodeEl: HTMLElement | null = null;
        if (isString(node)) {
            nodeEl = document.getElementById(node);
            if (nodeEl === null) return;
        }
        else {
            nodeEl = node;
        }

        if (!isDefined(nodeEl)) return;

        const parentScroll = nodeEl.closest('.scrollable') as HTMLElement;
        if (!isDefined(parentScroll)) return;

        const scrollLeft = nodeEl.offsetLeft - parentScroll.offsetLeft - (parentScroll.offsetWidth / 2);

        let scrollTop = 0;
        if (options.vertical === 'center') {
            scrollTop = nodeEl.offsetTop + (nodeEl.offsetHeight / 2) -
                parentScroll.offsetTop - (parentScroll.offsetHeight / 2);
        }
        else if (options.vertical === 'bottom') {
            scrollTop = nodeEl.offsetTop + nodeEl.offsetHeight - parentScroll.offsetTop - parentScroll.offsetHeight;
        }
        else {
            scrollTop = nodeEl.offsetTop - parentScroll.offsetTop;
        }
        parentScroll.scrollTo({
            top: scrollTop,
            left: scrollLeft,
            behavior: 'smooth'
        });
    }, options.delay);
};

export type ApiRef = {
    scrollTo: (itemId: string, verticalScroll: 'top' | 'center' | 'bottom') => void
}

type Props = {
    className?: string;
    children?: React.ReactNode;
    apiRef?: React.MutableRefObject<ApiRef | undefined>;
};

const List: React.FC<Props> = ({
    className,
    children,
    apiRef
}: Props) => {
    const [scrollOnTop, setScrollOnTop] = React.useState(false);
    const [scrollOnBottom, setScrollOnBottom] = React.useState(false);
    const listRef = React.createRef<HTMLUListElement>();

    const updateListScroll = React.useCallback((element: HTMLUListElement) => {
        if (!isDefined(element)) return;
        const { scrollTop, scrollHeight, offsetHeight } = element;
        const maxScroll = scrollHeight - offsetHeight;
        if (scrollTop <= 0 && scrollOnTop === false) setScrollOnTop(true);
        if (scrollTop > 0 && scrollOnTop === true) setScrollOnTop(false);
        if (scrollTop >= maxScroll && scrollOnBottom === false) setScrollOnBottom(true);
        if (scrollTop < maxScroll && scrollOnBottom === true) setScrollOnBottom(false);
    }, [scrollOnTop, setScrollOnTop, scrollOnBottom, setScrollOnBottom]);

    React.useEffect(() => {
        if (!isDefined(listRef.current)) return;
        updateListScroll(listRef.current);
    }, [listRef, updateListScroll]);

    const handleListScroll = React.useCallback((ev: React.UIEvent<HTMLUListElement>) => {
        const { currentTarget } = ev;
        updateListScroll(currentTarget);
    }, [updateListScroll]);

    React.useEffect(() => {
        if (!isDefined(apiRef)) return noop;
        apiRef.current = {
            scrollTo: (itemId: string, verticalScroll: 'top' | 'center' | 'bottom' = 'top') => {
                scrollTo(itemId, {
                    delay: 10,
                    vertical: verticalScroll
                });
            }
        };

        return noop;
    }, [apiRef]);

    return (
        <div className={concatClassName('list', className)}>
            <div className="listBtnUp">
                <ChevronUpIcon
                    className={concatClassName(scrollOnTop === false ? 'listBtn-active' : null)}
                />
            </div>
            <ul
                ref={listRef}
                className="listRoot scrollable"
                onScroll={handleListScroll}
            >
                {children}
            </ul>
            <div className="listBtnDown">
                <ChevronDownIcon
                    className={concatClassName(scrollOnBottom === false ? 'listBtn-active' : null)}
                />
            </div>
        </div>
    );
};

List.propTypes = {
    className: propTypes.string,
    children: propTypes.node,
    apiRef: propTypes.any
};

List.defaultProps = {
    className: undefined,
    children: undefined,
    apiRef: undefined
};

export default Object.assign(List, {
    Item: ListItem
});

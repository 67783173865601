import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { isString } from '@dateam/ark';

type Props = {
    className?: string;
    children?: React.ReactNode;
    width?: 'auto';
};

const ListItemCol: React.FC<Props> = ({
    className,
    children,
    width
}: Props) => (
    <div
        className={concatClassName(
            'listItemCol',
            isString(width) ? `listItemCol-${width}` : null,
            className
        )}
    >
        {children}
    </div>
);

ListItemCol.propTypes = {
    className: propTypes.string,
    children: propTypes.node,
    width: propTypes.oneOf(['auto'])
};

ListItemCol.defaultProps = {
    className: undefined,
    children: undefined,
    width: undefined
};

export default ListItemCol;

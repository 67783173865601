import React from 'react';
import propTypes from 'prop-types';
import { Button, Modal } from 'shared-ui';
import { isFunction, isString } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
    title: string;
    message: string;
    ok?: string;
    cancel?: string;
    onClose?: (isOk: boolean) => void;
};

const ConfirmDialog: React.FC<Props> = ({
    className,
    title,
    message,
    ok,
    cancel,
    onClose
}: Props) => {
    const okMessage = React.useMemo(() => {
        if (isString(ok)) return ok;
        return 'Oui';
    }, [ok]);
    const cancelMessage = React.useMemo(() => {
        if (isString(cancel)) return cancel;
        return 'Annuler';
    }, [cancel]);

    const handleCancelClick = React.useCallback(() => {
        if (isFunction(onClose)) {
            onClose(false);
        }
    }, [onClose]);

    const handleOkClick = React.useCallback(() => {
        if (isFunction(onClose)) {
            onClose(true);
        }
    }, [onClose]);

    return (
        <Modal
            className={concatClassName('dialog dialog-confirm', className)}
            backdrop
            centered
        >
            <Modal.Header>
                <>
                    <svg className="dialog-icon" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 438.5 438.5" fill="currentColor">
                        <path d="M409.1,109.2c-19.6-33.6-46.2-60.2-79.8-79.8C295.7,9.8,259.1,0,219.3,0c-39.8,0-76.5,9.8-110.1,29.4
                                C75.6,49,49,75.6,29.4,109.2C9.8,142.8,0,179.5,0,219.3c0,39.8,9.8,76.5,29.4,110.1c19.6,33.6,46.2,60.2,79.8,79.8
                                c33.6,19.6,70.3,29.4,110.1,29.4s76.5-9.8,110.1-29.4c33.6-19.6,60.2-46.2,79.8-79.8c19.6-33.6,29.4-70.3,29.4-110.1
                                C438.5,179.5,428.7,142.8,409.1,109.2z M387.4,290.2c-9.7,22.6-22.7,42-39,58.2c-16.3,16.3-35.7,29.3-58.2,39
                                c-22.6,9.7-46.2,14.6-70.9,14.6s-48.4-4.9-70.9-14.6c-22.6-9.7-42-22.7-58.2-39c-16.3-16.3-29.3-35.7-39-58.2
                                c-9.7-22.6-14.6-46.2-14.6-70.9c0-24.7,4.9-48.4,14.6-70.9c9.7-22.6,22.7-42,39-58.2c16.3-16.3,35.7-29.3,58.2-39
                                c22.6-9.7,46.2-14.6,70.9-14.6c24.7,0,48.4,4.9,70.9,14.6c22.6,9.7,42,22.7,58.2,39c16.3,16.3,29.3,35.7,39,58.2
                                c9.7,22.6,14.6,46.2,14.6,70.9C402,244,397.1,267.7,387.4,290.2z"
                        />
                        <path d="M217,294c-14.6,0-26.5,12.2-26.5,26.8c0,14.3,11.5,26.8,26.5,26.8s26.8-12.5,26.8-26.8C243.8,306.2,231.6,294,217,294z" />
                        <path d="M221.5,90.8c-47.1,0-68.7,27.9-68.7,46.7c0,13.6,11.5,19.9,20.9,19.9c18.8,0,11.2-26.8,46.7-26.8c17.4,0,31.4,7.7,31.4,23.7
                                c0,18.8-19.5,29.6-31,39.4c-10.1,8.7-23.4,23-23.4,53c0,18.1,4.9,23.4,19.2,23.4c17.1,0,20.6-7.7,20.6-14.3
                                c0-18.1,0.4-28.6,19.5-43.6c9.4-7.3,39-31,39-63.8S266.1,90.8,221.5,90.8z"
                        />
                    </svg>
                    <h2 className="dialog-title">{title}</h2>
                </>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOkClick}
                >
                    {okMessage}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancelClick}
                >
                    {cancelMessage}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ConfirmDialog.propTypes = {
    className: propTypes.string,
    title: propTypes.string.isRequired,
    message: propTypes.string.isRequired,
    ok: propTypes.string,
    cancel: propTypes.string,
    onClose: propTypes.func

};

ConfirmDialog.defaultProps = {
    className: undefined,
    ok: undefined,
    cancel: undefined,
    onClose: undefined
};

export default ConfirmDialog;

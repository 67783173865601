import { useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
export default function useRouter() {
  var params = useParams();
  var currentLocation = useLocation();
  var navigate = useNavigate();

  // Memoize so that a new object is only returned if something changes
  return useMemo(function () {
    return {
      push: function push(to) {
        return navigate(to);
      },
      replace: function replace(to) {
        return navigate(to, {
          replace: true
        });
      },
      pathname: currentLocation.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: Object.assign({}, queryString.parse(currentLocation.search), params),
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      location: currentLocation
    };
  }, [params, currentLocation, navigate]);
}
import { isBoolean, isString, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Tordeuses - Confusion
 ** - presence: Oui/Non
 **     Booleen
 **     Required
 **     Mettre un warning si la valeur saisie est differente de la propriete 'confusion' de la parcelle
 ** - type : Mode de confusion
 **    String
 **    Required si presence = Oui
 ** - comment: Remarque
 */

export const MODE_VALUES = [
    'Rak 1+2 mix',
    'Checkmate Puffer',
    'BIOOtwin'
];

type CurrentObservation = App.Observation28;
type CurrentApiObservation = App.Api.Observation28;
type Validation = Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

export const validate = (
    observation: CurrentObservation,
    plot: App.Plot
): Record<never, never> | Validation => {
    const validation = {} as Validation;

    const { ignored, presence, mode, comment } = observation;

    if (ignored === true) return validation;

    // The value must be defined
    if (presence == null || !isBoolean(presence)) {
        validation.presence = {
            field: 'presence',
            type: 'error',
            code: 'required'
        };
    }
    else if (presence !== plot.confusion) {
        validation.presence = {
            field: 'presence',
            type: 'warning',
            code: 'missmatchPlot'
        };
    }

    conditionalModeField(validation, mode, presence === true);

    if (comment != null && !isString(comment)) {
        validation.comment = {
            field: 'comment',
            type: 'error',
            code: 'format'
        };
    }

    return validation;

    function conditionalModeField(
        validation: Validation,
        mode: App.ObservationField<string> | undefined,
        confusionEnabled: boolean
    ): void {
        if (confusionEnabled === true && mode == null) {
            validation.mode = {
                field: 'mode',
                type: 'error',
                code: 'required'
            };
        }
        else if (mode != null && (!isString(mode) || MODE_VALUES.indexOf(mode) < 0)) {
            validation.mode = {
                field: 'mode',
                type: 'error',
                code: 'unknown'
            };
        }
    }
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        presence,
        mode,
        comment
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.presence = parseObservationField(presence);
    parsedObs.mode = parseObservationField(mode);
    parsedObs.comment = parseObservationField(comment);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        presence,
        mode,
        comment
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.presence = presence !== undefined ? new RecordValue(presence) : null;
    mappedObs.mode = mode !== undefined ? new RecordValue(mode) : null;
    mappedObs.comment = comment !== undefined ? new RecordValue(comment) : null;

    return mappedObs;
};
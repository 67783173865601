import { isArray } from '@dateam/ark';
import { IndexedDbProvider, SchemaValidator, TableDescriptors } from 'shared-utils';

const CURRENT_VERSION = 2;

type Schema = SchemaValidator<{
    activity: {
        value: App.ActivitySync;
    };
    observator: {
        keyPath: 'id',
        value: App.User
    };
    user: {
        keyPath: 'id',
        value: App.UserInfo
    };
    planning: {
        keyPath: 'id',
        value: App.PlanningSync
    };
    instructionDisplayed: {
        keyPath: 'inspectionId',
        value: {
            inspectionId: App.InspectionSync['id'];
        }
    };
    config: {
        keyPath: 'version',
        value: {
            version: number;
            updatedOn: Date;
        }
    };
}>;

const tables: TableDescriptors<Schema> = [
    'activity',
    { name: 'observator', keyPath: 'id' },
    { name: 'user', keyPath: 'id' },
    { name: 'planning', keyPath: 'id' },
    { name: 'config', keyPath: 'version' },
    { name: 'instructionDisplayed', keyPath: 'inspectionId' }
];

export let db: IndexedDbProvider<Schema>;
export const initDatabase = async (dbName: string): Promise<boolean> => {
    db = new IndexedDbProvider<Schema>(dbName);

    let rebuildRequired = false;

    // Check config to compare db version and define if rebuild is required
    try {
        await db.createObjectStore([]);

        const data = await db.getAllValue('config');

        if (isArray(data) && data.length > 0) {
            const [config] = data;

            if (config.version < CURRENT_VERSION) rebuildRequired = true;
        }
        else rebuildRequired = true;
    }
    catch {
        rebuildRequired = true;
    }

    // console.log('== DB ==>', 'init + version check => rebuild ? ', rebuildRequired);

    try {
        if (rebuildRequired) {
            db.close();
            await db.deleteDb(); // Drop db
        }
    }
    catch {
        return false;
    }

    // console.log('== DB ==>', 'delete db ? ', rebuildRequired);

    try {
        await db.createObjectStore(tables); // Create or open Db
    }
    catch {
        return false;
    }

    if (rebuildRequired) {
        // console.log('== DB ==>', 'Rebuild');
        try {
            await db.putValue('config', { // Set new config
                version: CURRENT_VERSION,
                updatedOn: new Date()
            });
        }
        catch {
            // Ignore
        }
    }

    return db.isSupported;
};

export const resetDatabase = async (): Promise<boolean> => {
    try {
        db.close();
        await db.deleteDb(); // Drop db
    }
    catch {
        return false;
    }

    // console.log('== DB ==>', 'delete db ? ', rebuildRequired);

    try {
        await db.createObjectStore(tables); // Create or open Db
    }
    catch {
        return false;
    }

    try {
        await db.putValue('config', { // Set new config
            version: CURRENT_VERSION,
            updatedOn: new Date()
        });
    }
    catch {
        // Ignore
    }

    return db.isSupported;
};

import React from 'react';
import { useQuery } from 'react-query';
import { computeOptions, isArray, ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import logger from 'utils/logger';
import { db } from 'utils/localDb';
import { queryClient } from 'utils/queryClient';
import { defaultDataOptions, PLANNING_KEY, QueryResult, queryResultKeys } from './constants';

export const usePlanning = (options?: DataOptions): QueryResult<App.PlanningSync | undefined> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.PlanningSync | undefined, ServiceError>({
        ...config,
        queryKey: [PLANNING_KEY],
        queryFn: async () => {
            // Checking current data
            let queryState = queryClient.getQueryState([PLANNING_KEY]);
            if (queryState != null) {
                const { data: cachedData, isInvalidated } = queryState;

                // if there are already some valid data, simply return it
                if (isInvalidated === false && cachedData != null) return cachedData as App.PlanningSync;
            }

            const localPlanning = await getLocalPlanning();

            // Checking again for current data
            // in case query updated while accessing local data
            queryState = queryClient.getQueryState([PLANNING_KEY]);
            if (queryState != null) {
                const { data: cachedData, isInvalidated } = queryState;

                // if there are already some valid data, simply return it
                if (isInvalidated === false && cachedData != null) return cachedData as App.PlanningSync;
            }
            logger.debug('Planning: No data in cache, updating with local data...');

            // Returning local data or default value if none exists
            return localPlanning ?? {
                id: 'currentPlanning',
                requestedDate: new Date(),
                activity: []
            };
        }
    });

    return usePick(query as any, queryResultKeys);
};

const getLocalPlanning = async (): Promise<App.PlanningSync | undefined> => {
    try {
        const data = await db.getAllValue('planning');

        if (isArray(data) && data.length > 0) {
            const [planning] = data;

            return planning;
        }
    }
    catch {
        logger.error('getLocalPlanning: failed to access local data.');
    }

    return undefined;
};

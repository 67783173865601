import React from 'react';
import propTypes from 'prop-types';
import { isArray, isDefined, orderBy } from '@dateam/ark';
import List from 'components/ui/List';
import PlotItem from './PlotItem';
import styles from '../PlanningScreen.module.scss';
import { planningPlotPropTypes } from './types';

type Props = {
    data?: App.PlanningPlot[];
};

const PlotList: React.FC<Props> = ({ data }) => {
    const plots = React.useMemo(() => {
        if (isDefined(data) && isArray(data)) {
            const plots = [...data];

            orderBy(plots, 'position');

            return plots;
        }

        return [];
    }, [data]);

    return (
        <List className={`list-scroll ${styles['plotList']}`}>
            {plots.map(plot => (
                <PlotItem
                    key={plot.id}
                    position={plot.position}
                    label={plot.label}
                    customer={plot.customer.label}
                />
            ))}
        </List>
    );
};

PlotList.propTypes = {
    data: propTypes.arrayOf(planningPlotPropTypes.isRequired)
};

PlotList.defaultProps = {
    data: undefined
};

export default PlotList;

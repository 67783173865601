import React from 'react';
import propTypes from 'prop-types';
import { isArray, isFunction, isValidDate } from '@dateam/ark';
import List from 'components/ui/List';
import { ApiRef } from 'components/ui/List/List';
import InspectionPlotItem from './InspectionPlotItem';
import styles from './InspectionDetails.module.scss';

const N_TESTER_TAG = 'N-Tester';
const BSV_TAG = 'BSV';

type Props = {
    data: App.Plot[]
    selected?: App.Plot['id'] | null;
    onClick?: (plot: App.Plot) => void;
};

const InspectionPlotList: React.FC<Props> = ({
    data,
    selected,
    onClick
}) => {
    const listApiRef = React.useRef<ApiRef>();
    const selectPlot = React.useCallback((plot: App.Plot) => () => {
        if (selected === plot.id) return;

        if (isFunction(onClick)) onClick(plot);
    }, [onClick, selected]);

    const plotChildren = React.useMemo(() => {
        return data.map(plot => {
            let tag: string | null = null;

            if (isArray(plot.observationTypes)) {
                const isNTester = plot.observationTypes.some(typeItem => typeItem.label === N_TESTER_TAG);
                const isBsv = plot.observationTypes.some(typeItem => typeItem.label === BSV_TAG);

                if (isNTester) tag = 'N';
                else if (isBsv) tag = 'B';
            }

            const plotSync = plot as App.PlotSync;

            let state: 'none' | 'error' | 'ok' | 'unObservable' | undefined = 'none';
            if (plotSync.isValid === false && plotSync.hasChanged === true) {
                state = 'error';
            }
            else if (plotSync.ignored === true) state = 'unObservable';
            else if (isValidDate(plotSync.validationDate)) state = 'ok';

            return (
                <InspectionPlotItem
                    id={`plot-${plot.id}`}
                    key={plot.id}
                    position={plot.position}
                    label={plot.label}
                    customer={plot.customer.label}
                    active={plot.id === selected}
                    tag={tag}
                    state={state}
                    onClick={selectPlot(plot)}
                />
            );
        });
    }, [data, selected, selectPlot]);

    React.useEffect(() => {
        listApiRef.current?.scrollTo(`plot-${selected}`, 'center');
    }, [listApiRef, selected]);

    return (
        <List
            className={`list-scroll ${styles['plotList']}`}
            apiRef={listApiRef}
        >
            {plotChildren}
        </List>
    );
};

InspectionPlotList.propTypes = {
    data: propTypes.arrayOf(propTypes.any).isRequired,
    selected: propTypes.number,
    onClick: propTypes.func
};

InspectionPlotList.defaultProps = {
    selected: undefined,
    onClick: undefined
};

export default InspectionPlotList;

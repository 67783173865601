import { assertIsArray, hasProperty, quickClone } from '@dateam/ark';
import logger from 'utils/logger';
import { queryClient } from 'utils/queryClient';
import { db } from 'utils/localDb';
import { refreshSyncProps } from 'data/observation';
import { ACTIVITY_KEY } from '../constants';

type SyncCount<T> = T & {
    readyForSync: number;
};

type Changes = {
    countChanged: number;
    // lastUpdate: Date | null;
    isValid: boolean;
};

export const computeActivity = (activity: App.Activity | App.ActivitySync): App.ActivitySync => {
    const computedData = quickClone(activity) as App.ActivitySync;

    assertIsArray(computedData.inspections, 'activity doesn\'t match expected format (`inspections`).');

    const changes = computedData.inspections.reduce((inspectionAcc: SyncCount<Changes>, inspection) => {
        assertIsArray(inspection.plots, 'activity doesn\'t match expected format (`inspections[].plots`).');
        if (!hasProperty(inspection, 'hasChanged')) inspection.hasChanged = false;

        const changes = inspection.plots.reduce((plotAcc: SyncCount<Changes>, plot) => {
            assertIsArray(plot.observations, 'activity doesn\'t match expected format (`inspections[].plots[].observations`).');
            if (!hasProperty(plot, 'hasChanged')) plot.hasChanged = false;

            let changes: Changes = { countChanged: 0, isValid: true };

            if (plot.ignored !== true) {
                changes = plot.observations.reduce((obsAcc: Changes, observation) => {
                    refreshSyncProps(observation, plot);

                    obsAcc.countChanged += +(observation.hasChanged);
                    obsAcc.isValid = obsAcc.isValid && observation.isValid;

                    return obsAcc;
                }, changes);
            }
            else {
                changes.isValid = true;
            }

            plot.isValid = changes.isValid;
            plot.hasChanged = plot.hasChanged || changes.countChanged > 0;
            plot.readyForSync = plot.isValid && plot.hasChanged;

            plotAcc.countChanged += +(plot.hasChanged);
            plotAcc.isValid = plotAcc.isValid && changes.isValid;
            plotAcc.readyForSync += +(plot.readyForSync);

            return plotAcc;
        }, { countChanged: 0, isValid: true, readyForSync: 0 });

        // To handle changes of validation date, increases both counters
        changes.countChanged += +(inspection.hasChanged);
        changes.readyForSync += +(inspection.hasChanged);
        inspection.isValid = changes.isValid;
        inspection.hasChanged = changes.countChanged > 0;

        const { readyForSync, countChanged } = changes;

        inspection.syncState = countChanged === 0 ? 'none' : (readyForSync === countChanged ? 'full' : 'partial');
        inspectionAcc.readyForSync += inspection.syncState === 'full' ? 1 : (inspection.syncState === 'partial' ? 0.5 : 0);
        inspectionAcc.countChanged += +(inspection.hasChanged);

        return inspectionAcc;
    }, { countChanged: 0, isValid: true, readyForSync: 0 });

    const { readyForSync, countChanged } = changes;
    computedData.syncState = countChanged === 0 ? 'none' : (readyForSync === countChanged ? 'full' : 'partial');

    return computedData;
};

export const saveActivity = async (userId: App.UserInfo['id'], activity: App.ActivitySync, saveToLocal = true): Promise<App.ActivitySync> => {
    const computedActivity = computeActivity(activity);

    queryClient.setQueryData(ACTIVITY_KEY, _ => computedActivity);
    if (saveToLocal === true) {
        await db.putValue('activity', computedActivity, userId);
    }

    return computedActivity;
};

export const getLocalActivity = async (userId: App.ActivitySync['userId']): Promise<App.ActivitySync | undefined> => {
    try {
        const data = await db.getValue('activity', userId);

        if (data != null) return data;
    }
    catch (err) {
        logger.error('getLocalActivity: failed to access local data.', err);
    }

    return undefined;
};

export const getLocalSyncParam = async (userId: App.UserInfo['id']): Promise<App.SyncParam | undefined> => {
    let activitySync: App.ActivitySync | undefined;

    try {
        activitySync = await getLocalActivity(userId);

        if (activitySync != null) {
            return { userId: activitySync.userId };
        }
    }
    catch (err) {
        logger.error('getLocalSyncParam: failed to access local data.', err);
    }

    return undefined;
};

export const saveObservators = async (observators: App.User[]): Promise<void> => {
    logger.debug('Observators: saving to local storage...');
    try {
        await db.clear('observator');
        await db.putBulkValue('observator', observators);
    }
    catch (err) {
        logger.debug('Observators: failed to save local data.');
        throw err;
    }

    logger.debug('Observators: data stored to local');
};

export const savePlanning = async (planning: App.PlanningSync): Promise<void> => {
    logger.debug('Planning: saving to local storage...');
    try {
        await db.clear('planning');
        await db.putValue('planning', planning);
    }
    catch (err) {
        logger.debug('Planning: failed to save local data.');
        throw err;
    }

    logger.debug('Planning: data stored to local');
};

export const getInstructionDisplayed = async (inspectionId: App.InspectionSync['id']): Promise<boolean> => {
    logger.debug('InstructionDisplayed: retrieve from local storage...');
    try {
        const entry = await db.getValue('instructionDisplayed', inspectionId);
        logger.debug('InstructionDisplayed: data loaded from local');

        return entry?.inspectionId === inspectionId;
    }
    catch (err) {
        logger.debug('InstructionDisplayed: failed to retrieve local data.');
        throw err;
    }
};

export const saveInstructionDisplayed = async (inspectionId: App.InspectionSync['id']): Promise<void> => {
    logger.debug('InstructionDisplayed: saving to local storage...');
    try {
        await db.putValue('instructionDisplayed', { inspectionId });
    }
    catch (err) {
        logger.debug('InstructionDisplayed: failed to save local data.');
        throw err;
    }

    logger.debug('InstructionDisplayed: data stored to local');
};

export const clearInstructionDisplayed = async (): Promise<void> => {
    logger.debug('InstructionDisplayed: clear local storage...');
    try {
        await db.clear('instructionDisplayed');
    }
    catch (err) {
        logger.debug('InstructionDisplayed: failed to clear local data.');
        throw err;
    }

    logger.debug('InstructionDisplayed: local data cleared');
};
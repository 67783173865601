import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isDefined, orderBy } from '@dateam/ark';
import { usePlanning } from 'data/planning';
import { useObservators } from 'data/user';
import { PlanningItem } from './types';
import PlanningList from './PlanningList';
import PlotList from './PlotList';
import styles from '../PlanningScreen.module.scss';

type Props = {
    className?: string;
};

const Planning: React.FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const { data: planning } = usePlanning();
    const { data: observators } = useObservators();
    const [selectedPlanningId, setSelectedPlanningId] = React.useState<PlanningItem['id'] | null>(null);
    const [selected, setSelected] = React.useState<PlanningItem | null>(null);

    const rows: PlanningItem[] = React.useMemo(() => {
        if (!isDefined(planning)) return [];

        const computedList = planning.activity.reduce((planningItemAcc: PlanningItem[], planningItem) => {
            const observator = observators?.find(observator => observator.id === planningItem.assigneeUser.id);

            if (isDefined(observator)) {
                planningItemAcc.push({
                    id: planningItem.id,
                    label: planningItem.label,
                    assigneeId: observator.id,
                    assigneeFirstname: observator.firstName,
                    assigneeLastname: observator.lastName,
                    plots: planningItem.plots,
                    plotCount: planningItem.plots.length,
                    overlapDays: planningItem.overlapDays
                });
            }

            return planningItemAcc;
        }, []);

        orderBy(computedList, 'label');
        orderBy(computedList, 'assigneeFirstname');
        orderBy(computedList, 'assigneeLastname');

        return computedList;
    }, [planning, observators]);

    React.useEffect(() => {
        setSelectedPlanningId(selectedPlanningId => {
            if (rows.length === 0) return null;
            if (!isDefined(selectedPlanningId)) return rows[0].id;

            return selectedPlanningId;
        });
    }, [rows, setSelectedPlanningId]);

    React.useEffect(() => {
        if (selectedPlanningId != null) {
            const itemFound = rows.find(planningItem => planningItem.id === selectedPlanningId);

            if (itemFound != null) return setSelected(itemFound);
        }

        return setSelected(null);
    }, [rows, selectedPlanningId, setSelected]);

    const handleOnSelect = React.useCallback((planningItem: PlanningItem) => {
        setSelectedPlanningId(planningItem.id);
    }, [setSelectedPlanningId]);

    if (rows.length < 1) return (<div className={styles['noData']}>{t('planning.noActivity')}</div>);

    return (
        <div className="row">
            <PlanningList data={rows} selected={selected} onSelect={handleOnSelect} />
            <PlotList data={selected?.plots} />
        </div>
    );
};

Planning.propTypes = {
    className: propTypes.string
};

Planning.defaultProps = {
    className: undefined
};

export default Planning;

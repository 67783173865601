import React from 'react';
import propTypes from 'prop-types';
import {
    useNavigation,
    useNavigationHistory
} from 'shared-utils';
import { Menu } from 'shared-ui';
import logger from 'utils/logger';
import { useUserState } from 'utils/userStore';
import Sidebar, {
    SidebarFooter,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuItem,
    SidebarMenuLink,
    SidebarSeparator
} from 'components/Sidebar';
import SidebarClock from 'components/SidebarClock'; import {
    CalendarIcon,
    PinIcon,
    UserIcon
} from 'components/icons';
import { useLogOut } from 'data/user';
import {
    LogOutNavigation,
    DashboardNavigation,
    PlanningNavigation
} from 'screens/routes';
import LogoImg from '../../../public/logo.svg'; // TODO: Should not be imported from public
import SyncButton from './components/SyncButton';
import styles from './MainLayout.module.scss';

const MainLayout = ({ children }: React.PropsWithChildren) => {
    const { push: navigate } = useNavigation();
    const { current } = useNavigationHistory();
    const [user] = useUserState();
    const { mutateAsync: logOut } = useLogOut();
    const userMenuRef = React.useRef<HTMLLIElement>(null);
    const [showMenuUser, setShowMenuUser] = React.useState(false);

    const handleMenuLogoutClick = React.useCallback(async () => {
        logger.debug('logOut');
        try {
            await logOut();
            navigate(LogOutNavigation());
        }
        catch {
            // Ignore
        }
    }, [navigate, logOut]);

    const handleUserMenuClick = React.useCallback(() => {
        if (showMenuUser === true) return;
        setShowMenuUser(true);
    }, [showMenuUser, setShowMenuUser]);

    const handleUserMenuClose = React.useCallback(() => {
        setShowMenuUser(false);
    }, [setShowMenuUser]);

    // TODO: Replace with component
    const userInitials = React.useMemo(() => {
        if (user == null) return '';

        const { firstName, lastName } = user;
        return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    }, [user]);

    return (
        <>
            <Sidebar className={styles['sidebar']}>
                <SidebarHeader className={styles['header']}>
                    <LogoImg />
                    <span className={styles['headerTitle']}>Démo</span>
                    <span className={styles['headerTitle']}>Viticoncept</span>
                </SidebarHeader>
                <SidebarMenu>
                    <SidebarMenuLink
                        key="inspections"
                        to={DashboardNavigation()}
                        icon={(<PinIcon className={styles['sidebarIcon']} />)}
                        active={current?.key === DashboardNavigation().key}
                    >
                        Mes visites
                    </SidebarMenuLink>
                    <SidebarMenuLink
                        key="planning"
                        to={PlanningNavigation()}
                        icon={(<CalendarIcon className={styles['sidebarIcon']} />)}
                        active={current?.key === PlanningNavigation().key}
                    >
                        Planning
                    </SidebarMenuLink>
                </SidebarMenu>
                <SidebarFooter>
                    <SidebarMenu>
                        <SyncButton />
                        <SidebarSeparator />
                        <SidebarMenuItem
                            ref={userMenuRef}
                            icon={(<UserIcon className={styles['profileIcon']} />)}
                            onClick={handleUserMenuClick}
                        >
                            {userInitials}
                        </SidebarMenuItem>
                    </SidebarMenu>
                    <SidebarClock />
                </SidebarFooter>
            </Sidebar>
            <main role="main" className={styles['page']}>
                {children}
            </main>
            {showMenuUser && (
                <Menu
                    anchorEl={userMenuRef}
                    position="right-bottom"
                    onClose={handleUserMenuClose}
                >
                    <Menu.Item onClick={handleMenuLogoutClick}>Se déconnecter</Menu.Item>
                </Menu>
            )}
        </>
    );
};

MainLayout.propTypes = {
    children: propTypes.node
};

MainLayout.defaultProps = {
    children: undefined
};

export default MainLayout;

import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { sortByProperty, computeOptions, hasProperty, replaceCollectionItem, ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import logger from 'utils/logger';
import { useUserState } from 'utils/userStore';
import {
    MutationResult,
    OBS_KEY,
    defaultDataOptions,
    QueryResult,
    queryResultKeys,
    defaultActionOptions
} from '../constants';
import { getLocalActivity, saveActivity } from '../sync';
import validate from './validator';

export { default as validate } from './validator';
export * from './validator';
export * from './mapper';

type SaveParameters = {
    inspectionId: App.InspectionSync['id'];
    plotId: App.Plot['id'];
    data: App.Observation;
};

type SaveObsCommentParameters = {
    inspectionId: App.InspectionSync['id'];
    plotId: App.Plot['id'];
    observationComment: App.PlotSync['observationComment'];
};

type AddParameters = {
    inspectionId: App.InspectionSync['id'];
    plotId: App.Plot['id'];
    observationId: App.Observation['id'];
};

// All orders have a 100 digit offset to be able to insert new observations between existing ones
// Please always insert new observations with a {ID}00 digit offset
// AND for the ones inserted in between, always half the offset (ex: 50, 25, ....) to keep room for more inserts
const OBSERVATIONS = [
    { id: 1, label: 'Stade phénologique', order: 100 },
    { id: 2, label: 'N-Tester', order: 200 },
    { id: 3, label: 'Entretien du sol', order: 300 },
    { id: 4, label: '% surface enherbée', order: 400 },
    { id: 5, label: 'Adventices dominantes', order: 500 },
    { id: 6, label: 'Adventices secondaires', order: 600 },
    { id: 7, label: 'Ceps manquants', order: 700 },
    { id: 8, label: 'Entreplants', order: 800 },
    { id: 9, label: 'Accident climatique - gel de printemps', order: 900 },
    { id: 10, label: 'Accident climatique - grêle', order: 1000 },
    { id: 11, label: 'Accident climatique - échaudage', order: 1100 },
    { id: 12, label: 'Chlorose', order: 1200 },
    { id: 13, label: 'Carence Mn', order: 1300 },
    { id: 14, label: 'Carence Mg', order: 1400 },
    { id: 15, label: 'Carence K', order: 1500 },
    { id: 16, label: 'Court Noué', order: 1600 },
    { id: 17, label: 'Enroulement', order: 1700 },
    { id: 18, label: 'Esca / BDA', order: 1800 },
    { id: 44, label: 'Eutypiose', order: 1835 },
    { id: 45, label: 'Excoriose', order: 1865 },
    { id: 19, label: 'Mange-Bourgeons', order: 1900 },
    { id: 20, label: 'Erinose Feuilles', order: 2000 },
    { id: 21, label: 'Erinose Grappes', order: 2100 },
    { id: 22, label: 'Acariose', order: 2200 },
    { id: 23, label: 'Cochenille', order: 2300 },
    { id: 24, label: 'Typhlodromes', order: 2400 },
    { id: 25, label: 'Araignées rouges', order: 2500 },
    { id: 26, label: 'Pyrales feuilles', order: 2600 },
    { id: 27, label: 'Pyrales grappes', order: 2700 },
    { id: 28, label: 'Tordeuses - Confusion', order: 2800 },
    { id: 29, label: 'Tordeuses - Œufs G1', order: 2900 },
    { id: 30, label: 'Tordeuses - Glomérules G1', order: 3000 },
    { id: 31, label: 'Tordeuses - Œufs G2', order: 3100 },
    { id: 32, label: 'Tordeuses - Perforations G2', order: 3200 },
    { id: 33, label: 'Cicadelle Verte', order: 3300 },
    { id: 34, label: 'Cicadelle Flavescence Dorée', order: 3400 },
    { id: 35, label: 'Gribouri / Ecrivain', order: 3500 },
    { id: 36, label: 'Mildiou feuilles', order: 3600 },
    { id: 37, label: 'Mildiou grappes', order: 3700 },
    { id: 46, label: 'Black rot', order: 3750 },
    { id: 38, label: 'Oïdium feuilles', order: 3800 },
    { id: 39, label: 'Oïdium grappes', order: 3900 },
    { id: 40, label: 'Botrytis feuilles', order: 4000 },
    { id: 41, label: 'Botrytis grappes', order: 4100 },
    { id: 42, label: 'Brenner feuilles', order: 4200 },
    { id: 43, label: 'Comptages grappes', order: 4300 },
    { id: 47, label: 'Apex', order: 4700 }
];

export const sortObservations = <T extends { id: number; }[]>(observations: T): T => {
    const sortedArray = [...observations] as T;

    sortByProperty(sortedArray, 'id', (obsA, obsB) => {
        if (obsA === obsB) return 0;
        if (obsA == null) return -1;
        if (obsB == null) return 1;

        const orderA = OBSERVATIONS.find(obs => obs.id === obsA)?.order ?? Infinity;
        const orderB = OBSERVATIONS.find(obs => obs.id === obsB)?.order ?? Infinity;

        return orderA < orderB ? -1 : 1;
    });

    return sortedArray;
};

export const useObservation = (options?: DataOptions): QueryResult<RefLabel[]> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<RefLabel[], ServiceError>({
        ...config,
        queryKey: OBS_KEY,
        queryFn: () => OBSERVATIONS
    });

    return usePick(query as any, queryResultKeys);
};

export const useSaveObservation = (): MutationResult<void, SaveParameters> => {
    const [user] = useUserState();

    const result = useMutation<void, ServiceError, SaveParameters>(
        async ({ inspectionId, plotId, data }: SaveParameters) => {
            logger.debug('Observation save: saving...');

            if (user == null) throw new Error('Observation save: no user available, aborted.');

            try {
                logger.debug('Observation save: retrieving local data...');
                const activity = await getLocalActivity(user.id);
                logger.debug(`Observation save: local data retrieved (${activity?.inspections.length ?? 0} inspections)`);

                if (activity == null) throw new Error('Observation save: no activity available, aborted.');

                logger.debug('Observation save: updating activity observation...');
                activity.inspections.forEach(inspection => {
                    if (inspection.id !== inspectionId) return;

                    inspection.plots.forEach(plot => {
                        if (plot.id !== plotId) return;

                        data.hasChanged = true;
                        data.updatedOn = new Date();

                        refreshSyncProps(data, plot);

                        replaceCollectionItem(plot.observations, data, observation => observation.id === data.id);
                    });
                });

                logger.debug('Observation save: saving local data...');
                await saveActivity(user.id, activity);
                logger.debug('Observation save: data saved');
            }
            catch (err) {
                logger.error(err);
                throw new Error('Observation save: failed to save data.');
            }
        },
        {
            ...defaultActionOptions
        }
    );

    return result;
};

export const useSaveObservationComment = (): MutationResult<void, SaveObsCommentParameters> => {
    const [user] = useUserState();

    const result = useMutation<void, ServiceError, SaveObsCommentParameters>(
        async ({ inspectionId, plotId, observationComment }: SaveObsCommentParameters) => {
            logger.debug('Observation comment save: saving...');

            if (user == null) throw new Error('Observation comment save: no user available, aborted.');

            try {
                logger.debug('Observation comment save: retrieving local data...');
                const activity = await getLocalActivity(user.id);
                logger.debug(`Observation comment save: local data retrieved (${activity?.inspections.length ?? 0} inspections)`);

                if (activity == null) throw new Error('Observation comment save: no activity available, aborted.');

                logger.debug('Observation comment save: updating activity observation...');
                const inspectionFound = activity.inspections.find(inspection => inspection.id === inspectionId);
                if (inspectionFound == null) {
                    logger.warn(`Observation comment save: unable to find inspection (${inspectionId})`);
                    return;
                }

                const plotFound = inspectionFound.plots.find(plot => plot.id === plotId);
                if (plotFound == null) {
                    logger.warn(`Observation comment save: unable to find plot (${plotId})`);
                    return;
                }

                plotFound.hasChanged = true;
                plotFound.observationComment = observationComment;

                logger.debug('Observation comment save: saving local data...');
                await saveActivity(user.id, activity);
                logger.debug('Observation comment save: data saved');
            }
            catch (err) {
                logger.error(err);
                throw new Error('Observation comment save: failed to save data.');
            }
        },
        {
            ...defaultActionOptions
        }
    );

    return result;
};

export const useAddObservation = (): MutationResult<void, AddParameters> => {
    const [user] = useUserState();

    const result = useMutation<void, ServiceError, AddParameters>(
        async ({ inspectionId, plotId, observationId }: AddParameters) => {
            logger.debug('Observation add: adding...');

            if (user == null) throw new Error('Observation add: no user available, aborted.');

            try {
                logger.debug('Observation add: retrieving local data...');
                const activity = await getLocalActivity(user.id);
                logger.debug(`Observation add: local data retrieved (${activity?.inspections.length ?? 0} inspections)`);

                if (activity == null) throw new Error('Observation add: no activity available, aborted.');

                logger.debug('Observation add: updating activity observation...');
                activity.inspections.forEach(inspection => {
                    if (inspection.id !== inspectionId) return;

                    inspection.plots.forEach(plot => {
                        if (plot.id !== plotId) return;

                        const obsExists = plot.observations.find(obs => obs.id === observationId) != null;
                        if (obsExists) return;

                        const newObs = {
                            id: observationId,
                            hasChanged: true,
                            manuallyAdded: true,
                            updatedOn: new Date()
                        } as App.Observation;

                        refreshSyncProps(newObs, plot);

                        plot.observations.push(newObs);
                    });
                });

                logger.debug('Observation add: saving local data...');
                await saveActivity(user.id, activity);
                logger.debug('Observation add: data added');
            }
            catch (err) {
                logger.error(err);
                throw new Error('Observation add: failed to add data.');
            }
        },
        {
            ...defaultActionOptions
        }
    );

    return result;
};

export const refreshSyncProps = (observation: App.Observation, plot: App.Plot): void => {
    if (!hasProperty(observation, 'isSyncing')) observation.isSyncing = false;
    if (!hasProperty(observation, 'hasChanged')) observation.hasChanged = false;

    const errors = validate(observation, plot);

    observation.isValid = Object.values(errors).filter(error => error.type === 'error').length === 0;
};

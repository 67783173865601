import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'shared-ui';
import { isDefined, isValidDate, ObjectAccessor, formatDate } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { validate as validator, ValidationError } from 'data/observation';
import { ObservationProps, plotObservationPropTypes } from './types';
import { ObservationErrors } from './components';
import ObservationField from './ObservationField';
import ObservationItem from './ObservationItem';
import styles from './Observation.module.scss';

const observationKey = 'observation4';

type Props = ObservationProps<App.Observation4>;

const Observation4: React.FC<Props> = ({
    data,
    plot,
    ignorable,
    className,
    onChange
}: Props) => {
    const [validationErrors, setValidationErrors] = React.useState<Record<string, ValidationError>>({});
    const internalData = React.useRef(data);

    React.useEffect(() => {
        internalData.current = data;
    }, [data]);

    React.useEffect(() => {
        if (data.row1 === undefined &&
            data.row2 === undefined &&
            data.processedRow === undefined &&
            data.comment === undefined) {
            return;
        }

        setValidationErrors(validator(data, plot));
    }, [plot, data]);

    const row1 = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.row1 ?? undefined;
    }, [data]);

    const row2 = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.row2 ?? undefined;
    }, [data]);

    const processedRow = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.processedRow ?? undefined;
    }, [data]);

    const comment = React.useMemo(() => {
        if (data.ignored) return undefined;

        return data.comment ?? undefined;
    }, [data]);

    const previousValue = React.useMemo(() => {
        if (isDefined(internalData.current.previous)) {
            return (
                <>
                    {isValidDate(internalData.current.previous.updatedOn) && (
                        <div className={styles['previousDate']}>{formatDate(internalData.current.previous.updatedOn)}</div>
                    )}
                    <div className={styles['previous']}>Général ou 1er rang: {internalData.current.previous.row1?.value ?? '-'}</div>
                    <div className={styles['previous']}>2ème rang: {internalData.current.previous.row2?.value ?? '-'}</div>
                    <div className={styles['previous']}>Rang de traitement: {internalData.current.previous.processedRow?.value ?? '-'}</div>
                </>
            );
        }

        return (<div>Aucune valeur disponible</div>);
    }, [internalData.current]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleIgnoreObservation = React.useCallback(() => {
        const newData: App.Observation4 = {
            ...internalData.current,
            row1: null,
            row2: null,
            processedRow: null,
            comment: null,
            ignored: !internalData.current.ignored
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleRow1Change = React.useCallback((newValue: number | undefined) => {
        const newData: App.Observation4 = {
            ...internalData.current,
            row1: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleRow2Change = React.useCallback((newValue: number | undefined) => {
        const newData: App.Observation4 = {
            ...internalData.current,
            row2: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleProcessedRowChange = React.useCallback((newValue: number | undefined) => {
        const newData: App.Observation4 = {
            ...internalData.current,
            processedRow: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleCommentChange = React.useCallback((newValue: string | undefined) => {
        const newData: App.Observation4 = {
            ...internalData.current,
            comment: newValue ?? null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    const handleFocus = React.useCallback((field: ObjectAccessor<Omit<App.Observation4, 'previous'>>) => {
        if (internalData.current[field] !== undefined) return;

        const newData: App.Observation4 = {
            ...internalData.current,
            [field]: null
        };

        onChange?.(newData);
    }, [onChange, internalData]);

    return (
        <ObservationItem
            data={data}
            ignorable={ignorable}
            onIgnore={handleIgnoreObservation}
            previous={previousValue}
            label="% surface enherbée"
        >
            <div className="row">
                <ObservationField
                    id={`${observationKey}-row1`}
                    label="Général ou 1er rang"
                    offLabel="%"
                    className={concatClassName(styles[`${observationKey}-row1`], 'col-4')}
                >
                    <Form.Number
                        min={0}
                        max={100}
                        value={row1}
                        onChange={handleRow1Change}
                        state={validationErrors['row1']?.type}
                        onFocus={() => handleFocus('row1')}
                        required={!data.ignored}
                        readOnly={data.ignored}
                    />
                </ObservationField>
                <ObservationField
                    id={`${observationKey}-row2`}
                    label="2ème rang"
                    offLabel="%"
                    className={concatClassName(styles[`${observationKey}-row2`], 'col-4')}
                >
                    <Form.Number
                        min={0}
                        max={100}
                        value={row2}
                        onChange={handleRow2Change}
                        state={validationErrors['row2']?.type}
                        readOnly={data.ignored}
                    />
                </ObservationField>
                <ObservationField
                    id={`${observationKey}-processedRow`}
                    label="Rang de traitement"
                    offLabel="%"
                    className={concatClassName(styles[`${observationKey}-processedRow`], 'col-4')}
                >
                    <Form.Number
                        min={0}
                        max={100}
                        value={processedRow}
                        onChange={handleProcessedRowChange}
                        state={validationErrors['processedRow']?.type}
                        readOnly={data.ignored}
                    />
                </ObservationField>
            </div>
            <div className="row">
                <ObservationField
                    id={`${observationKey}-comment`}
                    label="Commentaire"
                    className={concatClassName(styles[`${observationKey}-comment`], 'col-12')}
                >
                    <Form.Input
                        multiline
                        rows={2}
                        value={comment}
                        onChange={handleCommentChange}
                        state={validationErrors['comment']?.type}
                        readOnly={data.ignored}
                    />
                </ObservationField>
            </div>
            <ObservationErrors value={validationErrors} observationKey={observationKey} />
        </ObservationItem>
    );
};

Observation4.propTypes = {
    data: propTypes.any.isRequired,
    plot: plotObservationPropTypes.isRequired,
    className: propTypes.string,
    onChange: propTypes.func
};

Observation4.defaultProps = {
    className: undefined,
    onChange: undefined
};

export default Observation4;

import { isString, isValidNumber, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Tordeuses - Œufs G2
 ** - count: Comptage // /50 grappes
 **     0 <= Value <= 50
 **     Required
 ** - species: Espèce
 **     Liste: ['Cochylis', 'Eudémis', 'Cochylis et Eudémis']
 */

type CurrentObservation = App.Observation31;
type CurrentApiObservation = App.Api.Observation31;

export const SPECIES_VALUES = [
    'Cochylis',
    'Eudémis',
    'Cochylis et Eudémis'
];

export const validate = (
    observation: CurrentObservation,
    _: App.Plot
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, count, species } = observation;

    if (ignored === true) return validation;

    // The value must be defined
    if (count == null || !isValidNumber(count)) {
        validation.count = {
            field: 'count',
            type: 'error',
            code: 'required'
        };
    }
    else if (count < 0 || count > 50) {
        validation.count = {
            field: 'count',
            type: 'error',
            code: 'format'
        };
    }

    if (species != null) {
        if (!isString(species)) {
            validation.species = {
                field: 'species',
                type: 'error',
                code: 'format'
            };
        }
        else if (SPECIES_VALUES.indexOf(species) < 0) {
            validation.species = {
                field: 'species',
                type: 'error',
                code: 'unknown'
            };
        }
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        count,
        species
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.count = parseObservationField(count);
    parsedObs.species = parseObservationField(species);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        count,
        species
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.count = count !== undefined ? new RecordValue(count) : null;
    mappedObs.species = species !== undefined ? new RecordValue(species) : null;

    return mappedObs;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import List from 'components/ui/List';
import styles from '../PlanningScreen.module.scss';

type Props = {
    name: string;
    plotCount: number;
    overlapDays: number;
}

const PlotColumn: React.FC<Props> = ({ name, plotCount, overlapDays }) => {
    const { t } = useTranslation();

    return (
        <List.Item.Col className={styles['colInspection']}>
            <div className={styles['inspectionName']}>
                {name}
            </div>
            <div className={styles['plotCount']}>
                {t('planning.plotCount', { count: plotCount })}
                {overlapDays > 1 && <span>{` - ${t('planning.overlapDays', { count: overlapDays })}`}</span>}
            </div>
        </List.Item.Col>
    );
};

PlotColumn.propTypes = {
    name: propTypes.string.isRequired,
    plotCount: propTypes.number.isRequired,
    overlapDays: propTypes.number.isRequired
};

PlotColumn.defaultProps = {
};

export default PlotColumn;

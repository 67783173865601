import React from 'react';
import { hasProperty, mapToDeepObject } from '@dateam/ark';
import { default as localeEn } from './resources/en/locale.json';
import { default as localeFr } from './resources/fr/locale.json';
import { default as translationEn } from './resources/en/translation.json';
import { default as translationFr } from './resources/fr/translation.json';

type TranslationEntry = string | { [key: string]: TranslationEntry; };

export type TranslateResource = {
    key: string;
    translation: Record<string, TranslationEntry>;
    locale: Locale;
};
export type Locale = {
    fullDateFormat: string;
    dateFormat: string;
    monthLabel: string[];
    weekStartOnMondays: boolean;
    dayLabel: string[];
    dayLabelShort: string[];
};

export class I18nLoader {
    resources: Record<string, TranslateResource> = {};

    constructor() {
        this.resources['en'] = {
            key: 'en',
            translation: {},
            locale: {
                fullDateFormat: '',
                dateFormat: '',
                monthLabel: [],
                weekStartOnMondays: true,
                dayLabel: [],
                dayLabelShort: []
            }
        };
        this.resources['fr'] = {
            key: 'fr',
            translation: {},
            locale: {
                fullDateFormat: '',
                dateFormat: '',
                monthLabel: [],
                weekStartOnMondays: true,
                dayLabel: [],
                dayLabelShort: []
            }
        };

        mapToDeepObject(this.resources['en'].locale, localeEn);
        this.resources['en'].translation = translationEn;
        mapToDeepObject(this.resources['fr'].locale, localeFr);
        this.resources['fr'].translation = translationFr;
    }
}
const localeInstance = new I18nLoader();

export const useLocale = (locale: string): TranslateResource | null => {
    return React.useMemo(() => {
        if (!hasProperty(localeInstance.resources, locale)) {
            console.warn(`Requesting an unknown ${locale} locale`);
            return null;
        }

        return localeInstance.resources[locale];
    }, [locale]);
};

export default localeInstance;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DomContainer", {
  enumerable: true,
  get: function () {
    return _DomContainer.default;
  }
});
var _DomContainer = _interopRequireDefault(require("./DomContainer"));
import * as Ark from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';

export type InspectionValidationRequest = {
    inspectionId: App.Inspection['id'];
    validationDate: Date;
};

export type InspectionPlotValidationRequest = {
    inspectionId: App.Inspection['id'];
    plotId: App.Plot['id'];
    validationDate: Date;
    ignored: App.Plot['ignored'];
    ignoredReason: App.Plot['ignoredReason'];
};

export type InspectionPlotObsCommentRequest = {
    inspectionId: App.Inspection['id'];
    plotId: App.Plot['id'];
    observationComment: App.Plot['observationComment'];
};

export const validateInspections = async (requests: InspectionValidationRequest[]): Promise<void> => {
    Ark.assertIsArray(requests, 'requests are not valid');

    let response;

    try {
        const year = new Date().getFullYear();
        const requestUrl = Ark.stringFormat('{0}/{1}/inspection/validation', config.apiUrl, year);
        const requestData = {
            validations: requests
        };

        response = await httpService.post<App.Api.Activity>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection validation API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send inspection validation request');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('INSPECTION_VALIDATE_UNAUTHORIZED');
    }
    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'INSPECTION_VALIDATE_ERROR',
            response.error.message ?? undefined
        );
    }
};

export const validateInspectionPlots = async (requests: InspectionPlotValidationRequest[]): Promise<void> => {
    Ark.assertIsArray(requests, 'requests are not valid');

    let response;

    try {
        const year = new Date().getFullYear();
        const requestUrl = Ark.stringFormat('{0}/{1}/inspection/plot/validation', config.apiUrl, year);
        const requestData = {
            validations: requests
        };

        response = await httpService.post<App.Api.Activity>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection plot validation API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send inspection plot validation request');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('INSPECTION_PLOT_VALIDATE_UNAUTHORIZED');
    }
    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'INSPECTION_PLOT_VALIDATE_ERROR',
            response.error.message ?? undefined
        );
    }
};

export const savePlotObsComment = async (requests: InspectionPlotObsCommentRequest[]): Promise<void> => {
    Ark.assertIsArray(requests, 'requests are not valid');

    let response;

    try {
        const year = new Date().getFullYear();
        const requestUrl = Ark.stringFormat('{0}/{1}/inspection/plot/obscomment', config.apiUrl, year);
        const requestData = {
            obsComments: requests
        };

        response = await httpService.post<App.Api.Activity>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling inspection plot obs comment save API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send inspection plot obs comment request');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('INSPECTION_PLOT_OBS_COMMENT_UNAUTHORIZED');
    }
    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'INSPECTION_PLOT_OBS_COMMENT_ERROR',
            response.error.message ?? undefined
        );
    }
};
import React from 'react';
import propTypes from 'prop-types';
import List from 'components/ui/List';
import { PlanningItem, planningItemPropTypes } from './types';
import PlanningListItem from './PlanningListItem';
import styles from '../PlanningScreen.module.scss';

type Props = {
    data: PlanningItem[];
    selected: PlanningItem | null;
    onSelect?: (planningItem: PlanningItem) => void;
};

const PlanningList: React.FC<Props> = ({ data, selected, onSelect }) => {
    const handleItemClick = React.useCallback((planningItem: PlanningItem) => {
        onSelect?.(planningItem);
    }, [onSelect]);

    return (
        <List className={`list-scroll ${styles['planningList']}`}>
            {data.map(item => (
                <PlanningListItem
                    key={`${item.id}-${item.assigneeId}`}
                    data={item}
                    onClick={handleItemClick}
                    isActive={selected?.id === item.id}
                />
            ))}
        </List>
    );
};

PlanningList.propTypes = {
    data: propTypes.arrayOf(planningItemPropTypes.isRequired).isRequired,
    selected: planningItemPropTypes,
    onSelect: propTypes.func
};

PlanningList.defaultProps = {
    onSelect: undefined,
    selected: undefined
};

export default React.memo(PlanningList);

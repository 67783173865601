import { isString, isValidNumber, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Mildiou feuilles
 ** - count: Comptage // /50 ceps
 **     0 <= Value <= 50
 **     Required
 ** - comment: Commentaire
 */

type CurrentObservation = App.Observation36;
type CurrentApiObservation = App.Api.Observation36;

export const validate = (
    observation: CurrentObservation,
    _: App.Plot
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, count, comment } = observation;

    if (ignored === true) return validation;

    // The value must be defined
    if (count == null || !isValidNumber(count)) {
        validation.count = {
            field: 'count',
            type: 'error',
            code: 'required'
        };
    }
    else if (count < 0 || count > 50) {
        validation.count = {
            field: 'count',
            type: 'error',
            code: 'format'
        };
    }

    if (comment != null && !isString(comment)) {
        validation.comment = {
            field: 'comment',
            type: 'error',
            code: 'format'
        };
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        count,
        comment
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.count = parseObservationField(count);
    parsedObs.comment = parseObservationField(comment);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        count,
        comment
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.count = count !== undefined ? new RecordValue(count) : null;
    mappedObs.comment = comment !== undefined ? new RecordValue(comment) : null;

    return mappedObs;
};
import * as Ark from '@dateam/ark';
import logger from 'utils/logger';
import httpService from 'utils/httpService';
import config from 'config';
import { parse as parseObservation } from 'data/observation/mapper';
import { mapEachProperty } from '@dateam/ark';
import { RecordValue } from 'data/observation';

export const get = async (userId: number | null, date: Date | null): Promise<App.Activity> => {
    Ark.assertType(userId, Ark.Type.Number | Ark.Type.Valid | Ark.Type.Null, 'user is not valid');
    Ark.assertType(date, Ark.Type.Date | Ark.Type.Valid | Ark.Type.Null, 'date is not valid');

    let response;

    try {
        const requestUrl = Ark.stringFormat('{0}/activity', config.apiUrl);
        const requestData: Record<string, any> = {};

        if (userId != null) requestData.userId = userId;
        if (date != null) requestData.date = Ark.formatDate(date);

        response = await httpService.get<App.Api.Activity>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling activity API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send activity request');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('ACTIVITY_GET_UNAUTHORIZED');
    }
    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'ACTIVITY_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assert(Ark.isDefined(response.body) && Ark.isObjectLike(response.body), 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('ACTIVITY_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return parseActivity(response.body);
};

export const getPlanning = async (): Promise<App.PlanningActivity[]> => {
    let response;

    try {
        const requestUrl = Ark.stringFormat('{0}/activity/planning', config.apiUrl);
        const requestData: Record<string, any> = {};

        response = await httpService.get<App.PlanningActivity[]>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling activity API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send activity planning request');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('ACTIVITY_PLANNING_GET_UNAUTHORIZED');
    }
    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'ACTIVITY_PLANNING_GET_ERROR',
            response.error.message ?? undefined
        );
    }

    try {
        Ark.assert(response.jsonContent === true, 'excepted JSON data');
        Ark.assertIsArray(response.body, 'invalid request data');
    }
    catch (err) {
        throw new Ark.ServiceError('ACTIVITY_PLANNING_GET_ASSERTION_FAILED', (err instanceof Error && err?.message) || '');
    }

    return response.body;
};

export type ObservationRecord = {
    inspectionId: App.Inspection['id'];
    plotId: App.Plot['id'];
    observation: App.Api.Observation;
};

export const saveRecords = async (records: ObservationRecord[]): Promise<void> => {
    Ark.assertIsArray(records, 'saveRecords: records are not valid');

    let response;

    try {
        const requestUrl = Ark.stringFormat('{0}/activity/records', config.apiUrl);

        response = await httpService.post(requestUrl, {
            records: records.map(record => ({
                inspectionId: record.inspectionId,
                plotId: record.plotId,
                ...mapEachProperty(record.observation, value => (value instanceof RecordValue ? value.value : value))
            }))
        });
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling login API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send record request (saveRecords)');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('RECORD_SAVE_UNAUTHORIZED');
    }

    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'RECORD_SAVE_ERROR',
            response.error.message ?? undefined
        );
    }
};

const parseActivity = (activity: App.Api.Activity): App.Activity => {
    const inspections = activity.inspections.map(inspection => {
        Ark.assertIsArray(inspection.plots, 'activity doesn\'t match expected format (`inspections[].plots`).');

        const plots = inspection.plots.map(plot => {
            Ark.assertIsArray(plot.observations, 'activity doesn\'t match expected format (`inspections[].plots[].observations`).');

            return {
                ...plot,
                observations: plot.observations.map(parseObservation)
            };
        });

        return {
            ...inspection,
            plots
        };
    });

    return {
        ...activity,
        inspections
    };
};
import React from 'react';
import { objectPick } from '@dateam/ark';
import { QueryResult, queryResultKeys } from 'data/constants';
import { useActivity } from 'data/activity';

type EffectResult<T> = QueryResult<T>;

export const useInspection = (inspectionId: App.Inspection['id'], options?: DataOptions): EffectResult<App.InspectionSync | null> => {
    const { data: activity, ...queryData } = useActivity(options);

    return React.useMemo(() => {
        const inspections = activity?.inspections ?? [];
        const inspectionFound = inspections.find(i => i.id === inspectionId);

        return objectPick({
            ...queryData,
            data: inspectionFound ?? null
        } as QueryResult<App.InspectionSync | null>, queryResultKeys);
    }, [inspectionId, activity, queryData]);
};
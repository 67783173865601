import React from 'react';
import propTypes from 'prop-types';
import { isFunction, isValidDate } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import styles from './InspectionDetails.module.scss';

type Props = {
    data: App.InspectionSync;
    onValidate: () => void;
};

const InspectionActions: React.FC<Props> = ({ data, onValidate }) => {
    const validateInspection = React.useCallback(() => {
        if (isFunction(onValidate)) onValidate();
    }, [onValidate]);

    return (
        <div className={styles['actions']}>
            <button
                type="button"
                className={concatClassName(
                    styles['actionBtn'],
                    isValidDate(data?.validationDate) ||
                        !data?.isValid ? styles['actionBtn-disabled'] : null

                )}
                onClick={validateInspection}
                disabled={isValidDate(data.validationDate) || !data.isValid}
            >
                {isValidDate(data.validationDate) ? 'Visite validée' : 'Valider la visite'}
            </button>
        </div>
    );
};

InspectionActions.propTypes = {
    data: propTypes.any.isRequired,
    onValidate: propTypes.func.isRequired
};

InspectionActions.defaultProps = {
};

export default InspectionActions;

import { Logger } from 'shared-utils';

const logger = new Logger();

export const parseLoggerLevel = (level: string): ObjectValues<typeof LoggerLevel> | undefined => {
    level = level.toUpperCase();
    if (Object.keys(LoggerLevel).includes(level)) return (LoggerLevel as any)[level];

    return undefined;
};

export const LoggerLevel = {
    LOG: Logger.LOG,
    DEBUG: Logger.DEBUG,
    INFO: Logger.INFO,
    WARN: Logger.WARN,
    ERROR: Logger.ERROR
} as const;
export default logger;
import React from 'react';
import { createRoot } from 'react-dom/client';
import { observer as swObserver, SW_INIT, SW_UPDATE } from 'utils/serviceWorkerObserver';
import App from './App';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render((
    <React.StrictMode>
        <App />
    </React.StrictMode>
));

serviceWorker.register({
    skipWaiting: true,
    onSuccess: (registration: any) => swObserver.notify({ type: SW_INIT, payload: registration }),
    onUpdate: (registration: any) =>
        swObserver.notify({ type: SW_UPDATE, payload: registration })
});

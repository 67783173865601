import React from 'react';
import propTypes from 'prop-types';
import { isBoolean, isString, isValidDate } from '@dateam/ark';
import { concatClassName, propTypeNullable, useDebounce } from '@dateam/ark-react';
import { Form } from 'shared-ui';
import {
    isRefLabelNumber,
    refLabelNumberPropTypes,
    refLabelStringPropTypes
} from 'utils/propTypes';
import { useSaveObservationComment } from 'data/observation';
import ObservationItem from 'components/Observation/ObservationItem';
import ObservationField from 'components/Observation/ObservationField';
import styles from './PlotDetails.module.scss';

type Props = {
    inspectionId: App.Inspection['id'];
    plot: App.Plot;
};

const ObservationComment: React.FC<Props> = ({
    inspectionId,
    plot
}) => {
    const { mutateAsync: saveObservationComment } = useSaveObservationComment();
    const plotId = React.useRef(plot?.id);

    const saveChanges = useDebounce(
        (...args: Parameters<typeof saveObservationComment>): ReturnType<typeof saveObservationComment> => {
            const [changes] = args;

            return saveObservationComment(changes);
        },
        300
    );

    React.useEffect(() => {
        if (plotId.current !== plot.id) plotId.current = plot.id;
    }, [plot]);

    const handleCommentChange = React.useCallback((newValue: string) => {
        saveChanges({
            inspectionId,
            plotId: plotId.current,
            observationComment: newValue
        });
    }, [inspectionId, plotId, saveChanges]);

    return (
        <ObservationItem label="Autres remarques">
            <div className="row">
                <ObservationField
                    id="observationComment"
                    className={concatClassName(styles['observationComment'], 'col-12')}
                >
                    <Form.Input
                        multiline
                        rows={4}
                        value={plot.observationComment ?? ''}
                        onChange={handleCommentChange}
                    />
                </ObservationField>
            </div>
        </ObservationItem>
    );
};

ObservationComment.propTypes = {
    inspectionId: propTypes.number.isRequired,
    plot: propTypes.shape({
        id: propTypes.number.isRequired,
        year: propTypes.number.isRequired,
        activityId: propTypes.number.isRequired,
        publicId: propTypes.string.isRequired,
        label: propTypes.string.isRequired,
        position: propTypes.number.isRequired,
        varietal: propTypeNullable(isRefLabelNumber),
        customer: propTypes.shape({
            id: propTypes.number.isRequired,
            label: propTypes.string.isRequired,
            contactInfo: propTypeNullable(isString)
        }).isRequired,
        area: propTypeNullable(isRefLabelNumber),
        bio: refLabelNumberPropTypes.isRequired,
        city: propTypes.string.isRequired,
        confusion: propTypeNullable(isBoolean),
        observationTypes: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
        observations: propTypes.arrayOf(propTypes.any.isRequired).isRequired,
        observationComment: propTypeNullable(isString),
        validationDate: propTypeNullable(isValidDate),
        ignored: propTypeNullable(isBoolean),
        ignoredReason: propTypeNullable(isString)
    }).isRequired
};

ObservationComment.defaultProps = {

};

export default ObservationComment;

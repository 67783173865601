import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'shared-ui';
import ObservationField from '../ObservationField';

type Props = {
    id: string;
    label: string;
    value: number | null | undefined;
    state?: 'error' | 'warning' | 'ok';
    required?: boolean;
    readOnly?: boolean;
    className?: string;
    onChange?: (value: number | null) => void;
    onFocus?: () => void;
};

const AfflictedObservationField: React.FC<Props> = ({
    id,
    label,
    value,
    state,
    required,
    readOnly,
    className,
    onChange,
    onFocus
}: Props) => {
    const handleValueChange = React.useCallback((newValue: number | undefined) => {
        onChange?.(newValue ?? null);
    }, [onChange]);

    const handleOnFocus = React.useCallback(() => {
        onFocus?.();
    }, [onFocus]);

    return (
        <ObservationField
            id={id}
            label={label}
            className={className}
        >
            <Form.Number
                min={0}
                max={2}
                value={value ?? undefined}
                onChange={handleValueChange}
                state={state}
                onFocus={handleOnFocus}
                required={required}
                readOnly={readOnly}
            />
        </ObservationField>
    );
};

AfflictedObservationField.propTypes = {
    id: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    value: propTypes.number,
    state: propTypes.oneOf(['error', 'warning', 'ok'] as const),
    required: propTypes.bool,
    readOnly: propTypes.bool,
    className: propTypes.string,
    onChange: propTypes.func,
    onFocus: propTypes.func
};

AfflictedObservationField.defaultProps = {
    value: undefined,
    state: 'ok',
    required: false,
    readOnly: false,
    className: undefined,
    onChange: undefined,
    onFocus: undefined
};

export default AfflictedObservationField;

import React from 'react';
import propTypes from 'prop-types';
import { isArray, isDefined, isFunction, orderBy } from '@dateam/ark';
import { useNavigation } from 'shared-utils';
import { useValidateInspection } from 'data/inspection';
import { InspectionNavigation } from 'screens/routes';
import InspectionDetailsHeader from './InspectionDetailsHeader';
import InspectionPlotList from './InspectionPlotList';
import InspectionActions from './InspectionActions';
import styles from './InspectionDetails.module.scss';

type Props = {
    value: App.Inspection;
    plotId: App.Plot['id'] | undefined;
    onBackClick?: () => void;
};

const InspectionDetails: React.FC<Props> = ({
    value,
    plotId,
    onBackClick
}) => {
    const { replace: replaceNavigation } = useNavigation();
    const { mutateAsync: validate } = useValidateInspection();
    const plots = React.useMemo(() => {
        if (isDefined(value) && isArray(value.plots)) {
            const plots = [...value.plots];

            orderBy(plots, 'position');

            return plots;
        }

        return [];
    }, [value]);

    React.useEffect(() => {
        if (plotId == null && isArray(plots) && plots.length > 0) {
            const plotsToComplete = plots.filter(plot => plot.validationDate == null);

            if (plotsToComplete.length > 0) {
                const [firstPlot] = plotsToComplete;

                replaceNavigation(InspectionNavigation(value.id, firstPlot.id));
            }
            else {
                const [firstPlot] = plots;

                replaceNavigation(InspectionNavigation(value.id, firstPlot.id));
            }
        }
    }, [value, plotId, plots, replaceNavigation]);

    const handleBackClick = React.useCallback(() => {
        if (isFunction(onBackClick)) {
            onBackClick();
        }
    }, [onBackClick]);

    const selectPlot = React.useCallback((plot: App.Plot) => {
        replaceNavigation(InspectionNavigation(value.id, plot.id));
    }, [value, replaceNavigation]);

    const validateInspection = () => {
        validate({ inspectionId: value.id });
    };

    return (
        <section className={styles['inspection']}>
            <InspectionDetailsHeader
                data={value}
                onBackClick={handleBackClick}
            />
            <InspectionPlotList
                data={plots}
                selected={plotId}
                onClick={selectPlot}
            />
            <InspectionActions data={value as App.InspectionSync} onValidate={validateInspection} />
        </section>
    );
};

InspectionDetails.propTypes = {
    value: propTypes.any.isRequired,
    plotId: propTypes.number,
    onBackClick: propTypes.func
};

InspectionDetails.defaultProps = {
    plotId: undefined,
    onBackClick: undefined
};

export default InspectionDetails;

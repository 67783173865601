import React from 'react';
import propTypes from 'prop-types';
import { isFunction } from '@dateam/ark';
import { concatClassName, useInternalValue } from '@dateam/ark-react';
import AccordionTitle from './AccordionTitle';
import AccordionContent from './AccordionContent';
import AccordionContext from './AccordionContext';

type Props = {
    className?: string;
    expanded?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
    onChange?: (expanded: boolean) => void;
};

const Accordion: React.FC<Props> = ({
    className,
    expanded,
    disabled,
    children,
    onChange
}: Props) => {
    const [isExpandedState, setIsExpandedState] = useInternalValue(expanded, false);
    
    const handleToggle = React.useCallback(() => {
        setIsExpandedState(!isExpandedState);

        if (isFunction(onChange)) {
            onChange(!isExpandedState);
        }
    }, [isExpandedState, onChange, setIsExpandedState]);
   
    const context = React.useMemo(() => ({
        expanded: isExpandedState === true,
        disabled: disabled === true,
        toggle: handleToggle
    }), [isExpandedState, disabled, handleToggle]);

    return (
        <AccordionContext.Provider value={context}>
            <div
                className={concatClassName(
                    'accordion',
                    isExpandedState === true ? 'accordion-expanded' : null,
                    className
                )}
            >
                {children}
            </div>
        </AccordionContext.Provider>
    );
};

Accordion.propTypes = {
    className: propTypes.string,
    expanded: propTypes.bool,
    disabled: propTypes.bool,
    children: propTypes.node,
    onChange: propTypes.func
};

Accordion.defaultProps = {
    className: undefined,
    expanded: undefined,
    disabled: undefined,
    children: undefined,
    onChange: undefined
};

export default Object.assign(Accordion, {
    Title: AccordionTitle,
    Content: AccordionContent
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'shared-utils';
import config from 'config';
import Page from 'components/Page';
import { Activity, SyncInfo } from './components';
import styles from './DashboardScreen.module.scss';

const DashboardScreen: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('dashboard.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <Page className="page-full">
                <div className={styles['header']}>
                    <Page.Title className={styles['headerTitle']}>
                        Mes visites
                    </Page.Title>
                    <SyncInfo className={styles['headerSync']} />
                </div>
                <Page.Content className={`pageContent-auto ${styles['content']}`}>
                    <Activity />
                </Page.Content>
            </Page>
        </>
    );
};

export default withRouter(DashboardScreen, 'DASHBOARD');

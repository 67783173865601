import { isDefined, Observer } from '@dateam/ark';
import { createGlobalStateHook } from '@dateam/ark-react';
import React from 'react';

export const SW_INIT = 'SW_INIT';
export const SW_UPDATE = 'SW_UPDATE';

export const observer = new Observer();
const { hook: useObserver } = createGlobalStateHook<Observer>(new Observer());

export const useServiceWorkerEvent = (callback?: (() => void)): (() => void) => {
    const [swObserver] = useObserver();

    React.useEffect(() => {
        if (!isDefined(callback)) return;

        return swObserver.subscribe(callback);
    }, [swObserver, callback]);

    return () => swObserver.notify({});
};

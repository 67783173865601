import React from 'react';
import { useMutation } from 'react-query';
import * as Ark from '@dateam/ark';
import { usePick, useConnectivity } from '@dateam/ark-react';
import logger from 'utils/logger';
import connectivity from 'utils/connectivity';
import { queryClient } from 'utils/queryClient';
import { getSyncParam, setSyncParam, useLastSync, useSyncError } from 'utils/sync';
import { getUserState } from 'utils/userStore';
import { useConfig } from 'config';
import { unauthorizedProvider } from 'components/unauthorizedHandler';
import { map as mapObservation } from 'data/observation/mapper';
import {
    SYNC_KEY,
    MutationResult,
    mutateResultKeys,
    REF_OBSERVATOR_KEY,
    REF_KEY,
    PLANNING_KEY,
    defaultActionOptions
} from '../constants';
import {
    activityRequests,
    inspectionRequests,
    userRequests
} from '../requests';
import {
    computeActivity,
    getLocalActivity,
    getLocalSyncParam,
    saveActivity,
    saveObservators,
    savePlanning
} from './helpers';

export { getLocalActivity, saveActivity } from './helpers';

type ActivityBehavior = {
    saveRequired: false;
    activity: App.ActivitySync;
    observators?: App.User[];
    planning?: App.PlanningSync;
} | {
    saveRequired: true;
    activity: App.ActivitySync;
    observators: App.User[];
    planning: App.PlanningSync;
};

export const useSync = (): MutationResult<ActivityBehavior, void> => {
    const isOnline = useConnectivity();
    const [lastSyncDate, setLastSyncDate] = useLastSync();
    const [, setSyncError] = useSyncError();

    const result = useMutation<ActivityBehavior, Ark.ServiceError, void>(
        async () => {
            logger.debug('Sync: starting...');
            setSyncError(false);

            const user = getUserState();
            if (user == null) throw new Error('Sync: no user available, aborted.');

            const params = getSyncParam();
            if (params == null) throw new Error('Sync: no parameter available, aborted.');

            const { userId } = params;
            const requestedDate = new Date();

            let activitySync: App.ActivitySync | undefined;

            try {
                logger.debug('Sync: retrieving local data...');
                activitySync = await getLocalActivity(user.id);
                logger.debug(`Sync: local data retrieved (${activitySync?.inspections.length ?? 0} inspections)`);
            }
            catch (err) {
                logger.error('Sync: failed to access local data.', err);
                throw new Error('Sync: failed to access local data.');
            }

            if (!isOnline) {
                if (activitySync != null) {
                    return {
                        saveRequired: false,
                        activity: activitySync
                    };
                }

                logger.debug('Sync: offline without local data');
                return {
                    saveRequired: false,
                    activity: {
                        userId,
                        requestedDate,
                        toDate: requestedDate,
                        fromDate: requestedDate,
                        inspections: [],
                        syncState: 'none'
                    } as App.ActivitySync
                };
            }

            if (activitySync != null) await pushData(activitySync, lastSyncDate);

            try {
                logger.debug('Sync: Request server data...');
                const [activity, observators, planningActivities] = await Promise.all([
                    activityRequests.get(userId, requestedDate),
                    userRequests.getObservators(),
                    activityRequests.getPlanning()
                ]);

                setLastSyncDate(new Date());
                return {
                    saveRequired: true,
                    activity: reconcileSync(activity, activitySync),
                    observators,
                    planning: {
                        id: 'currentPlanning',
                        requestedDate: new Date(),
                        activity: planningActivities
                    }
                };
            }
            catch (error) {
                if (error instanceof Ark.ServiceError && error.code.indexOf('_UNAUTHORIZED') >= 0) unauthorizedProvider.notify();

                logger.error('Sync: failed to access server data.', error);
                setSyncError(true);
                if (activitySync != null) {
                    return {
                        saveRequired: false,
                        activity: activitySync
                    };
                }
                throw new Error('Sync: failed to access server data.');
            }
        },
        {
            ...defaultActionOptions,
            onSuccess: async behavior => {
                const user = getUserState();
                if (user == null) return;

                logger.debug('Sync: apply new queries\' data...');

                queryClient.setQueryData([SYNC_KEY, Ark.formatDate(new Date())], _ => behavior);
                if (behavior.observators != null) {
                    queryClient.setQueryData([REF_KEY, REF_OBSERVATOR_KEY], _ => behavior.observators);
                }
                if (behavior.planning != null) {
                    queryClient.setQueryData([PLANNING_KEY], _ => behavior.planning);
                }

                logger.debug('Sync: saving to local storage...');

                const promises: Promise<unknown>[] = [];
                promises.push(saveActivity(user.id, behavior.activity, behavior.saveRequired));

                if (behavior.saveRequired) {
                    promises.push(saveObservators(behavior.observators));
                    promises.push(savePlanning(behavior.planning));
                }

                await Promise.all(promises);

                logger.debug('Sync: data stored to local');

                logger.debug('Sync: trigger refresh queries');
                queryClient.invalidateQueries([SYNC_KEY]);
                queryClient.invalidateQueries([REF_KEY, REF_OBSERVATOR_KEY]);
                queryClient.invalidateQueries([PLANNING_KEY]);
            }
        }
    );

    return result;
};

const pushData = async (activitySync: App.ActivitySync, lastSyncDate: Nullable<Date>): Promise<void> => {
    try {
        if (activitySync.syncState !== 'none') {
            logger.debug('Sync: saving local data...');

            const records: activityRequests.ObservationRecord[] = [];
            const inspections: inspectionRequests.InspectionValidationRequest[] = [];
            const plots: inspectionRequests.InspectionPlotValidationRequest[] = [];
            const obsComments: inspectionRequests.InspectionPlotObsCommentRequest[] = [];

            activitySync.inspections.forEach(inspection => {
                Ark.assertIsArray(inspection.plots, 'activity doesn\'t match expected format (`inspections[].plots`).');

                if (inspection.syncState === 'none') return;

                if (inspection.validationChanged && inspection.validationDate != null) {
                    inspections.push({
                        inspectionId: inspection.id,
                        validationDate: inspection.validationDate
                    });
                }

                inspection.plots.forEach(plot => {
                    Ark.assertIsArray(plot.observations, 'activity doesn\'t match expected format (`inspections[].plots[].observations`).');

                    if (plot.readyForSync === false) return;

                    if (plot.validationChanged && Ark.isValidDate(plot.validationDate)) {
                        plots.push({
                            inspectionId: inspection.id,
                            plotId: plot.id,
                            validationDate: plot.validationDate,
                            ignored: plot.ignored,
                            ignoredReason: plot.ignoredReason
                        });
                    }

                    plot.observations.forEach(observation => {
                        if (Ark.isValidDate(observation.updatedOn)) {
                            records.push({
                                observation: mapObservation(observation),
                                inspectionId: inspection.id,
                                plotId: plot.id
                            });
                        }
                    });

                    obsComments.push({
                        inspectionId: inspection.id,
                        plotId: plot.id,
                        observationComment: plot.observationComment
                    });
                });
            });

            if (records.length > 0) await activityRequests.saveRecords(records);
            if (plots.length > 0) await inspectionRequests.validateInspectionPlots(plots);
            if (inspections.length > 0) await inspectionRequests.validateInspections(inspections);
            if (obsComments.length > 0) await inspectionRequests.savePlotObsComment(obsComments);

            logger.debug('Sync: local data saved');
        }
    }
    catch {
        logger.debug('Sync: failed to save local data.');
        throw new Error('Sync: failed to save local data.');
    }
};

const reconcileSync = (freshActivity: App.Activity, activitySync?: App.ActivitySync): App.ActivitySync => {
    if (activitySync == null || ['none', 'full'].indexOf(activitySync.syncState) >= 0) {
        return computeActivity(freshActivity);
    }

    const inspections = activitySync.inspections.reduce((inspectionAcc: App.Inspection[], inspection) => {
        const inspectionMatch = freshActivity.inspections.find(freshInspection => freshInspection.id === inspection.id);
        if (inspectionMatch == null) return inspectionAcc;
        if (inspection.syncState === 'full') {
            inspectionAcc.push(inspectionMatch);
            return inspectionAcc;
        }

        Ark.assertIsArray(inspection.plots, 'activity doesn\'t match expected format (`inspections[].plots`).');

        const plots = inspection.plots.reduce((plotAcc: App.Plot[], plot) => {
            const plotMatch = inspectionMatch.plots.find(freshPlot => freshPlot.id === plot.id);

            if (plotMatch != null) {
                if (plot.readyForSync === true || plot.hasChanged === false) {
                    plotAcc.push(plotMatch);
                }
                else {
                    Ark.assertIsArray(plot.observations, 'activity doesn\'t match expected format (`inspections[].plots[].observations`).');
                    const syncObservationIds = plotMatch.observations.map(({ id }) => id);

                    // Keeping observations that are still in activity
                    const observations = plot.observations.reduce((obsAcc: App.Observation[], observation) => {
                        if (syncObservationIds.includes(observation.id) || observation.manuallyAdded === true) {
                            obsAcc.push(observation);
                        }

                        return obsAcc;
                    }, []);

                    // Adding newly synced observation
                    plotMatch.observations.reduce((obsAcc: App.Observation[], observation) => {
                        if (!obsAcc.find(item => item.id === observation.id)) obsAcc.push(observation);

                        return obsAcc;
                    }, observations);

                    plotAcc.push({
                        ...plotMatch,
                        validationDate: plot.validationDate,
                        observations
                    });
                }
            }

            return plotAcc;
        }, []);

        inspectionMatch.plots.reduce((plotAcc: App.Plot[], plot) => {
            if (!plotAcc.find(item => item.id === plot.id)) plotAcc.push(plot);

            return plotAcc;
        }, plots);

        inspectionAcc.push({
            ...inspectionMatch,
            validationDate: inspection.validationDate,
            plots
        });

        return inspectionAcc;
    }, []);

    freshActivity.inspections.reduce((inspectionAcc: App.Inspection[], inspection) => {
        if (!inspectionAcc.find(item => item.id === inspection.id)) inspectionAcc.push(inspection);

        return inspectionAcc;
    }, inspections);

    const reconciledActivity = {
        ...freshActivity,
        inspections
    };

    return computeActivity(reconciledActivity);
};

/*
 ** Sync Manager
 */

type SyncManager = {
    start: () => Promise<void>;
    stop: () => void;
    forceSync: () => void;
};

export const useSyncManager = (): SyncManager => {
    const { syncInterval } = useConfig();
    const isOnline = useConnectivity();
    const [isLaunched, setIsLaunched] = React.useState(false);
    const { mutateAsync: sync, isLoading } = useSync();
    const [lastSyncDate] = useLastSync();
    const cancelInterval = React.useRef(Ark.noop);

    // Starts interval to refresh synced data over time
    const startInterval = React.useCallback(() => {
        cancelInterval.current = Ark.setIntervalAsync(() => {
            sync();
        }, syncInterval);
    }, [sync, syncInterval]);

    // Subscribe to connectivity to trigger sync once network is working
    React.useEffect(() => connectivity.subscribe(isOnline => {
        if (!isLaunched) return;

        if (!isOnline) {
            cancelInterval.current();
            return;
        }

        if (!isLoading) {
            // If there has been no sync or the last one occurred more thant 5 minutes ago
            if (lastSyncDate != null && lastSyncDate.addMinute(5) < new Date()) {
                sync();
            }
        }

        startInterval();
    }), [
        lastSyncDate,
        isLaunched,
        isLoading,
        sync,
        startInterval
    ]);

    const start = React.useCallback(async () => {
        if (isLaunched) return;

        setIsLaunched(true);

        const user = getUserState();
        if (user == null) throw new Error('Sync: no user available, aborted.');

        const params = await getLocalSyncParam(user.id);

        setSyncParam(prevState => {
            if (prevState != null) return prevState;
            if (params != null) return params;

            return { userId: user.id };
        });

        await sync();

        if (isOnline) {
            startInterval();
        }
    }, [
        isOnline,
        isLaunched,
        sync,
        startInterval,
        setIsLaunched
    ]);

    const stop = React.useCallback(() => {
        if (!isLaunched) return;

        setSyncParam(null);
        setIsLaunched(false);

        cancelInterval.current();
    }, [
        isLaunched,
        setIsLaunched
    ]);

    const forceSync = React.useCallback(() => {
        if (!isOnline) return;

        return sync();
    }, [isOnline, sync]);

    return React.useMemo(() => {
        return {
            start,
            stop,
            forceSync
        };
    }, [
        start,
        stop,
        forceSync
    ]);
};
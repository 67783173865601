import React from 'react';
import { TimedNotifier } from '@dateam/ark';
var generateNavigationHooks = function generateNavigationHooks() {
  var _notifier = new TimedNotifier();
  return {
    useSubscriber: function useSubscriber(callback, deps) {
      var cb = React.useCallback(callback, deps); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(function () {
        var unsubscribe = _notifier.subscribe(cb);
        return unsubscribe;
      }, [cb]); // eslint-disable-line react-hooks/exhaustive-deps
    },
    notifier: function notifier() {
      return React.useCallback(function (data) {
        return _notifier.notify(data);
      }, []);
    }
  };
};
var navigationHooks = generateNavigationHooks();
var preNavigationHooks = generateNavigationHooks();
export var useNavigationEvent = navigationHooks.useSubscriber;
export var usePreNavigationEvent = preNavigationHooks.useSubscriber;
export var useNavigationNotifier = navigationHooks.notifier;
export var usePreNavigationNotifier = preNavigationHooks.notifier;
import React from 'react';
import propTypes from 'prop-types';
import { isDefined, isFunction, strIncludes } from '@dateam/ark';
import { Form } from 'shared-ui';
import { ADVENTICE_VALUES } from 'data/observation/adventice';
import ObservationField from '../ObservationField';

type Props = {
    id: string;
    value: string | null | undefined;
    label?: string;
    state?: 'error' | 'warning' | 'ok';
    required?: boolean;
    readOnly?: boolean;
    className?: string;
    onChange?: (value: string) => void;
    onFocus?: () => void;
};

const AdventiceObservationField: React.FC<Props> = ({
    id,
    value,
    label,
    state,
    required,
    readOnly,
    onChange,
    onFocus,
    className
}: Props) => {
    const handleGetOptions = React.useCallback(async (value: string) => {
        if (value.length === 0) return ADVENTICE_VALUES;

        return ADVENTICE_VALUES.filter(item => strIncludes(value, item, {
            caseInsensitive: true,
            ignoreDiacritics: true,
            startsWith: true
        }));
    }, []);

    const handleOptionChange = React.useCallback((newValue: string | undefined) => {
        if (!isDefined(newValue)) return;

        if (isFunction(onChange)) onChange(newValue);
    }, [onChange]);

    const handleOnFocus = React.useCallback(() => {
        if (isFunction(onFocus)) onFocus();
    }, [onFocus]);

    return (
        <ObservationField
            id={id}
            className={className}
            label={label}
        >
            <Form.Autocomplete<string>
                value={value ?? undefined}
                onGetOptions={handleGetOptions}
                onChange={handleOptionChange}
                state={state}
                onFocus={handleOnFocus}
                required={required}
                readOnly={readOnly}
            />
            {required && label === undefined && (
                <span className="required">*</span>
            )}
        </ObservationField>
    );
};

AdventiceObservationField.propTypes = {
    id: propTypes.string.isRequired,
    value: propTypes.string,
    label: propTypes.string,
    state: propTypes.oneOf(['error', 'warning', 'ok'] as const),
    required: propTypes.bool,
    readOnly: propTypes.bool,
    className: propTypes.string,
    onChange: propTypes.func,
    onFocus: propTypes.func
};

AdventiceObservationField.defaultProps = {
    value: undefined,
    label: undefined,
    state: 'ok',
    required: false,
    readOnly: false,
    className: undefined,
    onChange: undefined,
    onFocus: undefined
};

export default AdventiceObservationField;

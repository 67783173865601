import { Router, RouteData, NavigationRoute } from 'shared-utils';
import PlanningScreen from './PlanningScreen';

export const PlanningNavigation = (): NavigationRoute =>
    new NavigationRoute(PlanningScreen.key);

const router = new Router();

router
    .add(new RouteData({
        key: PlanningScreen.key,
        path: '',
        component: PlanningScreen,
        exact: true,
        metadata: {
            authRequired: true
        }
    }));

export default router;

import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { Button, Modal } from 'shared-ui';

type Props = {
    title: string;
    message: string;
    className?: string;
    onClose?: () => void;
};

const UnauthorizedDialog: React.FC<Props> = ({
    title,
    message,
    className,
    onClose
}: Props) => {
    const handleClose = React.useCallback(() => onClose?.(), [onClose]);

    return (
        <Modal
            className={concatClassName('dialog dialog-confirm', className)}
            backdrop
            centered
        >
            <Modal.Header>
                <>
                    <svg className="dialog-icon" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 438.533 438.533">
                        <path d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0    c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267    c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407    s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062    C438.533,179.485,428.732,142.795,409.133,109.203z M387.434,290.213c-9.709,22.556-22.696,41.97-38.969,58.245    c-16.271,16.269-35.689,29.26-58.245,38.965c-22.555,9.712-46.202,14.564-70.946,14.564c-24.744,0-48.391-4.853-70.948-14.564    c-22.554-9.705-41.971-22.696-58.245-38.965c-16.269-16.275-29.259-35.687-38.97-58.245    c-9.707-22.552-14.562-46.206-14.562-70.946c0-24.744,4.854-48.391,14.562-70.948c9.707-22.554,22.697-41.968,38.97-58.245    c16.274-16.269,35.691-29.26,58.245-38.97c22.554-9.704,46.205-14.558,70.948-14.558c24.74,0,48.395,4.851,70.946,14.558    c22.556,9.707,41.97,22.698,58.245,38.97c16.272,16.274,29.26,35.688,38.969,58.245c9.709,22.554,14.564,46.201,14.564,70.948    C402.002,244.011,397.143,267.664,387.434,290.213z" />
                        <path d="M284.368,258.668c-19.219-14.086-40.926-21.129-65.095-21.129c-24.172,0-45.871,7.039-65.096,21.129    c-19.224,14.085-32.358,32.641-39.397,55.671c-1.521,4.757-1.143,9.381,1.141,13.847c2.286,4.469,5.898,7.467,10.85,8.993    c4.758,1.526,9.373,1.143,13.846-1.144c4.471-2.285,7.467-5.899,8.991-10.848c4.759-15.235,13.562-27.556,26.409-36.979    c12.847-9.418,27.263-14.127,43.252-14.127c15.987,0,30.412,4.712,43.251,14.127c12.854,9.424,21.655,21.744,26.412,36.979    c1.52,4.948,4.564,8.562,9.134,10.848c4.568,2.286,9.236,2.67,13.989,1.144c4.761-1.526,8.278-4.524,10.564-8.993    c2.286-4.466,2.669-9.09,1.14-13.847C316.729,291.312,303.591,272.75,284.368,258.668z" />
                        <path d="M146.181,182.725c10.085,0,18.699-3.576,25.837-10.709c7.139-7.135,10.708-15.749,10.708-25.837    c0-10.089-3.569-18.699-10.708-25.837s-15.752-10.709-25.837-10.709c-10.088,0-18.702,3.571-25.84,10.709    c-7.135,7.139-10.707,15.749-10.707,25.837c0,10.088,3.568,18.702,10.707,25.837C127.482,179.152,136.093,182.725,146.181,182.725    z" />
                        <path d="M292.359,109.631c-10.089,0-18.706,3.571-25.845,10.709c-7.132,7.139-10.708,15.749-10.708,25.837    c0,10.088,3.576,18.702,10.708,25.837c7.139,7.137,15.756,10.709,25.845,10.709c10.081,0,18.698-3.576,25.837-10.709    c7.139-7.135,10.708-15.749,10.708-25.837c0-10.089-3.569-18.699-10.708-25.837S302.44,109.631,292.359,109.631z" />
                    </svg>
                    <h2 className="dialog-title">{title}</h2>
                </>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                >
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

// class ErrorDialog extends Component<ErrorDialogProps, ErrorDialogState> {
//     onCloseClick = () => {
//         if (!this.props.onClose) return;

//         this.props.onClose();
//     }

//     render() {
//         const { title, message } = this.props;

//         return (
//             <Dialog
//                 open
//                 classname="dialog-box dialog-error"
//             >
//                 <DialogHeader>
//                     <>
//                         <svg className="dialog-icon" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 438.533 438.533">
//                             <path d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0    c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267    c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407    s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062    C438.533,179.485,428.732,142.795,409.133,109.203z M387.434,290.213c-9.709,22.556-22.696,41.97-38.969,58.245    c-16.271,16.269-35.689,29.26-58.245,38.965c-22.555,9.712-46.202,14.564-70.946,14.564c-24.744,0-48.391-4.853-70.948-14.564    c-22.554-9.705-41.971-22.696-58.245-38.965c-16.269-16.275-29.259-35.687-38.97-58.245    c-9.707-22.552-14.562-46.206-14.562-70.946c0-24.744,4.854-48.391,14.562-70.948c9.707-22.554,22.697-41.968,38.97-58.245    c16.274-16.269,35.691-29.26,58.245-38.97c22.554-9.704,46.205-14.558,70.948-14.558c24.74,0,48.395,4.851,70.946,14.558    c22.556,9.707,41.97,22.698,58.245,38.97c16.272,16.274,29.26,35.688,38.969,58.245c9.709,22.554,14.564,46.201,14.564,70.948    C402.002,244.011,397.143,267.664,387.434,290.213z" />
//                             <path d="M284.368,258.668c-19.219-14.086-40.926-21.129-65.095-21.129c-24.172,0-45.871,7.039-65.096,21.129    c-19.224,14.085-32.358,32.641-39.397,55.671c-1.521,4.757-1.143,9.381,1.141,13.847c2.286,4.469,5.898,7.467,10.85,8.993    c4.758,1.526,9.373,1.143,13.846-1.144c4.471-2.285,7.467-5.899,8.991-10.848c4.759-15.235,13.562-27.556,26.409-36.979    c12.847-9.418,27.263-14.127,43.252-14.127c15.987,0,30.412,4.712,43.251,14.127c12.854,9.424,21.655,21.744,26.412,36.979    c1.52,4.948,4.564,8.562,9.134,10.848c4.568,2.286,9.236,2.67,13.989,1.144c4.761-1.526,8.278-4.524,10.564-8.993    c2.286-4.466,2.669-9.09,1.14-13.847C316.729,291.312,303.591,272.75,284.368,258.668z" />
//                             <path d="M146.181,182.725c10.085,0,18.699-3.576,25.837-10.709c7.139-7.135,10.708-15.749,10.708-25.837    c0-10.089-3.569-18.699-10.708-25.837s-15.752-10.709-25.837-10.709c-10.088,0-18.702,3.571-25.84,10.709    c-7.135,7.139-10.707,15.749-10.707,25.837c0,10.088,3.568,18.702,10.707,25.837C127.482,179.152,136.093,182.725,146.181,182.725    z" />
//                             <path d="M292.359,109.631c-10.089,0-18.706,3.571-25.845,10.709c-7.132,7.139-10.708,15.749-10.708,25.837    c0,10.088,3.576,18.702,10.708,25.837c7.139,7.137,15.756,10.709,25.845,10.709c10.081,0,18.698-3.576,25.837-10.709    c7.139-7.135,10.708-15.749,10.708-25.837c0-10.089-3.569-18.699-10.708-25.837S302.44,109.631,292.359,109.631z" />
//                         </svg>
//                         <h1 className="dialog-title">{title}</h1>
//                     </>
//                 </DialogHeader>
//                 <DialogContent>
//                     {message}
//                 </DialogContent>
//                 <DialogFooter>
//                     <button type="button" className="btn dialog-action-close dialog-action" onClick={this.onCloseClick}>Fermer</button>
//                 </DialogFooter>
//             </Dialog>
//         );
//     }
// }

UnauthorizedDialog.propTypes = {
    title: propTypes.string.isRequired,
    message: propTypes.string.isRequired,
    className: propTypes.string,
    onClose: propTypes.func
};

UnauthorizedDialog.defaultProps = {
    className: undefined,
    onClose: undefined
};

export default UnauthorizedDialog;
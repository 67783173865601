import React from 'react';

type AccordionContextType = {
    expanded: boolean;
    disabled: boolean;
    toggle: (() => void) | undefined;
};

const AccordionContext = React.createContext<AccordionContextType>({
    expanded: false,
    disabled: false,
    toggle: undefined
});

export default AccordionContext;
import { mapToDeepObject, quickClone } from '@dateam/ark';
import { LogConfig, queryConfig } from './model';
import { default as appConfig } from './app-config.json';
import { default as appConfigTest } from './app-config.test.json';
import { default as appConfigProd } from './app-config.prod.json';

export class Config {
    appName: string = 'AppName';
    version: string = 'x.x.x';
    prod: boolean = false;
    apiUrl: string = '';
    appInsightsKey: string = '';
    dbName: string = '';
    authTokenKey: string = '';
    log = new LogConfig();
    syncInterval: number = 50000;
    query: typeof queryConfig = { ...queryConfig };

    constructor() {
        mapToDeepObject(this, quickClone(appConfig));

        if (process.env.NODE_ENV === 'test') {
            mapToDeepObject(this, quickClone(appConfigTest));
        }
        else if (process.env.NODE_ENV === 'production') {
            mapToDeepObject(this, quickClone(appConfigProd));
        }
    }
}

export default new Config();

import List from 'components/ui/List';
import React from 'react';
import propTypes from 'prop-types';
import { useLocale } from 'src/i18n/i18nLoader';
import styles from '../DashboardScreen.module.scss';

type Props = {
    date: Date;
}

const DateColumn: React.FC<Props> = ({ date }) => {
    const ressource = useLocale('fr');

    const day = React.useMemo(() => date.getDate(), [date]);

    const month = React.useMemo(() => {
        if (ressource === null || ressource.locale.monthLabel.length - 1 < date.getMonth()) return '';
        return ressource.locale.monthLabel[date.getMonth()];
    }, [date, ressource]);
    
    return (
        <List.Item.Col className={styles['colDate']}>
            <strong className={styles['colDateDay']}>{day}</strong>
            <span className={styles['colDateMonth']}>{month}</span>
        </List.Item.Col>
    );
};

DateColumn.propTypes = {
    date: propTypes.instanceOf(Date).isRequired
};

DateColumn.defaultProps = {
};

export default DateColumn;

import React from 'react';
import { useQuery } from 'react-query';
import { computeOptions, ServiceError } from '@dateam/ark';
import { usePick } from '@dateam/ark-react';
import logger from 'utils/logger';
import { getUserState } from 'utils/userStore';
import { queryClient } from 'utils/queryClient';
import {
    defaultDataOptions,
    QueryResult,
    queryResultKeys,
    ACTIVITY_KEY
} from './constants';
import { getLocalActivity } from './sync';

export const useActivity = (options?: DataOptions): QueryResult<App.ActivitySync | null> => {
    const config = React.useMemo(() => computeOptions(defaultDataOptions, options), [options]);
    const query = useQuery<App.ActivitySync | null, ServiceError>({
        ...config,
        queryKey: ACTIVITY_KEY,
        queryFn: async () => {
            // Checking current data
            let queryState = queryClient.getQueryState([ACTIVITY_KEY]);
            if (queryState != null) {
                const { data: cachedData, isInvalidated } = queryState;

                // if there are already some valid data, simply return it
                if (isInvalidated === false && cachedData != null) return cachedData as App.ActivitySync;
            }

            const user = getUserState();
            if (user == null) throw new Error('Activity: no user available, aborted.');

            const localActivity = await getLocalActivity(user.id);

            // Checking again for current data
            // in case query updated while accessing local data
            queryState = queryClient.getQueryState([ACTIVITY_KEY]);
            if (queryState != null) {
                const { data: cachedData, isInvalidated } = queryState;

                // if there are already some valid data, simply return it
                if (isInvalidated === false && cachedData != null) return cachedData as App.ActivitySync;
            }
            logger.debug('Activity: No data in cache, updating with local data...');

            // Returning local data or default value if none exists
            return localActivity ?? null;
        },
        onError: () => {
            logger.error('Activity: failed to access local data.');
        }
    });

    return usePick(query as any, queryResultKeys);
};
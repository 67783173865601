import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { dateToFormat } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { Menu } from 'shared-ui';
import { useUserState } from 'utils/userStore';
import { resetDatabase } from 'utils/localDb';
import config from 'config';
import { useDebugDatabase } from 'data/debug';
import { ConfirmDialog } from 'components/ui/dialog';
import styles from './SidebarClock.module.scss';

type Props = {
    className?: string;
};

const SidebarClock: React.FC<Props> = ({
    className
}: Props) => {
    const [user] = useUserState();
    const { mutateAsync: debugDatabase } = useDebugDatabase();
    const [currentDate, setCurrentDate] = React.useState(new Date());
    const [showDebug, setShowDebug] = React.useState(false);
    const [showDebugDialog, setShowDebugDialog] = React.useState(false);
    const [showLocalDataDialog, setShowLocalDataDialog] = React.useState(false);
    const versionRef = React.useRef<HTMLDivElement>(null);

    const openDebug = React.useCallback(() => setShowDebug(true), []);
    const closeDebug = React.useCallback(() => setShowDebug(false), []);
    const openDebugDialog = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation(); // To prevent the openDebug handler to be triggered
        closeDebug();
        setShowDebugDialog(true);
    }, [closeDebug]);
    const closeDebugDialog = React.useCallback(async (confirmed: boolean) => {
        if (confirmed === true) {
            await debugDatabase();
        }

        setShowDebugDialog(false);
    }, [debugDatabase]);

    const openLocalDataDialog = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation(); // To prevent the openDebug handler to be triggered
        closeDebug();
        setShowLocalDataDialog(true);
    }, [closeDebug]);
    const closeLocalDataDialog = React.useCallback(async (confirmed: boolean) => {
        if (confirmed === true) {
            await resetDatabase();
        }

        setShowLocalDataDialog(false);
    }, []);

    useEffect(() => {
        const intervalTimeout = setInterval(() => {
            setCurrentDate(new Date());
        }, 10000);

        return () => clearInterval(intervalTimeout);
    }, []);

    const dateText = React.useMemo(() => dateToFormat(currentDate, 'dd/MM'), [currentDate]);
    const timeText = React.useMemo(() => dateToFormat(currentDate, 'HH:mm'), [currentDate]);
    const userDebugOn = React.useMemo(() => user?.debugOn === true, [user]);

    return (
        <>
            <div
                role="button"
                tabIndex={0}
                ref={versionRef}
                onClick={openDebug}
                className={concatClassName(styles['clock'], className)}
            >
                <span className={styles['clockDate']}>{dateText}</span>
                <span className={styles['clockTime']}>{timeText}</span>
                {showDebug && (
                    <Menu
                        anchorEl={versionRef}
                        position="right-bottom"
                        onClose={closeDebug}
                    >
                        {userDebugOn === true && (
                            <>
                                <Menu.Item
                                    onClick={openDebugDialog}
                                    className={styles['debugMode']}
                                >
                                    Sécuriser mes données
                                </Menu.Item>
                                <Menu.Item
                                    onClick={openLocalDataDialog}
                                    className={styles['debugMode']}
                                >
                                    SUPPRIMER LES DONNÉES DE L'APPAREIL
                                </Menu.Item>
                            </>
                        )}
                        <Menu.Item>Version: {config.version}</Menu.Item>
                    </Menu>
                )}
            </div>
            {showDebugDialog && (
                <ConfirmDialog
                    title="Un souci pour synchroniser votre travail ?"
                    message="Les données saisies seront envoyées directement à Dateam. Voulez-vous continuer ?"
                    ok="Envoyer mes saisies"
                    onClose={closeDebugDialog}
                />
            )}
            {showLocalDataDialog && (
                <ConfirmDialog
                    title="Vous souhaitez supprimer vos données sur l'appareil ?"
                    message="Les données présentes seront supprimées. Voulez-vous continuer ?"
                    ok="Confirmer"
                    onClose={closeLocalDataDialog}
                />
            )}
        </>
    );
};

SidebarClock.propTypes = {
    className: propTypes.string
};

SidebarClock.defaultProps = {
    className: undefined
};

export default SidebarClock;

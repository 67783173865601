import { useMutation } from 'react-query';
import { ServiceError } from '@dateam/ark';
// import { usePick } from '@dateam/ark-react';
import logger from 'utils/logger';
import { db } from 'utils/localDb';
import { debugRequests } from './requests';
import { defaultActionOptions, mutateResultKeys, MutationResult } from './constants';

export const useDebugDatabase = (): MutationResult<void, void> => {
    const result = useMutation<void, ServiceError, void>(
        async () => {
            logger.debug('Debug Database: validating...');

            try {
                logger.debug('Debug Database: retrieving local data...');
                const exportedData = await exportLocalDb();

                if (exportedData == null) throw new Error('Debug Database: no export available, aborted.');

                logger.debug('Debug Database: sending data...');
                await debugRequests.sendDbBackup(exportedData);
                logger.debug('Debug Database: data sent');
            }
            catch (err) {
                throw new Error(`Debug Database: failed to save data. (${(err instanceof Error && err?.message) || ''})`);
            }
        },
        {
            ...defaultActionOptions
        }
    );

    return result;
};

const exportLocalDb = async (): Promise<App.Debug.DbExportSchema> => {
    try {
        const tablesData = await Promise.all([
            db.getAllValue('activity'),
            db.getAllValue('observator'),
            db.getAllValue('user'),
            db.getAllValue('planning'),
            db.getAllValue('config')
        ]);

        const dataAreValid = tablesData.every(data => data != null);

        if (dataAreValid === false) {
            throw new Error('exportLocalDb: unable to retrieve data from one of the tables.');
        }

        const [
            activity,
            observator,
            user,
            planning,
            config
        ] = tablesData;

        return {
            activity,
            observator,
            user,
            planning,
            config
        };
    }
    catch (err) {
        throw new Error(`exportLocalDb: unable to export local database. (${(err instanceof Error && err?.message) || ''})`);
    }
};

import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
    children?: React.ReactNode;
};

const AccordionContent: React.FC<Props> = ({
    className,
    children
}: Props) => {
    return (
        <div className={concatClassName('accordionContent', className)}>
            <div className="accordionContentRoot">
                {children}
            </div>
        </div>
    );
};

AccordionContent.propTypes = {
    className: propTypes.string,
    children: propTypes.node
};

AccordionContent.defaultProps = {
    className: undefined,
    children: undefined
};

export default AccordionContent;

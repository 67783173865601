import React from 'react';
import propTypes from 'prop-types';
import { isString } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { Form } from 'shared-ui';
import styles from './Observation.module.scss';

type Props = {
    id: string;
    label?: string;
    offLabel?: string;
    className?: string;
    children?: React.ReactNode;
};

const ObservationField: React.FC<Props> = ({
    id,
    label,
    offLabel,
    className,
    children
}: Props) => (
    <div className={concatClassName(styles['field'], className)}>
        <Form.Group controlId={`field-${id}`} className="form-group">
            {isString(label) && (
                <Form.Label className={styles['fieldLabel']}>{label}</Form.Label>
            )}
            <div className={styles['fieldContent']}>
                {children}
                <span className={styles['fieldOffLabel']}>{offLabel}</span>
            </div>
        </Form.Group>
    </div>
);

ObservationField.propTypes = {
    id: propTypes.string.isRequired,
    label: propTypes.string,
    offLabel: propTypes.string,
    className: propTypes.string,
    children: propTypes.node
};

ObservationField.defaultProps = {
    label: undefined,
    offLabel: undefined,
    className: undefined,
    children: undefined
};

export default ObservationField;

import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';

type Props = {
    className?: string;
};

const WifiIcon: React.FC<Props> = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" className={concatClassName('icon', className)}>
        <path fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M8,15.3c0,0,1.6-1.8,4.1-1.8s3.9,1.8,3.9,1.8" />
        <path fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M4.7,12c0,0,2.5-3.1,7.2-3.1s7.3,3.1,7.3,3.1" />
        <path fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M1.4,8.6c0,0,4-4.5,10.6-4.5s10.6,4.5,10.6,4.5" />
        <circle fill="currentColor" cx="12" cy="18.6" r="1.3" />
    </svg>
);

WifiIcon.propTypes = {
    className: propTypes.string
};

WifiIcon.defaultProps = {
    className: undefined
};

export default WifiIcon;

import { Router, RouteData, NavigationRoute } from 'shared-utils';
import DashboardScreen from './DashboardScreen';

export const DashboardNavigation = (): NavigationRoute => new NavigationRoute(DashboardScreen.key);

const router = new Router();

router
    .add(new RouteData({
        key: DashboardScreen.key,
        path: '',
        component: DashboardScreen,
        exact: true,
        metadata: {
            authRequired: true
        }
    }));

export default router;
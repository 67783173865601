import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import {
    Button,
    Modal
} from 'shared-ui';
import { SyncIcon } from 'components/icons';
import styles from './SyncParamOverwriteModal.module.scss';

type Props = {
    className?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
};

const SyncParamOverwriteModal: React.FC<Props> = ({
    className,
    onConfirm,
    onCancel,
    onClose
}: Props) => {
    const handleConfirm = React.useCallback(() => {
        onConfirm?.();
        onClose?.();
    }, [onConfirm, onClose]);

    const handleCancel = React.useCallback(() => {
        onCancel?.();
        onClose?.();
    }, [onCancel, onClose]);

    return (
        <Modal
            className={concatClassName(
                'dialog',
                'dialog-confirm',
                styles['modal'],
                className
            )}
            backdrop
            centered
        >
            <Modal.Header>
                <>
                    <SyncIcon className={styles['icon']} />
                    <h2 className="dialog-title">Données non synchronisées</h2>
                </>
            </Modal.Header>
            <Modal.Body>
                En changeant d'observateur, les changements non synchronisés seront perdus.
                Souhaitez-vous continuer ?
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={handleCancel}
                    color="secondary"
                >
                    Annuler
                </Button>
                <Button
                    onClick={handleConfirm}
                    color="primary"
                >
                    Continuer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

SyncParamOverwriteModal.propTypes = {
    className: propTypes.string,
    onConfirm: propTypes.func,
    onCancel: propTypes.func,
    onClose: propTypes.func
};

SyncParamOverwriteModal.defaultProps = {
    className: undefined,
    onConfirm: undefined,
    onCancel: undefined,
    onClose: undefined
};

export default SyncParamOverwriteModal;

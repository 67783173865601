import React from 'react';
import propTypes from 'prop-types';
import { isBoolean, isString, isValidDate, orderBy } from '@dateam/ark';
import { propTypeNullable } from '@dateam/ark-react';
import {
    isRefLabelNumber,
    refLabelNumberPropTypes
} from 'utils/propTypes';
import { sortObservations } from 'data/observation';
import Observation from 'components/Observation';
import ObservationComment from './ObservationComment';
import styles from './PlotDetails.module.scss';

type Props = {
    data: App.Observation[];
    inspectionId: App.Inspection['id'];
    plot: App.Plot;
};

const PlotObservations: React.FC<Props> = ({
    data,
    inspectionId,
    plot
}) => {
    const observations = React.useMemo(() => sortObservations(data), [data]);

    return (
        <div className={styles['observations']}>
            {observations.map(obs => (
                <Observation
                    key={`${plot.id}-${obs.id}`}
                    data={obs}
                    inspectionId={inspectionId}
                    plot={plot}
                />
            ))}
            <ObservationComment
                key={`${plot.id}-obsComment`}
                inspectionId={inspectionId}
                plot={plot}
            />
        </div>
    );
};

PlotObservations.propTypes = {
    data: propTypes.arrayOf(propTypes.any).isRequired,
    inspectionId: propTypes.number.isRequired,
    plot: propTypes.shape({
        id: propTypes.number.isRequired,
        year: propTypes.number.isRequired,
        activityId: propTypes.number.isRequired,
        publicId: propTypes.string.isRequired,
        label: propTypes.string.isRequired,
        position: propTypes.number.isRequired,
        varietal: propTypeNullable(isRefLabelNumber),
        customer: propTypes.shape({
            id: propTypes.number.isRequired,
            label: propTypes.string.isRequired,
            contactInfo: propTypeNullable(isString)
        }).isRequired,
        area: propTypeNullable(isRefLabelNumber),
        bio: refLabelNumberPropTypes.isRequired,
        city: propTypes.string.isRequired,
        confusion: propTypeNullable(isBoolean),
        observationTypes: propTypes.arrayOf(refLabelNumberPropTypes.isRequired).isRequired,
        observations: propTypes.arrayOf(propTypes.any.isRequired).isRequired,
        observationComment: propTypeNullable(isString),
        validationDate: propTypeNullable(isValidDate),
        ignored: propTypeNullable(isBoolean),
        ignoredReason: propTypeNullable(isString)
    }).isRequired
};

PlotObservations.defaultProps = {};

export default PlotObservations;

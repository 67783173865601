import { isValidNumber, ObjectAccessor } from '@dateam/ark';
import { RecordValue, ValidationError } from './types';
import { AFFLICTION_LEVEL } from './afflicationObservation';
import { parseObservationField } from './shared';

/*
 ** Carence Mn
 ** - plot: Parcelle
 **     Niveaux de maladie (règles à l'endroit des données)
 **     Required
 ** - area: Zones
 **     Liste : 0, 1, 2
 ** - isolatedRoot: Ceps isolés
 **     Liste : 0, 1, 2
 */

type CurrentObservation = App.Observation13;
type CurrentApiObservation = App.Api.Observation13;

/*
 ** area >= plot
 ** isolatedRoot >= area
 ** isolatedRoot > 0
 */

export const validate = (
    observation: CurrentObservation,
    _: App.Plot
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const {
        ignored,
        plot,
        area,
        isolatedRoot
    } = observation;

    if (ignored === true) return validation;

    // The value must be defined
    if (plot == null || !isValidNumber(plot)) {
        validation.plot = {
            field: 'plot',
            type: 'error',
            code: 'required'
        };
    }
    // The value must be a valid number
    else if (AFFLICTION_LEVEL.indexOf(plot) < 0) {
        validation.plot = {
            field: 'plot',
            type: 'error',
            code: 'unknown'
        };
    }

    if (area != null) {
        if (plot == null) {
            validation.area = {
                field: 'area',
                type: 'error',
                code: 'forbidden'
            };
        }
        else if (!isValidNumber(area) || AFFLICTION_LEVEL.indexOf(area) < 0) {
            validation.area = {
                field: 'area',
                type: 'error',
                code: 'unknown'
            };
        }
        else if (isValidNumber(area) && area < plot) {
            validation.area = {
                field: 'area',
                type: 'error',
                code: 'level'
            };
        }
    }

    if (isolatedRoot != null) {
        if (plot == null || area == null) {
            validation.isolatedRoot = {
                field: 'isolatedRoot',
                type: 'error',
                code: 'forbidden'
            };
        }
        else if (!isValidNumber(isolatedRoot) || AFFLICTION_LEVEL.indexOf(isolatedRoot) < 0) {
            validation.isolatedRoot = {
                field: 'isolatedRoot',
                type: 'error',
                code: 'unknown'
            };
        }
        else if (isValidNumber(isolatedRoot) && (isolatedRoot < area || isolatedRoot < plot)) {
            validation.isolatedRoot = {
                field: 'isolatedRoot',
                type: 'error',
                code: 'level'
            };
        }
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        plot,
        area,
        isolatedRoot
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.plot = parseObservationField(plot);
    parsedObs.area = parseObservationField(area);
    parsedObs.isolatedRoot = parseObservationField(isolatedRoot);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        plot,
        area,
        isolatedRoot
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.plot = plot !== undefined ? new RecordValue(plot) : null;
    mappedObs.area = area !== undefined ? new RecordValue(area) : null;
    mappedObs.isolatedRoot = isolatedRoot !== undefined ? new RecordValue(isolatedRoot) : null;

    return mappedObs;
};
import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dateToFormat, isValidDate } from '@dateam/ark';
import { concatClassName, useConnectivity } from '@dateam/ark-react';
import { useLastSync } from 'utils/sync';
import { useSync } from 'data/sync';
import { useActivity } from 'data/activity';
import { NoWifiIcon, SyncIcon } from 'components/icons';
import styles from './SyncInfo.module.scss';

type Props = {
    className?: string;
};

const SyncInfo: React.FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const isOnline = useConnectivity();
    const { data: activity } = useActivity();
    const { mutateAsync: sync, isLoading: syncLoading } = useSync();
    const [lastSyncDate] = useLastSync();
    const [lastSyncText, setLastSyncText] = React.useState('-');
    const [isOpen, setIsOpen] = React.useState(false);

    const handleRootClick = React.useCallback(() => {
        if (syncLoading === false) sync();

        if (isOpen) return;
        setIsOpen(true);
    }, [isOpen, setIsOpen, sync, syncLoading]);

    React.useEffect(() => {
        if (!isOpen) return;
        const timeout = setTimeout(() => {
            setIsOpen(false);
        }, 60 * 1000);

        return () => clearTimeout(timeout);
    }, [isOpen, setIsOpen]);

    React.useEffect(() => {
        if (isValidDate(lastSyncDate)) {
            setLastSyncText(dateToFormat(lastSyncDate, 'le dd/MM/yyyy à HH:mm'));
        }
    }, [lastSyncDate, setLastSyncText]);

    return (
        <div className={concatClassName(styles['wrapper'], className)}>
            <div
                role="button"
                tabIndex={0}
                className={concatClassName(
                    styles['root'],
                    isOnline && activity?.syncState === 'none' ? styles['root-success'] : null,
                    isOnline && activity?.syncState === 'full' ? styles['root-primary'] : null,
                    isOnline && activity?.syncState === 'partial' ? styles['root-warning'] : null,
                    isOpen === false ? styles['hide'] : null
                )}
                onClick={handleRootClick}
            >
                {isOnline && <SyncIcon className={concatClassName(styles['icon'], syncLoading === true ? styles['cw-anim'] : null)} />}
                {!isOnline && <NoWifiIcon className={styles['icon']} />}
                <div className={styles['info']}>
                    <span className={styles['date']}>Dernière synchronisation : {lastSyncText}</span>
                    <strong className={styles['track']}>
                        {t('sync.inspectionChanges')}
                    </strong>
                </div>
            </div>
        </div>
    );
};

SyncInfo.propTypes = {
    className: propTypes.string
};

SyncInfo.defaultProps = {
    className: undefined
};

export default SyncInfo;

import { isString, isValidNumber, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Pyrales feuilles
 ** - count: Comptage // /25 ceps
 **     0 <= Value <= 25
 **     Required
 ** - cut: Taille
 **     Required
 ** - total: Total /25 ceps
 */

type CurrentObservation = App.Observation26;
type CurrentApiObservation = App.Api.Observation26;

export const validate = (
    observation: CurrentObservation,
    _: App.Plot
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, count, cut, total } = observation;

    if (ignored === true) return validation;

    // The value must be defined
    if (count == null || !isValidNumber(count)) {
        validation.count = {
            field: 'count',
            type: 'error',
            code: 'required'
        };
    }
    else if (count < 0 || count > 25) {
        validation.count = {
            field: 'count',
            type: 'error',
            code: 'format'
        };
    }

    // The value must be defined
    if (cut == null || !isString(cut) || cut.trim().length === 0) {
        validation.cut = {
            field: 'cut',
            type: 'error',
            code: 'required'
        };
    }

    if (total != null) {
        if (!isValidNumber(total) || total < 0) {
            validation.total = {
                field: 'total',
                type: 'error',
                code: 'format'
            };
        }
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        count,
        cut,
        total
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.count = parseObservationField(count);
    parsedObs.cut = parseObservationField(cut);
    parsedObs.total = parseObservationField(total);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        count,
        cut,
        total
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.count = count !== undefined ? new RecordValue(count) : null;
    mappedObs.cut = cut !== undefined ? new RecordValue(cut) : null;
    mappedObs.total = total !== undefined ? new RecordValue(total) : null;

    return mappedObs;
};
import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import { isFunction } from '@dateam/ark';
import {
    Button,
    Modal
} from 'shared-ui';
import styles from './InspectionInstructionDialog.module.scss';

type Props = {
    instruction?: string;
    campaignInstruction?: string;
    className?: string;
    onClose?: () => void;
};

const InspectionInstructionDialog: React.FC<Props> = ({
    instruction,
    campaignInstruction,
    className,
    onClose
}: Props) => {
    const handleCloseClick = React.useCallback(() => {
        if (isFunction(onClose)) {
            onClose();
        }
    }, [onClose]);

    return (
        <Modal
            className={concatClassName(styles['modal'], 'modal-flex', className)}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Consignes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles['campaignInstruction']}>
                    <div className={styles['title']}>Tournée</div>
                    {campaignInstruction}
                </div>
                <div className={styles['instruction']}>
                    <div className={styles['title']}>Visite</div>
                    {instruction}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={handleCloseClick}
                    color="primary"
                >
                    J'ai lu les consignes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

InspectionInstructionDialog.propTypes = {
    instruction: propTypes.string,
    campaignInstruction: propTypes.string,
    className: propTypes.string,
    onClose: propTypes.func
};

InspectionInstructionDialog.defaultProps = {
    instruction: undefined,
    campaignInstruction: undefined,
    className: undefined,
    onClose: undefined
};

export default InspectionInstructionDialog;

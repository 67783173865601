import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React from 'react';
import NavigationRoute from '../navigationRoute';
import useRouterRegistration from './useRouterRegistration';
var useRouterPath = function useRouterPath(route) {
  var _useRouterRegistratio = useRouterRegistration(),
    _useRouterRegistratio2 = _slicedToArray(_useRouterRegistratio, 1),
    router = _useRouterRegistratio2[0];
  return React.useMemo(function () {
    var _route$toPath;
    if (router == null || !(route instanceof NavigationRoute)) return '';
    return (_route$toPath = route.toPath(router)) !== null && _route$toPath !== void 0 ? _route$toPath : '';
  }, [route, router]);
};
export default useRouterPath;
import React from 'react';
import propTypes from 'prop-types';
import { isFunction, isString } from '@dateam/ark';
import { concatClassName, propTypeNullable } from '@dateam/ark-react';
import {
    Button,
    Modal
} from 'shared-ui';
import { refLabelStringPropTypes } from 'utils/propTypes';
import styles from './CustomerContactModal.module.scss';

type Props = {
    customer: App.PlotCustomer;
    className?: string;
    onClose?: () => void;
};

const CustomerContactModal: React.FC<Props> = ({
    customer,
    className,
    onClose
}: Props) => {
    const handleCloseClick = React.useCallback(() => {
        if (isFunction(onClose)) {
            onClose();
        }
    }, [onClose]);

    return (
        <Modal
            className={concatClassName(styles['modal'], 'modal-flex', className)}
            backdrop
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Informations de contact
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles['customer']}>
                    {customer.label}
                </div>
                <div className={styles['customerContact']}>
                    {customer.contactInfo}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={handleCloseClick}
                    color="primary"
                >
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

CustomerContactModal.propTypes = {
    customer: propTypes.shape({
        id: propTypes.number.isRequired,
        label: propTypes.string.isRequired,
        contactInfo: propTypeNullable(isString)
    }).isRequired,
    className: propTypes.string,
    onClose: propTypes.func
};

CustomerContactModal.defaultProps = {
    className: undefined,
    onClose: undefined
};

export default CustomerContactModal;

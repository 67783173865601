import React from 'react';
import propTypes from 'prop-types';
import List from 'components/ui/List';
import styles from '../PlanningScreen.module.scss';

type Props = {
    firstname: string;
    lastname: string;
}

const AssigneeColumn: React.FC<Props> = ({ firstname, lastname }) => (
    <List.Item.Col width="auto" className={styles['colAssignee']}>
        <div className={styles['assigneeName']}>
            {firstname}
        </div>
        <div>
            {lastname.toUpperCase()}
        </div>
    </List.Item.Col>
);

AssigneeColumn.propTypes = {
    firstname: propTypes.string.isRequired,
    lastname: propTypes.string.isRequired
};

AssigneeColumn.defaultProps = {

};

export default AssigneeColumn;

import React from 'react';
import propTypes from 'prop-types';
import { isBoolean } from '@dateam/ark';
import Accordion from 'components/ui/accordion';
import styles from './PlotDetails.module.scss';

type Props = {
    data: App.Plot;
};

const getConfusionLabel = (confusion: App.Plot['confusion']) => {
    if (isBoolean(confusion)) return confusion ? 'Oui' : 'Non';

    return '-';
};

const PlotDetailsInfo: React.FC<Props> = ({ data }) => (
    <Accordion className={styles['info']}>
        <Accordion.Title className={styles['infoTitle']}>
            Informations sur la parcelle
        </Accordion.Title>
        <Accordion.Content className={styles['infoContent']}>
            <>
                <div className="row">
                    <div className="col col-4">
                        <span className={styles['infoLabel']}>Code de la parcelle :</span>
                        <span className={styles['infoValue']}>{data.publicId}</span>
                    </div>
                    <div className="col col-4">
                        <span className={styles['infoLabel']}>Zone géographique :</span>
                        <span className={styles['infoValue']}>{data.area?.label ?? '-'}</span>
                    </div>
                    <div className="col col-4">
                        <span className={styles['infoLabel']}>Type de protection :</span>
                        <span className={styles['infoValue']}>{data.bio?.label ?? '-'}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-4">
                        <span className={styles['infoLabel']}>Vigneron :</span>
                        <span className={styles['infoValue']}>{data.customer.label}</span>
                    </div>
                    <div className="col col-4">
                        <span className={styles['infoLabel']}>Ville :</span>
                        <span className={styles['infoValue']}>{data.city ?? '-'}</span>
                    </div>
                    <div className="col col-4">
                        <span className={styles['infoLabel']}>Confusion :</span>
                        <span className={styles['infoValue']}>{getConfusionLabel(data.confusion)}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <span className={styles['infoLabel']}>Cépage :</span>
                        <span className={styles['infoValue']}>{data.varietal?.label ?? '-'}</span>
                    </div>
                </div>
            </>
        </Accordion.Content>
    </Accordion>
);

PlotDetailsInfo.propTypes = {
    data: propTypes.any.isRequired
};

PlotDetailsInfo.defaultProps = {
};

export default PlotDetailsInfo;

import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import PageContent from './PageContent';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';
import styles from './Page.module.scss';

type Props = {
    className?: string;
    children?: React.ReactNode;
};

const Page: React.FC<Props> = ({
    className,
    children
}: Props) => (
    <div className={concatClassName(styles['page'], className)}>
        {children}
    </div>
);

Page.propTypes = {
    className: propTypes.string,
    children: propTypes.node
};

Page.defaultProps = {
    className: undefined,
    children: undefined
};

export default Object.assign(Page, {
    Content: PageContent,
    Header: PageHeader,
    Title: PageTitle
});

import { Router } from 'shared-utils';
import { MainLayout } from 'layout';
import { authGuard } from './guards';
import { LoginNavigation, loginRouter } from './login';
import { dashboardRouter, DashboardNavigation } from './dashboard';
import { inspectionRouter } from './inspection';
import { planningRouter } from './planning';

export { LoginNavigation } from './login';
export { DashboardNavigation } from './dashboard';
export { InspectionNavigation } from './inspection';
export { PlanningNavigation } from './planning';
export const DefaultNavigation = DashboardNavigation;
export const LogOutNavigation = LoginNavigation;

const authRouter = new Router();
authRouter
    .setLayout(MainLayout)
    .setGuards([
        authGuard(LoginNavigation())
    ])
    .use('/dashboard', dashboardRouter)
    .use('/inspection', inspectionRouter)
    .use('/planning', planningRouter);

const router = new Router();

// Apply screen routers
router
    .use('/login', loginRouter)
    .use('', authRouter)
    .otherwise('/dashboard');

export default router;

import { QueryObserverOptions } from 'react-query';

const queryConfig: QueryObserverOptions = {
    retry: 3,
    staleTime: 0,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchInterval: false,
    refetchOnMount: true
};

export default queryConfig;
import { isString, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Adventices dominantes
 ** - field1
 **     Liste Adventice (données de référence, la saisie alimente la liste)
 **     Required
 ** - field2
 **     Liste Adventice (données de référence, la saisie alimente la liste)
 ** - field3
 **     Liste Adventice (données de référence, la saisie alimente la liste)
 ** - field4
 **     Liste Adventice (données de référence, la saisie alimente la liste)
 ** - field5
 **     Liste Adventice (données de référence, la saisie alimente la liste)
 */

type CurrentObservation = App.Observation5;
type CurrentApiObservation = App.Api.Observation5;

export const validate = (
    observation: CurrentObservation,
    _: App.Plot
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const {
        ignored,
        field1,
        field2,
        field3,
        field4,
        field5
    } = observation;

    if (ignored === true) return validation;

    // The value must be defined
    if (field1 == null || !isString(field1) || field1.trim().length === 0) {
        validation.field1 = {
            field: 'field1',
            type: 'error',
            code: 'required'
        };
    }

    if (field2 != null && !isString(field2)) {
        validation.field2 = {
            field: 'field2',
            type: 'error',
            code: 'format'
        };
    }

    if (field3 != null && !isString(field3)) {
        validation.field3 = {
            field: 'field3',
            type: 'error',
            code: 'format'
        };
    }

    if (field4 != null && !isString(field4)) {
        validation.field4 = {
            field: 'field4',
            type: 'error',
            code: 'format'
        };
    }

    if (field5 != null && !isString(field5)) {
        validation.field5 = {
            field: 'field5',
            type: 'error',
            code: 'format'
        };
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        field1,
        field2,
        field3,
        field4,
        field5
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.field1 = parseObservationField(field1);
    parsedObs.field2 = parseObservationField(field2);
    parsedObs.field3 = parseObservationField(field3);
    parsedObs.field4 = parseObservationField(field4);
    parsedObs.field5 = parseObservationField(field5);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        field1,
        field2,
        field3,
        field4,
        field5
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.field1 = field1 !== undefined ? new RecordValue(field1) : null;
    mappedObs.field2 = field2 !== undefined ? new RecordValue(field2) : null;
    mappedObs.field3 = field3 !== undefined ? new RecordValue(field3) : null;
    mappedObs.field4 = field4 !== undefined ? new RecordValue(field4) : null;
    mappedObs.field5 = field5 !== undefined ? new RecordValue(field5) : null;

    return mappedObs;
};
import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'shared-ui';
import { isFunction } from '@dateam/ark';
import { DangerIcon, InstructionIcon, MailIcon } from 'components/icons';
import styles from './PlotDetails.module.scss';

type Props = {
    id: string;
    label: string;
    onShowInstruction?: () => void;
    onShowContact?: () => void;
}

const PlotDetailsHeader: React.FC<Props> = ({
    id,
    label,
    onShowInstruction,
    onShowContact
}) => {
    const handleShowInstructionClick = React.useCallback(() => {
        if (isFunction(onShowInstruction)) {
            onShowInstruction();
        }
    }, [onShowInstruction]);

    const handleShowContactClick = React.useCallback(() => {
        if (isFunction(onShowContact)) {
            onShowContact();
        }
    }, [onShowContact]);

    return (
        <header className={styles['header']}>
            <h2 className={styles['headerTitle']}>
                <div className={styles['titleId']}>{id}</div>
                <div className={styles['titleLabel']}>{label}</div>
            </h2>
            <div className={styles['headerActions']}>
                <Button
                    onClick={handleShowContactClick}
                    startIcon={<MailIcon />}
                    className={styles['instructionButton']}
                    size="lg"
                >
                    Contacts
                </Button>
                <Button
                    onClick={handleShowInstructionClick}
                    startIcon={<InstructionIcon />}
                    className={styles['instructionButton']}
                    size="lg"
                >
                    Consignes
                </Button>
            </div>
        </header>
    );
};

PlotDetailsHeader.propTypes = {
    id: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    onShowInstruction: propTypes.func,
    onShowContact: propTypes.func
};

PlotDetailsHeader.defaultProps = {
    onShowInstruction: undefined,
    onShowContact: undefined
};

export default PlotDetailsHeader;

import React from 'react';
import propTypes from 'prop-types';
import styles from './Observation.module.scss';

type Props = {
    previous?: React.ReactNode;
};

const ObservationPreviousValue: React.FC<Props> = ({ previous }: Props) => {
    if (!React.isValidElement(previous)) return null;

    return (
        <div className={styles['previousContainer']}>
            {previous}
        </div>
    );
};

ObservationPreviousValue.propTypes = {
    previous: propTypes.node
};

ObservationPreviousValue.defaultProps = {
    previous: undefined
};

export default ObservationPreviousValue;

import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { concatClassName } from '@dateam/ark-react';
import { isDefined } from '@dateam/ark';
import { Button, Form, Popover } from 'shared-ui';
import { useUserState } from 'utils/userStore';
import { EyeIcon } from 'components/icons';
import ObservationPreviousValue from './ObservationPreviousValue';
import styles from './Observation.module.scss';

type Props = {
    label?: string;
    ignorable?: boolean;
    data?: any;
    previous?: React.ReactNode;
    className?: string;
    onIgnore?: () => void;
};

const ObservationHeader: React.FC<Props> = ({
    label,
    ignorable,
    data,
    previous,
    className,
    onIgnore
}: Props) => {
    const { t } = useTranslation();
    const [user] = useUserState();
    const previousValueBtnRef = React.useRef<HTMLButtonElement>(null);
    const [showPreviousValue, setShowPreviousValue] = React.useState(false);

    const handleShowPreviousValueClick = () => {
        setShowPreviousValue(true);
    };

    const handlePreviousValueClickOutside = () => {
        setShowPreviousValue(false);
    };

    const handleIgnoreObservation = () => {
        onIgnore?.();
    };

    return (
        <header className={concatClassName(styles['header'], className)}>
            <h3 className={styles['headerTitle']}>{label}</h3>
            <div className={concatClassName('row', styles['headerActions'])}>
                {ignorable && (
                    <Button
                        startIcon={(<Form.Checkbox checked={data?.ignored} />)}
                        className={styles['headerBtn']}
                        onClick={handleIgnoreObservation}
                    >
                        {t('observation.ignorable')}
                    </Button>
                )}
                {isDefined(data?.previous) && user?.isTrustworthy && (
                    <Button
                        ref={previousValueBtnRef}
                        startIcon={(<EyeIcon />)}
                        className={concatClassName(
                            styles['headerBtn'],
                            showPreviousValue === true ? styles['headerBtn-active'] : null
                        )}
                        onClick={handleShowPreviousValueClick}
                    >
                        {t('observation.previousValue')}
                    </Button>
                )}
            </div>
            {showPreviousValue && (
                <Popover
                    anchorEl={previousValueBtnRef}
                    position="bottom-right"
                    onClickOutside={handlePreviousValueClickOutside}
                >
                    <ObservationPreviousValue previous={previous} />
                </Popover>
            )}
        </header>
    );
};

ObservationHeader.propTypes = {
    label: propTypes.string,
    ignorable: propTypes.bool,
    data: propTypes.any,
    previous: propTypes.node,
    className: propTypes.string,
    onIgnore: propTypes.func
};

ObservationHeader.defaultProps = {
    label: undefined,
    ignorable: false,
    data: undefined,
    previous: undefined,
    className: undefined,
    onIgnore: undefined
};

export default ObservationHeader;

import React from 'react';
import propTypes from 'prop-types';
import { isFunction } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { ChevronDownIcon } from 'components/icons';
import AccordionContext from './AccordionContext';

type Props = {
    className?: string;
    children?: React.ReactNode;
};

const AccordionTitle: React.FC<Props> = ({
    className,
    children
}: Props) => {
    const {
        expanded,
        toggle: onToggle
    } = React.useContext(AccordionContext);

    const handleTitleClick = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        if (isFunction(onToggle)) {
            onToggle();
        }
    }, [onToggle]);

    return (
        <div
            role="button"
            tabIndex={0}
            className={concatClassName('accordionTitle', className)}
            onClick={handleTitleClick}
        >
            <span className="accordionTitleContent">
                {children}
            </span>
            <ChevronDownIcon className="accordionExpandIcon" />
        </div>
    );
};

AccordionTitle.propTypes = {
    className: propTypes.string,
    children: propTypes.node
};

AccordionTitle.defaultProps = {
    className: undefined,
    children: undefined
};

export default AccordionTitle;

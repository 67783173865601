import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { isDefined } from '@dateam/ark';
import { useNavigation, withRouter } from 'shared-utils';
import { Button, Card, Form } from 'shared-ui';
import logger from 'utils/logger';
import { useUserState } from 'utils/userStore';
import config from 'config';
import { UnlockIcon } from 'components/icons';
import { useLogIn } from 'data/user';
import { userRequests } from 'data/requests';
import { DefaultNavigation } from 'screens/routes';
import styles from './LoginScreen.module.scss';

const LoginScreen: React.FC = () => {
    const { t } = useTranslation();
    const [user] = useUserState();
    const { mutateAsync: logIn, error, isLoading: isLogingIn } = useLogIn();
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const { push: navigate } = useNavigation();

    React.useEffect(() => {
        if (isDefined(user)) navigate(DefaultNavigation());
    }, [user, navigate]);

    const onUsernameChange = React.useCallback((value: string): void => {
        setUsername(value);
    }, []);

    const onPasswordChange = React.useCallback((value: string): void => {
        setPassword(value);
    }, []);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            await logIn({ username, password });

            navigate(DefaultNavigation());
        }
        catch (err) {
            logger.debug('login failed', err);
            return;
        }

        logger.debug('login complete');
    }, [username, password, logIn, navigate]);

    const errorMessage = React.useMemo(() => {
        if (error == null) return null;

        if (error.code === userRequests.LOGIN_UNAUTHORIZED) {
            return 'Identifiants non valides';
        }

        return 'Une erreur est survenue.';
    }, [error]);

    return (
        <>
            <Helmet>
                <title>{t('login.pageTitle', { appName: config.appName })}</title>
            </Helmet>
            <div className={styles['wrapper']}>
                <div className={styles['content']}>
                    <Card>
                        <Card.Body>
                            <>
                                <UnlockIcon className={styles['loginIcon']} />
                                <h1 className={styles['title']}>{t('login.title')}</h1>
                                <form onSubmit={onSubmit}>
                                    <Form.Group controlId="username">
                                        <Form.Input
                                            autocomplete="off"
                                            spellcheck={false}
                                            autocapitalize="none"
                                            placeholder={t('user.username')}
                                            value={username}
                                            className={styles['username']}
                                            onChange={onUsernameChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="password">
                                        <Form.Input
                                            type="password"
                                            autocomplete="off"
                                            spellcheck={false}
                                            autocapitalize="none"
                                            placeholder={t('user.password')}
                                            value={password}
                                            className={styles['password']}
                                            onChange={onPasswordChange}
                                        />
                                    </Form.Group>
                                    {errorMessage != null && (
                                        <div className={styles['errorMessage']}>{errorMessage}</div>
                                    )}
                                    <Button
                                        type="submit"
                                        color="success"
                                        value={t('login.action')}
                                        className={styles['submitBtn']}
                                        pending={isLogingIn}
                                    />
                                </form>
                            </>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default withRouter(LoginScreen, 'LOGIN');
import * as Ark from '@dateam/ark';
import httpService from 'utils/httpService';
import config from 'config';

export const sendDbBackup = async (exportedData: App.Debug.DbExportSchema): Promise<void> => {
    Ark.assert(Ark.isPlainObject(exportedData), 'exportedData are not valid');

    let response;

    try {
        const requestUrl = Ark.stringFormat('{0}/debug/database', config.apiUrl);
        const requestData = {
            date: Ark.dateToFormat(new Date(), 'dd/MM/yyyy HH:mm'),
            data: exportedData
        };

        response = await httpService.post<void>(requestUrl, requestData);
    }
    catch (err) {
        const errMessage = (err instanceof Error && err?.message) || 'no further detail';
        throw new Error(`an error occurred while calling debug database API (${errMessage}})`);
    }

    if (response.status === -1) throw new Error('unable to send debug database request');
    if (response.status === Ark.HttpStatusCode.Unauthorized) {
        throw new Ark.ServiceError('DEBUG_DATABASE_UNAUTHORIZED');
    }
    if (Ark.isDefined(response.error)) {
        throw new Ark.ServiceError(
            response.error.code ?? 'DEBUG_DATABASE_ERROR',
            response.error.message ?? undefined
        );
    }
};
import React from 'react';
import { isValidNumber } from '@dateam/ark';
import { getInstructionDisplayed, saveInstructionDisplayed } from 'data/sync/helpers';

type InstructionDisplayedState = boolean | undefined;

export const useInstructionDisplayed = (inspectionId: App.Inspection['id'] | undefined): [
    InstructionDisplayedState,
    () => void
] => {
    const [instructionDisplayed, setInstructionDisplayed] = React.useState<InstructionDisplayedState>();
    const unmounted = React.useRef(false);

    React.useEffect(() => () => {
        unmounted.current = true;
    }, [unmounted]);

    React.useEffect(() => {
        setInstructionDisplayed(undefined);

        if (isValidNumber(inspectionId)) {
            getInstructionDisplayed(inspectionId)
                .then(result => unmounted.current === false && setInstructionDisplayed(result))
                .catch();
        }
    }, [inspectionId, unmounted, setInstructionDisplayed]);

    return React.useMemo(() => ([
        instructionDisplayed,
        async () => {
            setInstructionDisplayed(true);
            if (isValidNumber(inspectionId)) {
                await saveInstructionDisplayed(inspectionId);
            }
        }
    ]), [inspectionId, instructionDisplayed, setInstructionDisplayed]);
};
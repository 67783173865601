import React from 'react';
import { useConnectivity } from '@dateam/ark-react';
import { SidebarMenuSync } from 'components/Sidebar';

const SyncButton: React.FC = () => {
    const isOnline = useConnectivity();

    return (
        <SidebarMenuSync isOnline={isOnline} />
    );
};

SyncButton.propTypes = {
};

SyncButton.defaultProps = {
};

export default SyncButton;

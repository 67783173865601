import React from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { isDefined } from '@dateam/ark';
import { concatClassName } from '@dateam/ark-react';
import { ValidationError } from 'data/observation/types';
import styles from '../Observation.module.scss';

type Props = {
    observationKey: string;
    value: Record<string, ValidationError>;
    className?: string;
};

const ObservationErrors: React.FC<Props> = ({
    observationKey,
    value,
    className
}: Props) => {
    const { t } = useTranslation();

    const errorList = React.useMemo(() => {
        if (!isDefined(value)) return [];

        const errors = Object.values(value);

        return errors.map(error => (
            <div key={`${error.field}-${error.code}`} className={styles[`${error.type}`]}>
                {t(`observation.${observationKey}.fields.${error.field}.label`).length > 0 && (
                    <span>{t(`observation.${observationKey}.fields.${error.field}.label`)}: </span>
                )}
                <span>{t(`observation.${observationKey}.fields.${error.field}.${error.code}`)}</span>
            </div>
        ));
    }, [observationKey, value, t]);

    if (errorList.length === 0) return null;

    return (
        <div className={concatClassName(styles['observationError'], className)}>
            {errorList}
        </div>
    );
};

ObservationErrors.propTypes = {
    observationKey: propTypes.string.isRequired,
    value: propTypes.objectOf(propTypes.shape({
        field: propTypes.string.isRequired,
        type: propTypes.oneOf(['error', 'warning'] as const).isRequired,
        code: propTypes.string.isRequired
    }).isRequired).isRequired,
    className: propTypes.string
};

ObservationErrors.defaultProps = {
    className: undefined
};

export default ObservationErrors;

import { isBoolean, isString, isValidNumber, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Gribouri / Ecrivain
 ** - presence: Présence
 **     Booleen => Liste ['Oui', 'Non']
 ** - count1: Comptage // /25 ceps
 **     0 <= Value <= 25
 ** - count2: // /25 grappes
 **     0 <= Value <= 25
 */

type CurrentObservation = App.Observation35;
type CurrentApiObservation = App.Api.Observation35;

export const validate = (
    observation: CurrentObservation,
    _: App.Plot
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, presence, count1, count2 } = observation;

    if (ignored === true) return validation;

    if (presence != null && !isBoolean(presence)) {
        validation.presence = {
            field: 'presence',
            type: 'error',
            code: 'format'
        };
    }

    if (count1 != null) {
        if (!isValidNumber(count1) || count1 < 0 || count1 > 25) {
            validation.count1 = {
                field: 'count1',
                type: 'error',
                code: 'format'
            };
        }
    }

    if (count2 != null) {
        if (!isValidNumber(count2) || count2 < 0 || count2 > 25) {
            validation.count2 = {
                field: 'count2',
                type: 'error',
                code: 'format'
            };
        }
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        presence,
        count1,
        count2
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.presence = parseObservationField(presence);
    parsedObs.count1 = parseObservationField(count1);
    parsedObs.count2 = parseObservationField(count2);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        presence,
        count1,
        count2
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.presence = presence !== undefined ? new RecordValue(presence) : null;
    mappedObs.count1 = count1 !== undefined ? new RecordValue(count1) : null;
    mappedObs.count2 = count2 !== undefined ? new RecordValue(count2) : null;

    return mappedObs;
};
import React from 'react';
import propTypes from 'prop-types';
import { concatClassName } from '@dateam/ark-react';
import List from 'components/ui/List';
import { PlanningItem, planningItemPropTypes } from './types';
import AssigneeColumn from './AssigneeColumn';
import InspectionColumn from './InspectionColumn';
import styles from '../PlanningScreen.module.scss';

type Props = {
    data: PlanningItem;
    isActive?: boolean;
    onClick?: (planningItem: PlanningItem) => void;
};

const PlanningListItem: React.FC<Props> = ({ data, isActive, onClick }) => {
    const handleOnClick = React.useCallback(() => {
        onClick?.(data);
    }, [onClick, data]);

    return (
        <List.Item
            id={data.id.toString()}
            className={concatClassName(styles['planningItem'], isActive ? styles['selectedItem'] : undefined)}
            onClick={handleOnClick}
        >
            <AssigneeColumn firstname={data.assigneeFirstname} lastname={data.assigneeLastname} />
            <InspectionColumn name={data.label} plotCount={data.plots.length} overlapDays={data.overlapDays} />
            <List.Item.ColNav />
        </List.Item>
    );
};

PlanningListItem.propTypes = {
    data: planningItemPropTypes.isRequired,
    isActive: propTypes.bool,
    onClick: propTypes.func
};

PlanningListItem.defaultProps = {
    isActive: false,
    onClick: undefined
};

export default PlanningListItem;

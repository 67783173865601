import { isString, ObjectAccessor } from '@dateam/ark';
import { parseObservationField } from './shared';
import { RecordValue, ValidationError } from './types';

/*
 ** Stade phénologique
 ** - stage: Stade
 **     Autocompletion stricte
 **     Required
 */

export const STAGE_VALUES = [
    '1f',
    '2f',
    '3f',
    '4f',
    '5f',
    '6f',
    '7f',
    '8f',
    '9f',
    '10f',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37'
];

type CurrentObservation = App.Observation1;
type CurrentApiObservation = App.Api.Observation1;

export const validate = (
    observation: CurrentObservation,
    _: App.Plot
): Record<never, never> | Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError> => {
    const validation = {} as Record<ObjectAccessor<Omit<CurrentObservation, 'previous'>>, ValidationError>;

    const { ignored, stage } = observation;

    if (ignored === true) return validation;

    if (stage == null) {
        validation.stage = {
            field: 'stage',
            type: 'error',
            code: 'required'
        };
    }
    else if (!isString(stage) || STAGE_VALUES.indexOf(stage) < 0) {
        validation.stage = {
            field: 'stage',
            type: 'error',
            code: 'unknown'
        };
    }

    return validation;
};

export const parse = (observation: CurrentApiObservation): CurrentObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        stage
    } = observation;
    const parsedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        previous,
        isSyncing: false,
        isValid: true,
        hasChanged: false
    } as CurrentObservation;

    parsedObs.stage = parseObservationField(stage);

    return parsedObs;
};

export const map = (observation: CurrentObservation): CurrentApiObservation => {
    const {
        id,
        manuallyAdded,
        ignored,
        updatedOn,
        stage
    } = observation;
    const mappedObs = {
        id,
        manuallyAdded,
        ignored,
        updatedOn
    } as CurrentApiObservation;

    mappedObs.stage = stage !== undefined ? new RecordValue(stage) : null;

    return mappedObs;
};
